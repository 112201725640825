import * as Yup from 'yup';
import { HTMLAttributes, useEffect, useState } from 'react';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ButtonLoading from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { InsightViewAttributeItem } from 'types/api/SystemTenant/AROView/InsightViewDefinitionTypes';
import { FormikInsightAttibuteValues, formikInsightAttributeInitialValues } from './ModalAddColumnAttribute';
import { useFormik } from 'formik';
import { getInsightViewRelativeTimeDescription } from 'utils/DateUtils';
import {
  ACCUMULATION_TYPE_DROPDOWN_OPTIONS,
  InsightViewRelativeTimeCategory,
  InsightViewRelativeTimeType,
  TOTAL_RULE_DROPDOWN_OPTIONS,
} from 'constant/ViewConstant';
import { RelativeTimeType } from './RelativeTimeType';
import { Autocomplete, Box } from '@mui/material';
import { useGetStandardViewDefinitionCubeLayerDropdown } from 'services/v1/SystemTenant/AROView/StandardViewDefinitionService';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { INSIGHT_COLUM_GROUP_TOP_HEADER_TYPE, SelectGroupConfigType } from './SelectGroupType';

const autoCompleteStyle = {
  '& .MuiAutocomplete-popper': {
    backgroundColor: 'red !imoprtant',
    fontSize: '10px',
  },
};

const ModalContentStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 750,
  bgcolor: 'background.paper',
  maxHeight: 580,
  overflow: 'auto',
  borderRadius: 1,
  py: 2,
};

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

const nonNegativeIntegerRegex = /^\d*$/;

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  selectedGroupType: Yup.string().required('Selected Group Type is required'),
  cubeLayer: Yup.object()
    .nullable()
    .when('selectedGroupType', {
      is: INSIGHT_COLUM_GROUP_TOP_HEADER_TYPE.RELATIVE_YEAR_AND_LAYER,
      then: Yup.object().nullable().required('Cube Layer is required'),
    }),
  relativePeriodType: Yup.string().when('selectedGroupType', {
    is: INSIGHT_COLUM_GROUP_TOP_HEADER_TYPE.TIME_PERIOD,
    then: Yup.string().required('Relative Period Type is required'),
  }),
  relativePeriod: Yup.number().when('selectedGroupType', {
    is: INSIGHT_COLUM_GROUP_TOP_HEADER_TYPE.TIME_PERIOD,
    then: Yup.number().required('Relative Period is required'),
  }),
  relativeYear: Yup.number().when('selectedGroupType', {
    is: INSIGHT_COLUM_GROUP_TOP_HEADER_TYPE.RELATIVE_YEAR_AND_LAYER,
    then: Yup.number().required('Relative Year is required'),
  }),
  relativeYearType: Yup.string().when('selectedGroupType', {
    is: INSIGHT_COLUM_GROUP_TOP_HEADER_TYPE.RELATIVE_YEAR_AND_LAYER,
    then: Yup.string().required('Relative Year Type is required'),
  }),
  totalRule: Yup.object()
    .nullable()
    .when('selectedGroupType', {
      is: INSIGHT_COLUM_GROUP_TOP_HEADER_TYPE.TOTAL_RULE,
      then: Yup.object().nullable().required('Total Rule is required'),
    }),
  accumulationRule: Yup.object()
    .nullable()
    .when('selectedGroupType', {
      is: INSIGHT_COLUM_GROUP_TOP_HEADER_TYPE.STATIC_PERIOD,
      then: Yup.object().nullable().required('Accumulation Rule is required'),
    }),
});
type FormikInsightAttibuteValuesGroup = FormikInsightAttibuteValues & {
  selectedGroupType: string;
};

export interface ModalAddGroupAttributeProps {
  visible?: boolean;
  insightDefinitionType: string;
  selectedData?: InsightViewAttributeItem;
  onClose?: () => void;
  onSave?: (groupItem: InsightViewAttributeItem) => void;
  onDelete?: (groupItem: InsightViewAttributeItem) => void;
  cubeId?: string;
}

export function ModalAddGroupAttribute(props: Readonly<ModalAddGroupAttributeProps>) {
  const { onClose, visible = false, onSave, selectedData, onDelete, cubeId = '', insightDefinitionType } = props;

  const [selectedGroupType, setSelectedGroupType] = useState<string>('');

  const { data: cubeLayerOptions, isLoading: isLoadingDropdownCubeLayer } =
    useGetStandardViewDefinitionCubeLayerDropdown(cubeId);

  const formik = useFormik<FormikInsightAttibuteValuesGroup>({
    initialValues: { ...formikInsightAttributeInitialValues, selectedGroupType: '' },
    validationSchema,
    onSubmit: () => {},
  });

  const renderOption = (props: HTMLAttributes<HTMLElement>, option: AutoCompleteItem) => {
    return (
      <Box component='li' sx={{ '& > span': { fontSize: '14px', mr: 1, flexShrink: 0 } }} {...props}>
        <span>{option.label}</span>
      </Box>
    );
  };

  const handleOnCloseModal = () => {
    onClose?.();
    formik.resetForm();
    setSelectedGroupType('');
  };
  const handleOnCancel = () => {
    handleOnCloseModal();
  };

  const handleOnRelativeYearTypeChange = (selectedVal: string) => {
    formik.setFieldValue('relativeYearType', selectedVal);

    const isCurrentTime = selectedVal === InsightViewRelativeTimeType.CURRENT_TIME;
    const shouldResetRelativeYear = isCurrentTime || formik.values.relativeYear === 0;

    if (shouldResetRelativeYear) {
      formik.setFieldValue('relativeYear', isCurrentTime ? 0 : 1);
    }
  };

  const handleOnRelativePeriodTypeChange = (selectedVal: string) => {
    formik.setFieldValue('relativePeriodType', selectedVal);

    const isCurrentTime = selectedVal === InsightViewRelativeTimeType.CURRENT_TIME;
    const shouldResetRelativePeriod = isCurrentTime || formik.values.relativePeriod === 0;

    if (shouldResetRelativePeriod) {
      formik.setFieldValue('relativePeriod', isCurrentTime ? 0 : 1);
    }
  };

  const handleRelativeYearChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (nonNegativeIntegerRegex.test(value)) {
      formik.setFieldValue('relativeYear', value);
    }
  };

  const handleRelativePeriodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (nonNegativeIntegerRegex.test(value)) {
      formik.setFieldValue('relativePeriod', value);
    }
  };

  const handleOnSelectGroupConfigType = (option: string) => {
    setSelectedGroupType(option);
    formik.setFieldValue('selectedGroupType', option);
    if (option === INSIGHT_COLUM_GROUP_TOP_HEADER_TYPE.TIME_PERIOD) {
      formik.setFieldValue('cubeLayer', null);
      formik.setFieldValue('relativeYear', 0);
      formik.setFieldValue('relativeYearType', InsightViewRelativeTimeType.CURRENT_TIME);
      formik.setFieldValue('totalRule', null);
    }
    if (option === INSIGHT_COLUM_GROUP_TOP_HEADER_TYPE.TIME_PERIOD) {
      formik.setFieldValue('relativePeriodType', InsightViewRelativeTimeType.CURRENT_TIME);
      formik.setFieldValue('relativePeriod', 0);
      formik.setFieldValue('totalRule', null);
    }
    if (option === INSIGHT_COLUM_GROUP_TOP_HEADER_TYPE.RELATIVE_YEAR_AND_LAYER) {
      formik.setFieldValue('relativePeriodType', InsightViewRelativeTimeType.CURRENT_TIME);
      formik.setFieldValue('relativePeriod', 0);
      formik.setFieldValue('cubeLayer', null);
      formik.setFieldValue('relativeYear', 0);
      formik.setFieldValue('relativeYearType', InsightViewRelativeTimeType.CURRENT_TIME);
      formik.setFieldValue('totalRule', null);
    }
  };

  const handleOnSave = () => {
    if (!selectedData) {
      const groupItem: InsightViewAttributeItem = {
        cubeLayer: formik.values.cubeLayer,
        id: `NEWGROUP-${Date.now()}`,
        isGroup: true,
        isNew: true,
        relativePeriodType: formik.values.relativePeriodType,
        relativePeriod: formik.values.relativePeriod,
        relativeYear: formik.values.relativeYear,
        relativeYearType: formik.values.relativeYearType,
        totalRule: formik.values.totalRule,
        accumulationRule: formik.values.accumulationRule,
        name: formik.values.name,
        groupTopHeaderType: selectedGroupType,
        group: null,
      };

      onSave?.(groupItem);
    } else {
      const groupItem: InsightViewAttributeItem = {
        ...selectedData,
        name: formik.values.name,
        cubeLayer: formik.values.cubeLayer,
        relativePeriodType: formik.values.relativePeriodType,
        relativePeriod: formik.values.relativePeriod,
        relativeYear: formik.values.relativeYear,
        relativeYearType: formik.values.relativeYearType,
        totalRule: formik.values.totalRule,
        groupTopHeaderType: selectedGroupType,
      };

      onSave?.(groupItem);
    }
    handleOnCloseModal();
  };

  const handleOnDelete = () => {
    if (!selectedData) return;

    onDelete?.(selectedData);
    handleOnCloseModal();
  };

  useEffect(() => {
    if (selectedData) {
      formik.setFieldValue('name', selectedData.name);
      formik.setFieldValue('cubeLayer', selectedData.cubeLayer);
      formik.setFieldValue('relativePeriodType', selectedData.relativePeriodType);
      formik.setFieldValue('relativePeriod', selectedData.relativePeriod);
      formik.setFieldValue('relativeYear', selectedData.relativeYear);
      formik.setFieldValue('relativeYearType', selectedData.relativeYearType);
      formik.setFieldValue('totalRule', selectedData.totalRule);
      formik.setFieldValue('selectedGroupType', selectedData.groupTopHeaderType);
      formik.setFieldValue('accumulationRule', selectedData.accumulationRule);
      selectedData.groupTopHeaderType && setSelectedGroupType(selectedData.groupTopHeaderType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedData]);

  const isValidForm = validationSchema.isValidSync(formik.values);

  return (
    <Modal open={visible} onClose={handleOnCloseModal}>
      <Stack direction='column' sx={ModalContentStyle} px={2} width='80%'>
        <Stack>
          <Stack justifyContent='space-between' direction='row' alignItems='center'>
            <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
              Attribute Group
            </Typography>
            <IconButton onClick={handleOnCloseModal} component='label'>
              <HighlightOffRoundedIcon sx={closeIconStyle} />
            </IconButton>
          </Stack>
        </Stack>
        <Divider sx={{ mb: 1 }} />

        <Stack mb={1.6}>
          <Stack mb={1.6} width='100%' spacing={1}>
            <Typography variant='body2' fontWeight='bold' color='black'>
              Group Name
            </Typography>
            <TextField
              size='small'
              placeholder='e.g. Last Year Actual'
              name='name'
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Stack>
          <Stack mb={1.6}>
            <Typography mb={1.6} variant='body2' fontWeight='bold' color='black'>
              Top Heading Type
            </Typography>
            <SelectGroupConfigType
              onMenuOptionClick={handleOnSelectGroupConfigType}
              value={selectedGroupType}
              insightDefinitionType={insightDefinitionType}
            />
          </Stack>
          {selectedGroupType === INSIGHT_COLUM_GROUP_TOP_HEADER_TYPE.TIME_PERIOD && (
            <Stack mb={1.6} width='100%' direction='row' spacing={2.5} alignItems='center'>
              <Stack mb={1.6} width='80%' spacing={1}>
                <Typography variant='body2' fontWeight='bold' color='black'>
                  Relative Period
                </Typography>
                <RelativeTimeType
                  value={formik.values.relativePeriodType}
                  onChange={handleOnRelativePeriodTypeChange}
                />
                <TextField
                  size='small'
                  placeholder='e.g. 1'
                  value={formik.values.relativePeriod}
                  onBlur={formik.handleBlur}
                  onChange={handleRelativePeriodChange}
                  disabled={formik.values.relativePeriodType === InsightViewRelativeTimeType.CURRENT_TIME}
                  type='number'
                  name='relativePeriod'
                />
                {formik.values.relativePeriodType && formik.values.relativePeriod != null && (
                  <Typography variant='input-label-gray' mb={1}>
                    {getInsightViewRelativeTimeDescription(
                      formik.values.relativePeriod,
                      formik.values.relativePeriodType,
                      InsightViewRelativeTimeCategory.PERIOD_TIME
                    )}
                  </Typography>
                )}
              </Stack>
            </Stack>
          )}

          {selectedGroupType === INSIGHT_COLUM_GROUP_TOP_HEADER_TYPE.STATIC_PERIOD && (
            <Stack mb={1.6} width='100%' direction='row' spacing={2.5} alignItems='center'>
              <Stack mb={1.6} width='80%'>
                <Typography variant='body2' fontWeight='bold' color='black'>
                  Accumulation Rule
                </Typography>
                <Typography variant='input-label-gray' mb={1}>
                  Choose the accumulation rule for this column to be displayed in.
                </Typography>
                <Autocomplete
                  clearIcon={null}
                  size='small'
                  value={formik.values.accumulationRule}
                  onChange={(event, newValue) => {
                    formik.setFieldValue('accumulationRule', newValue);
                  }}
                  onBlur={formik.handleBlur}
                  getOptionLabel={(option: AutoCompleteItem) => option.label}
                  options={ACCUMULATION_TYPE_DROPDOWN_OPTIONS}
                  renderOption={renderOption}
                  sx={autoCompleteStyle}
                  renderInput={(params) => (
                    <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                      <TextField
                        {...params}
                        name='accumulationRule'
                        placeholder={isLoadingDropdownCubeLayer ? 'Loading...' : 'Select Accumulation Rule'}
                      />
                    </Stack>
                  )}
                />
              </Stack>
            </Stack>
          )}
          {selectedGroupType === INSIGHT_COLUM_GROUP_TOP_HEADER_TYPE.TOTAL_RULE && (
            <Stack mb={1.6} width='100%' direction='row' spacing={2.5} alignItems='center'>
              <Stack mb={1.6} width='80%'>
                <Typography variant='body2' fontWeight='bold' color='black'>
                  Total Rule
                </Typography>
                <Typography variant='input-label-gray' mb={1}>
                  Choose the total rule for this column to be displayed in.
                </Typography>
                <Autocomplete
                  clearIcon={null}
                  size='small'
                  value={formik.values.totalRule}
                  onChange={(event, newValue) => {
                    formik.setFieldValue('totalRule', newValue);
                  }}
                  onBlur={formik.handleBlur}
                  getOptionLabel={(option: AutoCompleteItem) => option.label}
                  options={TOTAL_RULE_DROPDOWN_OPTIONS}
                  renderOption={renderOption}
                  sx={autoCompleteStyle}
                  renderInput={(params) => (
                    <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                      <TextField
                        {...params}
                        name='totalRule'
                        placeholder={isLoadingDropdownCubeLayer ? 'Loading...' : 'Select Total Rule'}
                      />
                    </Stack>
                  )}
                />
              </Stack>
            </Stack>
          )}

          {selectedGroupType === INSIGHT_COLUM_GROUP_TOP_HEADER_TYPE.RELATIVE_YEAR_AND_LAYER && (
            <Stack mb={1.6} width='100%' direction='column' spacing={2.5}>
              <Stack mb={1.6} width='80%'>
                <Typography variant='body2' fontWeight='bold' color='black'>
                  Cube Layer
                </Typography>
                <Typography variant='input-label-gray' mb={1}>
                  Choose the cube layer from which you want to display the values for.
                </Typography>
                <Autocomplete
                  clearIcon={null}
                  size='small'
                  value={formik.values.cubeLayer}
                  onChange={(event, newValue) => {
                    formik.setFieldValue('cubeLayer', newValue);
                  }}
                  onBlur={formik.handleBlur}
                  getOptionLabel={(option: AutoCompleteItem) => option.label}
                  options={cubeLayerOptions?.data ?? []}
                  renderOption={renderOption}
                  sx={autoCompleteStyle}
                  renderInput={(params) => (
                    <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                      <TextField
                        {...params}
                        name='cubeLayer'
                        placeholder={isLoadingDropdownCubeLayer ? 'Loading...' : 'Select Layer'}
                      />
                    </Stack>
                  )}
                />
              </Stack>
              <Stack mb={1.6} width='80%' spacing={1}>
                <Typography variant='body2' fontWeight='bold' color='black'>
                  Relative Year
                </Typography>
                <RelativeTimeType value={formik.values.relativeYearType} onChange={handleOnRelativeYearTypeChange} />
                <TextField
                  size='small'
                  placeholder='e.g. 1'
                  value={formik.values.relativeYear}
                  disabled={formik.values.relativeYearType === InsightViewRelativeTimeType.CURRENT_TIME}
                  onBlur={formik.handleBlur}
                  onChange={handleRelativeYearChange}
                  name='relativeYear'
                />
                {formik.values.relativeYearType && formik.values.relativeYear != null && (
                  <Typography variant='input-label-gray' mb={1}>
                    {getInsightViewRelativeTimeDescription(
                      formik.values.relativeYear,
                      formik.values.relativeYearType,
                      InsightViewRelativeTimeCategory.YEAR_TIME
                    )}
                  </Typography>
                )}
              </Stack>
            </Stack>
          )}
        </Stack>

        <Stack>
          <Divider sx={{ mb: 1, mt: 2 }} />
          <Stack direction='row' justifyContent='flex-end' spacing={2} alignItems='center' sx={{ py: 1 }}>
            {selectedData && (
              <Button
                onClick={handleOnDelete}
                variant='outlined'
                sx={{
                  textTransform: 'none',
                }}
                color='error'
              >
                Delete
              </Button>
            )}
            <Button variant='main-table-panel-border' onClick={handleOnCancel}>
              Cancel
            </Button>
            <ButtonLoading variant='main-table-panel' onClick={() => handleOnSave()} disabled={!isValidForm}>
              Save Group
            </ButtonLoading>
          </Stack>
        </Stack>
      </Stack>
    </Modal>
  );
}
