import { usePrefetchBreadcumbItems } from 'services/v1/Common/NavBarMenuService';
import { usePrefetchSubjectDetails } from 'services/v1/SystemTenant/AROKMS/SubjectService';
import { usePrefetchDisplayEventTable } from 'services/v1/Tenant/AROEvent/EventTableService';
import { usePrefetchDataInputTable } from 'services/v1/Tenant/AROKMS/DisplayTableService';
import { usePrefetchKtreeData } from 'services/v1/Tenant/AROKMS/KTreeDataService';
import { usePrefetchMyViewDetails } from 'services/v1/Tenant/ConfigurationService';
import { QuickAccessItem } from 'types/api/Tenant/ConfigurationTypes';
import { handlePrefetch } from 'utils/MyViewUtils';

export function useDashboardNaviagationPrefetchHandler() {
  const prefetchMyViewDetails = usePrefetchMyViewDetails();
  const prefetchDataInputTable = usePrefetchDataInputTable();
  const prefetchSubjectDetails = usePrefetchSubjectDetails();
  const prefetchEventTableData = usePrefetchDisplayEventTable();
  const prefetchKTreeData = usePrefetchKtreeData();
  const prefetchBreadcumbItems = usePrefetchBreadcumbItems();

  return (viewItem: QuickAccessItem, nextViewItem?: QuickAccessItem) => {
    handlePrefetch(
      viewItem,
      nextViewItem || null,
      prefetchMyViewDetails,
      prefetchDataInputTable,
      prefetchSubjectDetails,
      prefetchEventTableData,
      prefetchKTreeData,
      prefetchBreadcumbItems
    );
  };
}
