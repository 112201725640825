import { AxiosResponse } from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import qs from 'query-string';
import axios from 'libs/Axios';
import { WithOptimisticUpdate } from 'utils/WithOptimisticUpdate';

import {
  SubjectsDataListReponse,
  SubjectDataUpsertRequest,
  SubjectItem,
  SubjectDetails,
} from 'types/api/SystemTenant/AROKMS/SubjectTypes';
import { PaginationAndSortingParams } from 'types/api/Common/PaginationTypes';
import { AxiosDefaultErrorEntity } from 'types/api/Common/ErrorTypes';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { usePrefetchQueryManualKey } from 'hooks/usePrefetchQuery';

export interface SubjectDropdownParameter extends AutoCompleteItem {
  persistentName?: string | null;
}

export const SUBJECT_QUERY_KEY = {
  SUBJECT_LIST: 'SUBJECT_LIST',
  SUBJECT_DETAILS: 'SUBJECT_DETAILS',
  SUBJECT_DROPDOWN: 'SUBJECT_DROPDOWN',
  SUBJECT_DROPDOWN_TABLE_DEFINITION: 'SUBJECT_DROPDOWN_TABLE_DEFINITION',
  SUBJECT_DROPDOWN_MEMBER_LIST: 'SUBJECT_DROPDOWN_MEMBER_LIST',
  SUBJECT_DROPDOWN_USED: 'SUBJECT_DROPDOWN_USED',
};

export function useSubjectList(params?: PaginationAndSortingParams) {
  return useQuery<AxiosResponse<SubjectsDataListReponse>, AxiosDefaultErrorEntity>(
    [SUBJECT_QUERY_KEY.SUBJECT_LIST],
    () => axios.get(`/api/v1/subject/getPaginatedList?${qs.stringify({ ...params })}`).then((res) => res)
  );
}

export function useGetSubjectDetails(subjectId?: string | number) {
  return useQuery<AxiosResponse<SubjectDetails>, AxiosDefaultErrorEntity>(
    [SUBJECT_QUERY_KEY.SUBJECT_DETAILS, subjectId],
    () => axios.get(`/api/v1/subject/${subjectId}`).then((res) => res),
    {
      enabled: !!subjectId,
      cacheTime: 1000 * 60 * 60 * 24,
      staleTime: 1000 * 60 * 60 * 24,
    }
  );
}
export function usePrefetchSubjectDetails() {
  return usePrefetchQueryManualKey(
    (subjectId?: string | number) => axios.get(`/api/v1/subject/${subjectId}`).then((res) => res),
    {
      cacheTime: 1000 * 60 * 60 * 24,
      staleTime: 1000 * 60 * 60 * 24,
    }
  );
}

export function useSubjectDropdown(parentSubjectId?: string | number, subjectType?: string | string[]) {
  const getEndpoint = () => {
    return '/api/v1/subject/dropdown';
  };

  return useQuery<AxiosResponse<SubjectItem[]>, AxiosDefaultErrorEntity>(
    [SUBJECT_QUERY_KEY.SUBJECT_DROPDOWN, parentSubjectId, subjectType],
    () =>
      axios
        .get(
          parentSubjectId
            ? `${getEndpoint()}?${qs.stringify({ parentSubjectId, subjectType })}`
            : `${getEndpoint()}?${qs.stringify({ subjectType })}`
        )
        .then((res) => res)
  );
}

export function useSubjectDropdownTableDefinition(tableDefinitionSubjectId?: string | number | undefined) {
  return useQuery<AxiosResponse<SubjectItem[]>, AxiosDefaultErrorEntity>(
    [SUBJECT_QUERY_KEY.SUBJECT_DROPDOWN_TABLE_DEFINITION, tableDefinitionSubjectId],
    () => axios.get(`/api/v1/subject/dropdown/table-definition/${tableDefinitionSubjectId}`).then((res) => res),
    {
      enabled: !!tableDefinitionSubjectId,
    }
  );
}

export function useSubjectDropdownMemberList() {
  return useQuery<AxiosResponse<SubjectItem[]>, AxiosDefaultErrorEntity>(
    [SUBJECT_QUERY_KEY.SUBJECT_DROPDOWN_MEMBER_LIST],
    () => axios.get('/api/v1/subject/dropdown/member-list').then((res) => res)
  );
}
export function useSubjectUsedDropdown({
  enabled,
  subjectType,
  containsAttributeId,
}: {
  enabled: boolean;
  subjectType?: string | string[];
  containsAttributeId?: string;
}) {
  return useQuery<AxiosResponse<SubjectDropdownParameter[]>, AxiosDefaultErrorEntity>(
    [SUBJECT_QUERY_KEY.SUBJECT_DROPDOWN_USED, { subjectType, containsAttributeId }],
    () => {
      return axios
        .get(`/api/v1/subject/dropdown/used?${qs.stringify({ subjectType, containsAttributeId })}`)
        .then((res) => res);
    },
    {
      enabled,
    }
  );
}

export function useUpsertSubjectData(filter: PaginationAndSortingParams) {
  const queryClient = useQueryClient();
  const listQuery = [SUBJECT_QUERY_KEY.SUBJECT_LIST];
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, SubjectDataUpsertRequest>(
    (bodyRequest) => axios.post('/api/v1/subject/upsert', bodyRequest),
    {
      onMutate: async (bodyRequest) =>
        WithOptimisticUpdate<SubjectDataUpsertRequest>(bodyRequest, listQuery, queryClient, 'UPDATE'),
      onError: (err, variables, context) => {
        //@ts-ignore
        if (context?.previousData) {
          //@ts-ignore
          queryClient.setQueryData(context.query, context.previousData);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries([SUBJECT_QUERY_KEY.SUBJECT_DROPDOWN]);
        queryClient.invalidateQueries([SUBJECT_QUERY_KEY.SUBJECT_DROPDOWN_USED]);
        queryClient.invalidateQueries(listQuery);
      },
    }
  );
}
export function useEditSubject() {
  const queryClient = useQueryClient();
  const listQuery = [SUBJECT_QUERY_KEY.SUBJECT_LIST];
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, SubjectDataUpsertRequest>(
    (bodyRequest) => axios.put('/api/v1/subject/edit', bodyRequest),
    {
      onMutate: async (bodyRequest) =>
        WithOptimisticUpdate<SubjectDataUpsertRequest>(bodyRequest, listQuery, queryClient, 'UPDATE'),
      onError: (err, variables, context) => {
        //@ts-ignore
        if (context?.previousData) {
          //@ts-ignore
          queryClient.setQueryData(context.query, context.previousData);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries([SUBJECT_QUERY_KEY.SUBJECT_DROPDOWN]);
        queryClient.invalidateQueries([SUBJECT_QUERY_KEY.SUBJECT_DROPDOWN_USED]);
        queryClient.invalidateQueries(listQuery);
      },
    }
  );
}

export function useDeleteSubjectData(filter: PaginationAndSortingParams) {
  const queryClient = useQueryClient();
  const listQuery = [SUBJECT_QUERY_KEY.SUBJECT_LIST];
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, { id: number | string }>(
    (bodyRequest) => axios.delete(`/api/v1/subject/delete?subjectId=${bodyRequest.id}`),
    {
      onMutate: async (bodyRequest) =>
        WithOptimisticUpdate<{ id: string | number }>(bodyRequest, listQuery, queryClient, 'DELETE'),
      onError: (err, variables, context) => {
        //@ts-ignore
        if (context?.previousData) {
          //@ts-ignore
          queryClient.setQueryData(context.query, context.previousData);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(['SUBJECTS_DROPDOWN']);
        queryClient.invalidateQueries(listQuery);
      },
    }
  );
}

export function useExportSubjectData() {
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, { fileType: string }>((bodyRequest) =>
    axios.get(`/api/v1/subject/export?${qs.stringify(bodyRequest)}`, {
      responseType: 'blob',
    })
  );
}
