import { gridFilteredSortedRowIdsSelector, gridVisibleColumnFieldsSelector, GridApi } from '@mui/x-data-grid-pro';
import * as XLSX from 'xlsx';
export interface ExportConfig {
  columnNames: string[];
  keys: string[];
  sheetName: string;
  fileName: string;
}

export function getExcelData(apiRef: React.MutableRefObject<GridApi>) {
  // Select rows and columns
  const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
  const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);

  // Format the data. Here we only keep the value
  const data = filteredSortedRowIds.map((id) => {
    const row: {
      [key: string]: string | number | boolean;
    } = {};
    visibleColumnsField.forEach((field) => {
      row[field] = apiRef.current.getCellParams(id, field).value as string | number | boolean;
    });
    return row;
  });

  return data;
}

export function handleExport(
  apiRef: React.MutableRefObject<GridApi>,
  config: ExportConfig,
  extraRows: Array<{ [key: string]: string | number | boolean }> = []
) {
  // Get the main data from the DataGrid
  const data = getExcelData(apiRef);

  // Filter and reorder according to config.keys for the main data
  const mainRows = data.map((row) => {
    const mRow: { [key: string]: string | number | boolean } = {};
    for (const key of config.keys) {
      mRow[key] = row[key];
    }
    return mRow;
  });

  // Collect all unique keys from extraRows
  const extraKeysSet = new Set<string>();
  for (const r of extraRows) {
    Object.keys(r).forEach((k) => extraKeysSet.add(k));
  }
  const extraKeys = Array.from(extraKeysSet);

  // Create a new worksheet
  const worksheet = XLSX.utils.aoa_to_sheet([[]]); // start with an empty sheet

  let currentRow = 0;

  // If we have extra rows, place them first
  if (extraRows.length > 0) {
    // Write extra keys header
    XLSX.utils.sheet_add_aoa(worksheet, [extraKeys], { origin: { r: currentRow, c: 0 } });
    currentRow++;

    // Write each extra row
    for (const er of extraRows) {
      const rowValues = extraKeys.map((key) => (er[key] !== undefined ? er[key] : ''));
      XLSX.utils.sheet_add_aoa(worksheet, [rowValues], { origin: { r: currentRow, c: 0 } });
      currentRow++;
    }

    // Add a blank line after extra rows
    XLSX.utils.sheet_add_aoa(worksheet, [[]], { origin: { r: currentRow, c: 0 } });
    currentRow++;
  }

  // Write main headers
  XLSX.utils.sheet_add_aoa(worksheet, [config.columnNames], { origin: { r: currentRow, c: 0 } });
  currentRow++;

  // Write main data rows
  for (const m of mainRows) {
    const rowValues = config.keys.map((k) => (m[k] !== undefined ? m[k] : ''));
    XLSX.utils.sheet_add_aoa(worksheet, [rowValues], { origin: { r: currentRow, c: 0 } });
    currentRow++;
  }

  // Determine the max number of columns we have
  const maxColCount = Math.max(config.keys.length, extraKeys.length);
  const finalRowCount = currentRow - 1; // currentRow is now one past the last written row
  worksheet['!ref'] = XLSX.utils.encode_range({ r: 0, c: 0 }, { r: finalRowCount, c: maxColCount - 1 });

  // Create workbook and append worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, config.sheetName);

  // Write file
  XLSX.writeFile(workbook, config.fileName, { compression: true });
}

export const generateColumnNames = (apiRef: React.MutableRefObject<GridApi>) => {
  const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);
  return visibleColumnsField.filter((field) => field !== 'action');
};

export const getDisplayColumnNames = (apiRef: React.MutableRefObject<GridApi>) => {
  const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);
  return visibleColumnsField
    .filter((field) => field !== 'action')
    .map((field) => {
      return apiRef.current.getColumn(field);
    });
};
