import { AxiosResponse } from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import axios from 'libs/Axios';

import qs from 'query-string';
import { AxiosDefaultErrorEntity } from 'types/api/Common/ErrorTypes';
import { KTreeDefinitionItem, KTreeDefinitionListResponse } from 'types/api/SystemTenant/KTreeBuilderTypes';
import { KTreeGenerationFilter, KTreeLinkDashboardResponse, KTreeNode } from 'types/api/Tenant/AROKMS/KTreeDataTypes';
import { createTreeData, sortTreeData } from 'utils/Object';
import { DropdownItem } from 'types/api/SystemTenant/AROKMS/TableDefinitionTypes';
import { usePrefetchQueryManualKey } from 'hooks/usePrefetchQuery';

export const KTREE_TENANT_DATA_QUERY = {
  KTREE_DATA_DETAILS: 'KTREE_DATA_DETAILS',
  KTREE_LIST: 'KTREE_LIST',
  KTREE_DATA: 'KTREE_DATA',
  KTREE_LINK_DASHBOARD: 'KTREE_LINK_DASHBOARD',
  KTREE_TABLE_ATTRIBUTE: 'KTREE_TABLE_ATTRIBUTE',
  KTREE_GENERATE_FILTER: 'KTREE_GENERATE_FILTER',
};

export function useGenerateKTreeData() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, { id: string }>(
    (data) => axios.post('/api/v1/customer/ktree/generate', data).then((res) => res),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(KTREE_TENANT_DATA_QUERY.KTREE_DATA_DETAILS);
        queryClient.invalidateQueries(KTREE_TENANT_DATA_QUERY.KTREE_LIST);
        queryClient.invalidateQueries(KTREE_TENANT_DATA_QUERY.KTREE_DATA);
      },
    }
  );
}

export function useGenerateFilterKTreeData() {
  const queryClient = useQueryClient();
  return useMutation<
    AxiosResponse,
    AxiosDefaultErrorEntity,
    { id: string; filterValue: string | null; resetFilter?: boolean }
  >(
    (data) =>
      axios
        .post(
          `/api/v1/customer/ktree/generate/filter?${qs.stringify({
            resetFilter: data.resetFilter,
          })}`,
          data
        )
        .then((res) => res),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(KTREE_TENANT_DATA_QUERY.KTREE_GENERATE_FILTER);
        queryClient.invalidateQueries(KTREE_TENANT_DATA_QUERY.KTREE_DATA);
      },
    }
  );
}

export function useGetKtreeDefinitionListTenant(subjectId?: string | number) {
  return useQuery<AxiosResponse<KTreeDefinitionListResponse>, AxiosDefaultErrorEntity>(
    [KTREE_TENANT_DATA_QUERY.KTREE_LIST, subjectId],
    () => axios.get(`/api/v1/customer/ktree/list/${subjectId}`).then((res) => res),
    {
      enabled: !!subjectId,
    }
  );
}

export function useGetKTreeGenerationFilter(ktreeId?: string) {
  return useQuery<AxiosResponse<KTreeGenerationFilter>, AxiosDefaultErrorEntity>(
    [KTREE_TENANT_DATA_QUERY.KTREE_GENERATE_FILTER, ktreeId],
    () => axios.get(`/api/v1/customer/ktree/generate/filter/${ktreeId}`).then((res) => res),
    {
      enabled: !!ktreeId,
    }
  );
}

export function useGetKtreeDefinitionDetails(ktreeId?: string | number) {
  return useQuery<AxiosResponse<KTreeDefinitionItem>, AxiosDefaultErrorEntity>(
    [KTREE_TENANT_DATA_QUERY.KTREE_DATA_DETAILS, ktreeId],
    () => axios.get(`/api/v1/customer/ktree/${ktreeId}`).then((res) => res),
    {
      enabled: !!ktreeId,
    }
  );
}

export function useGetKtreeLinkDashboard(ktreeId?: string | number) {
  return useQuery<AxiosResponse<KTreeLinkDashboardResponse>, AxiosDefaultErrorEntity>(
    [KTREE_TENANT_DATA_QUERY.KTREE_LINK_DASHBOARD, ktreeId],
    () => axios.get(`/api/v1/customer/ktree/link-dashboard/${ktreeId}`).then((res) => res),
    {
      enabled: !!ktreeId,
    }
  );
}

export function useGetKtreeData(ktreeTableId?: string | number) {
  return useQuery<
    {
      data: KTreeNode[] | KTreeNode;
      expandedIds: string[];
    },
    AxiosDefaultErrorEntity
  >(
    [KTREE_TENANT_DATA_QUERY.KTREE_DATA, ktreeTableId],
    () =>
      axios.get(`/api/v1/customer/ktree/data/${ktreeTableId}`).then((res) => {
        if (res.data.length > 0) {
          const treeData = createTreeData(null, res.data);
          return {
            data: sortTreeData(treeData),
            expandedIds: res.data.map((item: DropdownItem) => item.id),
          };
        }

        return {
          data: [],
          expandedIds: [],
        };
      }),
    {
      enabled: !!ktreeTableId,
    }
  );
}
export function usePrefetchKtreeData() {
  return usePrefetchQueryManualKey(
    (ktreeTableId?: string) =>
      axios.get(`/api/v1/customer/ktree/data/${ktreeTableId}`).then((res) => {
        if (res.data.length > 0) {
          const treeData = createTreeData(null, res.data);
          return {
            data: sortTreeData(treeData),
            expandedIds: res.data.map((item: DropdownItem) => item.id),
          };
        }

        return {
          data: [],
          expandedIds: [],
        };
      }),
    {
      staleTime: 100000,
    }
  );
}
