import { useEffect, useMemo, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import ActivityIndicator from 'components/ActivityIndicatorComponent';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import {
  useGetMyViewDashboardList,
  useUpdateMyViewDashboardListOrdering,
} from 'services/v1/Tenant/ConfigurationService';
import {
  DataGridPro,
  GridColumns,
  GridRenderCellParams,
  GridRowModel,
  GridRowOrderChangeParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid-pro';
import { Button } from '@mui/material';
import { MyViewDashboardDefinitionItem, RequestMyViewDashboardItemOrdering } from 'types/api/Tenant/ConfigurationTypes';
import { ButtonRecordEdit } from 'pages/Tenant/AROKMS/DataInput/components/ButtonRecordEdit';

interface PeopleConfigDetailComponentProps {
  onEdit: (data: MyViewDashboardDefinitionItem) => void;
  onAdd: () => void;
}

function updateRowPosition(
  oldIndex: number,
  newIndex: number,
  rows: Array<GridRowModel<MyViewDashboardDefinitionItem>>
) {
  const rowsClone = [...rows];
  const [row] = rowsClone.splice(oldIndex, 1);
  rowsClone.splice(newIndex, 0, row);

  for (let i = 0; i < rowsClone.length; i++) {
    rowsClone[i].colIndexOrder = i + 1;
  }

  return rowsClone;
}

export const PeopleConfigListComponent = (props: PeopleConfigDetailComponentProps) => {
  const { onEdit, onAdd } = props;

  const [rowData, setRowData] = useState<Array<MyViewDashboardDefinitionItem>>([]);
  const { data: myViewDashboard, isLoading } = useGetMyViewDashboardList();
  const { mutate: updateOrdering, isLoading: isUpdating } = useUpdateMyViewDashboardListOrdering();
  const handleAddDashboard = () => {
    onAdd();
  };

  const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
    const newRows = updateRowPosition(params.oldIndex, params.targetIndex, rowData);
    setRowData(newRows);
    const payload: RequestMyViewDashboardItemOrdering[] = newRows
      .map((item) => {
        if (item.colIndexOrder == null) return undefined;
        return {
          colIndexOrder: item.colIndexOrder,
          id: item.id,
        };
      })
      .filter((item): item is RequestMyViewDashboardItemOrdering => item !== undefined);
    updateOrdering(payload);
  };

  const columns: GridColumns = useMemo(
    () => [
      {
        field: 'action',
        headerName: 'Edit',
        editable: false,
        width: 30,
        renderCell: (params: GridRenderCellParams) => (
          <ButtonRecordEdit
            {...params}
            onClick={() => {
              onEdit(params.row);
            }}
          />
        ),
      },
      {
        field: 'dashboardName',
        headerName: 'Dashboard Name',
        valueFormatter: (params: GridValueFormatterParams) => {
          return params.value?.toLocaleString();
        },
        width: 230,
      },
      {
        field: 'solutionDefinition',
        headerName: 'Group',
        valueFormatter: (params: GridValueFormatterParams) => {
          return params.value?.label?.toLocaleString();
        },

        width: 200,
      },
      {
        field: 'users',
        headerName: 'Total Users',
        valueFormatter: (params: GridValueFormatterParams) => {
          return params.value?.length?.toLocaleString();
        },
        width: 110,
      },

      {
        field: 'myViews',
        headerName: 'Total Views',
        valueFormatter: (params: GridValueFormatterParams) => {
          return params.value?.length?.toLocaleString();
        },
        width: 110,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (myViewDashboard?.data) {
      setRowData(myViewDashboard?.data);
    }
  }, [myViewDashboard?.data]);

  return (
    <Box sx={{ bgcolor: '#fff', px: 1, py: 2, width: '100%' }}>
      <Stack mb={2} width='100%'>
        <Typography variant='h6' color='text.primary' fontWeight='bold'>
          myView Dashboard
        </Typography>
        <Divider sx={{ mt: 1 }} />
      </Stack>
      {isLoading ? (
        <Stack direction='column' justifyContent='center'>
          <ActivityIndicator />
        </Stack>
      ) : (
        <Stack direction='column' display='flex'>
          <Typography variant='body2' color='text.secondary'>
            Initial Financial Year is the first financial year of the company. This will be used on functions such as
            calculation Depreciation Value and Loan
          </Typography>
          <Stack width={180} my={1}>
            <Button
              variant='secondary-table-panel'
              startIcon={<AddCircleOutlineOutlinedIcon />}
              onClick={handleAddDashboard}
            >
              Add
            </Button>
          </Stack>
          <Stack mt={1} height={300}>
            <DataGridPro
              autoHeight={false}
              loading={isLoading || isUpdating}
              pagination
              disableMultipleColumnsFiltering
              paginationMode='client'
              density='compact'
              hideFooter
              rowCount={0}
              rows={myViewDashboard?.data || []}
              columns={columns}
              filterMode='client'
              checkboxSelection={false}
              rowReordering
              onRowOrderChange={handleRowOrderChange}
            />
          </Stack>
        </Stack>
      )}
    </Box>
  );
};
