import { ROLES } from 'constant/PermissonConstant';
import SVGIcon from '@mui/material/SvgIcon';
import FunctionsOutlinedIcon from '@mui/icons-material/FunctionsOutlined';
import ViewCompactOutlinedIcon from '@mui/icons-material/ViewCompactOutlined';
import { ReactComponent as CustomIconCreditCard } from 'assets/icons/icon-credit-card.svg';
import { ReactComponent as CustomIconServer } from 'assets/icons/icon-server.svg';
import SourceOutlinedIcon from '@mui/icons-material/SourceOutlined';
import { ReactComponent as CustomIconEvent } from 'assets/icons/icon-menu-event.svg';
import { ReactComponent as CustomIconCube } from 'assets/icons/icon-menu-cube.svg';
import { PATH_CONSTANT } from 'constant/PathConstant';

interface SidebarChildItem {
  title: string;
  path?: string | null;
  accessAdminRightLevel?: number;
  allowedRoles: string[];
  parentPath?: string;
  child: SidebarChildItem[];
}
export interface SidebarItemsProps {
  icon: React.ReactNode;
  title: string;
  child?: SidebarChildItem[];
  accessAdminRightLevel?: number;
  allowedRoles: string[];
  parentPath?: string;
  path?: string | null;
}

export const SIDEBAR_ITEMS = [
  {
    title: 'ARO KMS',
    icon: <SourceOutlinedIcon sx={{ color: '#fff' }} fontSize='small' />,
    path: null,
    parentPath: '/kms',
    allowedRoles: [ROLES.BUILDER],
    child: [
      {
        title: 'SIDEBAR_MENU.DATA_BUILDER',
        icon: null,
        parentPath: '/kms/data-builder',
        path: null,
        allowedRoles: [ROLES.BUILDER],
        child: [
          {
            title: 'SIDEBAR_MENU.SUBJECT',
            path: PATH_CONSTANT.SYSTEM.KMS.DATA_BUILDER.SUBJECT,
          },
          {
            title: 'SIDEBAR_MENU.ATTRIBUTE',
            path: PATH_CONSTANT.SYSTEM.KMS.DATA_BUILDER.ATTRIBUTE,
          },

          {
            title: 'SIDEBAR_MENU.TABLE_DEFINITION',
            path: PATH_CONSTANT.SYSTEM.KMS.DATA_BUILDER.TABLE_DEFINITION,
          },
        ],
      },
      {
        title: 'KTree Builder',
        icon: <SVGIcon fontSize='small' sx={{ fill: 'none' }} inheritViewBox component={CustomIconServer} />,
        path: PATH_CONSTANT.SYSTEM.KMS.KTREE_DEFINITION,
        allowedRoles: [ROLES.BUILDER],
        child: [],
      },
      {
        title: 'Subject Process',
        icon: <SVGIcon fontSize='small' sx={{ fill: 'none' }} inheritViewBox component={CustomIconServer} />,
        path: PATH_CONSTANT.SYSTEM.KMS.SUBJECT_PROCESS,
        allowedRoles: [ROLES.BUILDER],
        child: [],
      },

      {
        title: 'Subject Procedure',
        icon: <SVGIcon fontSize='small' sx={{ fill: 'none' }} inheritViewBox component={CustomIconServer} />,
        path: PATH_CONSTANT.SYSTEM.KMS.SUBJECT_PROCEDURE,
        allowedRoles: [ROLES.BUILDER],
        child: [],
      },
      {
        title: 'Solution Definition',
        icon: <SVGIcon fontSize='small' sx={{ fill: 'none' }} inheritViewBox component={CustomIconServer} />,
        path: PATH_CONSTANT.SYSTEM.KMS.SOLUTION_DEFINITION,
        allowedRoles: [ROLES.BUILDER],
        child: [],
      },
    ],
  },

  {
    title: 'ARO Cube',
    icon: <SVGIcon fontSize='small' sx={{ fill: '#fff', color: '#fff' }} inheritViewBox component={CustomIconCube} />,
    path: null,
    parentPath: '/cube',
    allowedRoles: [ROLES.BUILDER],
    child: [
      {
        title: 'Cube Builder',
        icon: <SVGIcon fontSize='small' sx={{ fill: 'none' }} inheritViewBox component={CustomIconServer} />,
        path: PATH_CONSTANT.SYSTEM.CUBE.CUBE_DEFINITION,
        allowedRoles: [ROLES.BUILDER],
        child: [],
      },
      {
        title: 'Cube Layer',
        icon: <SVGIcon fontSize='small' sx={{ fill: 'none' }} inheritViewBox component={CustomIconServer} />,
        path: PATH_CONSTANT.SYSTEM.CUBE.CUBE_LAYER,
        allowedRoles: [ROLES.BUILDER],
        child: [],
      },
      {
        title: 'Cube Layer Set',
        icon: <SVGIcon fontSize='small' sx={{ fill: 'none' }} inheritViewBox component={CustomIconServer} />,
        path: PATH_CONSTANT.SYSTEM.CUBE.CUBE_LAYER_SET,
        allowedRoles: [ROLES.BUILDER],
        child: [],
      },
      {
        title: 'Time Period Set',
        icon: <SVGIcon fontSize='small' sx={{ fill: 'none' }} inheritViewBox component={CustomIconServer} />,
        path: PATH_CONSTANT.SYSTEM.CUBE.TIME_PERIOD_SET,
        allowedRoles: [ROLES.BUILDER],
        child: [],
      },
    ],
  },

  {
    title: 'ARO Event',
    icon: (
      <SVGIcon
        fontSize='small'
        sx={{ fill: 'transparent', color: '#fff' }}
        inheritViewBox
        component={CustomIconEvent}
      />
    ),
    path: null,
    parentPath: '/aro-event',
    allowedRoles: [ROLES.BUILDER],
    child: [
      {
        title: 'Event Definition',
        icon: <SVGIcon fontSize='small' sx={{ fill: 'none' }} inheritViewBox component={CustomIconServer} />,
        path: PATH_CONSTANT.SYSTEM.KMS.DATA_EVENT.EVENT_DEFINTION,
        allowedRoles: [ROLES.BUILDER],
        child: [],
      },
    ],
  },

  {
    title: 'ARO Function',
    icon: <FunctionsOutlinedIcon sx={{ color: '#fff' }} fontSize='small' />,
    path: null,
    parentPath: '/aro-function',
    allowedRoles: [ROLES.BUILDER],
    child: [
      {
        title: 'Function Definition',
        icon: <SVGIcon fontSize='small' sx={{ fill: 'none' }} inheritViewBox component={CustomIconServer} />,
        path: PATH_CONSTANT.SYSTEM.FUNCTION.FUNCTION_DEFINITION,
        allowedRoles: [ROLES.BUILDER],
        child: [],
      },
    ],
  },
  {
    title: 'ARO View',
    icon: <ViewCompactOutlinedIcon sx={{ color: '#fff' }} fontSize='small' />,
    path: null,
    parentPath: '/aro-view',
    allowedRoles: [ROLES.BUILDER],
    child: [
      {
        title: 'Standard View Definition',
        icon: <SVGIcon fontSize='small' sx={{ fill: 'none' }} inheritViewBox component={CustomIconServer} />,
        path: PATH_CONSTANT.SYSTEM.VIEW.STANDARD_VIEW_DEFINITION,
        allowedRoles: [ROLES.BUILDER],
        child: [],
      },
      {
        title: 'Insight View Definition',
        icon: <SVGIcon fontSize='small' sx={{ fill: 'none' }} inheritViewBox component={CustomIconServer} />,
        path: PATH_CONSTANT.SYSTEM.VIEW.INSIGHT_VIEW_DEFINITION,
        allowedRoles: [ROLES.BUILDER],
        child: [],
      },
    ],
  },

  {
    title: 'Master Table',
    icon: <SVGIcon fontSize='small' sx={{ fill: 'none' }} inheritViewBox component={CustomIconCreditCard} />,
    parentPath: '/master-table',
    path: null,
    allowedRoles: [ROLES.ADMIN],
    child: [
      {
        title: 'System Table Data',
        path: PATH_CONSTANT.TENANT_MASTER.MASTER_TABLE.SYSTEM,
        accessAdminRightLevel: 4,
      },
      {
        title: 'Server Table Date',
        path: PATH_CONSTANT.TENANT_MASTER.MASTER_TABLE.SERVER,
        accessAdminRightLevel: 4,
      },
      {
        title: 'Tenant Table Data',
        path: PATH_CONSTANT.TENANT_MASTER.MASTER_TABLE.TENANT,
        accessAdminRightLevel: 3,
      },
      {
        title: 'Person Table Data',
        path: PATH_CONSTANT.TENANT_MASTER.MASTER_TABLE.PERSON,
        accessAdminRightLevel: 1,
      },
    ],
  },

  {
    title: 'SIDEBAR_MENU.MEMBER_LIST',
    icon: <SVGIcon fontSize='small' sx={{ fill: 'none' }} inheritViewBox component={CustomIconCreditCard} />,
    path: PATH_CONSTANT.TENANT.KMS.MEMBER_LIST,
    allowedRoles: [ROLES.MANAGER, ROLES.TESTER, ROLES.ADMIN, ROLES.PACKAGER, ROLES.SUPPORT, ROLES.TRAINER],
    child: [],
  },
  {
    title: 'Display Table',
    icon: <SVGIcon fontSize='small' sx={{ fill: 'none' }} inheritViewBox component={CustomIconCreditCard} />,
    path: PATH_CONSTANT.TENANT_MASTER.DISPLAY_TABLE,
    allowedRoles: [ROLES.ADMIN],
    accessAdminRightLevel: 5,
    child: [],
  },

  {
    title: 'Data Model',
    icon: <SVGIcon fontSize='small' sx={{ fill: 'none' }} inheritViewBox component={CustomIconCreditCard} />,
    parentPath: '/data-model',
    path: null,
    allowedRoles: [ROLES.MANAGER, ROLES.TESTER, ROLES.ADMIN, ROLES.PACKAGER, ROLES.SUPPORT, ROLES.TRAINER],
    child: [
      {
        title: 'Data Input',
        path: PATH_CONSTANT.TENANT.KMS.DATA_INPUT,
      },
      {
        title: 'Cube',
        path: PATH_CONSTANT.TENANT.CUBE.CUBE,
      },
    ],
  },
  {
    title: 'Event',
    icon: <SVGIcon fontSize='small' sx={{ fill: 'none' }} inheritViewBox component={CustomIconCreditCard} />,
    path: '/event',
    allowedRoles: [ROLES.MANAGER, ROLES.TESTER, ROLES.ADMIN, ROLES.PACKAGER, ROLES.SUPPORT, ROLES.TRAINER],
    child: [],
  },
  {
    title: 'Configuration',
    icon: <SVGIcon fontSize='small' sx={{ fill: 'none' }} inheritViewBox component={CustomIconCreditCard} />,
    path: PATH_CONSTANT.TENANT.CONFIGURATION.CONFIGURATION,
    allowedRoles: [ROLES.MANAGER, ROLES.TESTER, ROLES.ADMIN, ROLES.PACKAGER, ROLES.SUPPORT, ROLES.TRAINER],
    child: [],
  },

  {
    title: 'KTree ',
    icon: <SVGIcon fontSize='small' sx={{ fill: 'none' }} inheritViewBox component={CustomIconServer} />,
    path: PATH_CONSTANT.TENANT.KMS.KTREE_DATA,
    allowedRoles: [ROLES.MANAGER, ROLES.TESTER, ROLES.ADMIN, ROLES.PACKAGER, ROLES.SUPPORT, ROLES.TRAINER],
    child: [],
  },
];
