import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import packageJson from '../../../package.json';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { IconButton } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

const ModalContentStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  height: 250,
  bgcolor: 'background.paper',
  borderRadius: 1,
  px: 3,
  py: 1,
};

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

export interface ModalAboutProps {
  visible: boolean;
  onClose: () => void;
}

export function ModalAbout(props: ModalAboutProps) {
  const { visible = false, onClose } = props;
  const { selectedTenant } = useSelector((state: RootState) => state.auth);

  return (
    <div>
      <Modal open={visible}>
        <Stack direction='column' sx={ModalContentStyle}>
          <Stack justifyContent='space-between' direction='row' alignItems='center'>
            <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
              About
            </Typography>
            <IconButton onClick={onClose} component='label'>
              <HighlightOffRoundedIcon sx={closeIconStyle} />
            </IconButton>
          </Stack>
          <Divider sx={{ mb: 1 }} />
          <Stack sx={{ width: '100%', mt: 3 }}>
            <Typography variant='input-label-gray'>
              Licensee: <b>{selectedTenant.tenant.organizationName}</b>​
            </Typography>
            <Typography variant='input-label-gray'>
              System:{' '}
              <b>
                {selectedTenant.tenant.industryName} {selectedTenant.tenant.systemCode}​
              </b>
            </Typography>
            <Typography variant='input-label-gray'>
              Platform Version: <b>{packageJson.version}</b>​
            </Typography>
            <br />
            <Typography variant='input-label-gray'>Supplied under licence</Typography>
            <Typography variant='input-label-gray'>All rights reserved.</Typography>
          </Stack>
        </Stack>
      </Modal>
    </div>
  );
}
