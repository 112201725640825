import { useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import { Divider, IconButton, Modal, SvgIcon, Typography } from '@mui/material';
import { DateConfigurationPage } from './components/DateConfigurationPage';
import { MyViewConfigurationPage } from './components/MyViewConfigurationPage';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { RoleBasedAccessProvider } from 'components/RBAC';
import { ROLES } from 'constant/PermissonConstant';
import { DataImportConfigurationPage } from './components/DataImportConfigurationPage';
const ModalContentStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  bgcolor: 'background.paper',
  minHeight: 600,
  maxHeight: 800,
  borderRadius: 1,
  py: 2,
};
const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };
interface ConfigurationPageProps {
  visible?: boolean;
  onClose?: () => void;
}

enum CONFIGURATION_MENU_ID {
  DATE_CONFIG = 'DATE_CONFIG',
  PEOPLE_CONFIG = 'PEOPLE_CONFIG',
  IMPORT_CONFIG = 'IMPORT_CONFIG',
}

const CONFIGURATION_MENU = [
  {
    id: CONFIGURATION_MENU_ID.PEOPLE_CONFIG,
    name: 'myView',
    icon: GroupOutlinedIcon,
    roles: [ROLES.BUILDER, ROLES.PACKAGER, ROLES.SUPPORT, ROLES.TESTER],
  },
  {
    id: CONFIGURATION_MENU_ID.DATE_CONFIG,
    name: 'Date',
    icon: CalendarMonthOutlinedIcon,
    roles: [ROLES.MANAGER, ROLES.BUILDER, ROLES.PACKAGER, ROLES.SUPPORT, ROLES.TESTER, ROLES.TRAINER],
  },
  {
    id: CONFIGURATION_MENU_ID.IMPORT_CONFIG,
    name: 'Data Import',
    icon: DriveFolderUploadOutlinedIcon,
    roles: [ROLES.BUILDER, ROLES.PACKAGER, ROLES.SUPPORT, ROLES.TESTER],
  },
];

function getPageContent(menuId: CONFIGURATION_MENU_ID | null) {
  switch (menuId) {
    case CONFIGURATION_MENU_ID.DATE_CONFIG:
      return <DateConfigurationPage />;
    case CONFIGURATION_MENU_ID.PEOPLE_CONFIG:
      return <MyViewConfigurationPage />;

    case CONFIGURATION_MENU_ID.IMPORT_CONFIG:
      return <DataImportConfigurationPage />;
    default:
      return null;
  }
}

export default function ConfigurationPage(props: Readonly<ConfigurationPageProps>) {
  const { visible = false, onClose } = props;
  const [selectedMenu, setSelectedMenu] = useState<CONFIGURATION_MENU_ID | null>(CONFIGURATION_MENU[0].id);
  const handleOnCloseModal = () => {
    setSelectedMenu(CONFIGURATION_MENU[0].id);
    onClose && onClose();
  };

  return (
    <Modal open={visible} onClose={handleOnCloseModal}>
      <Stack direction='column' sx={ModalContentStyle} px={2}>
        <Stack>
          <Stack justifyContent='space-between' direction='row' alignItems='center'>
            <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
              Tenant Settings
            </Typography>
            <IconButton onClick={handleOnCloseModal} component='label'>
              <HighlightOffRoundedIcon sx={closeIconStyle} />
            </IconButton>
          </Stack>
        </Stack>
        <Divider sx={{ mb: 1 }} />
        <Stack direction='row' gap={1} minHeight={400}>
          <Box width={210} bgcolor='#f9f9f9'>
            <List>
              {CONFIGURATION_MENU.map((item, index) => (
                <RoleBasedAccessProvider allowedRoles={item.roles}>
                  <ListItem key={item.id} disablePadding>
                    {item.id === selectedMenu && (
                      <Box
                        sx={{
                          position: 'absolute',
                          width: 5,
                          height: '100%',
                          backgroundColor: '#4856b7',
                        }}
                      />
                    )}
                    <ListItemButton
                      onClick={() => {
                        setSelectedMenu(item.id);
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 35,
                        }}
                      >
                        <SvgIcon component={item.icon} color={item.id === selectedMenu ? 'primary' : 'inherit'} />
                      </ListItemIcon>
                      <ListItemText
                        primary={item.name}
                        primaryTypographyProps={{
                          fontSize: 14,
                          fontWeight: item.id === selectedMenu ? 'bold' : 'normal',
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                </RoleBasedAccessProvider>
              ))}
            </List>
          </Box>
          {getPageContent(selectedMenu)}
        </Stack>
      </Stack>
    </Modal>
  );
}
