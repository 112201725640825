import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Autocomplete, FormControlLabel, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material';
import {
  FormTableAnalyticParameter,
  TABLE_ANALYTIC_SCALE_OPTION_VALUE,
  TABLE_ANALYTICS_SCALE_OPTIONS,
  tableAnalyticParameterValidatonSchema,
} from 'components/ButtonComponent/ButtonTableAnalytic';
import { useFormik } from 'formik';
import { useGetSubjectDetails } from 'services/v1/SystemTenant/AROKMS/SubjectService';
import { useGetTableAnalyticOptions } from 'services/v1/SystemTenant/AROKMS/TableAnalyticService';

const autocompleteStyles = {
  width: '55%',
  '& .MuiOutlinedInput-root': {
    padding: '1px 2px',
  },
  '& .MuiAutocomplete-popper': {
    fontSize: '10px',
  },
};

const textInputStyles = {
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

const radioButtonLabelStyle = {
  '& .MuiFormControlLabel-label': {
    fontSize: '14px',
    color: '#98A2AE',
  },
};

const radioButtonStyle = {
  color: '#98A2AE',
  '& .MuiSvgIcon-root': {
    fontSize: 20,
  },
  '&.Mui-checked': {
    color: '#42BB93',
  },
};

const emptyFormikValues: FormTableAnalyticParameter = {
  rowColumn: null,
  columnColumn: null,
  dataColumn: {
    label: 'Count',
    value: 'COUNT',
  },
  dataDecimals: '0',
  scale: 'NONE',
};

interface ModalParameterControllerProps {
  initialValues?: FormTableAnalyticParameter;
  onChange: (values: FormTableAnalyticParameter) => void;
  loading?: boolean;
}

export function ModalParameterController(props: ModalParameterControllerProps) {
  const { initialValues, onChange, loading = false } = props;
  const { subjectId } = useParams<{ subjectId: string }>();
  const { data: subjectDetails } = useGetSubjectDetails(subjectId);

  const { data: tableAnalyticOptions } = useGetTableAnalyticOptions({
    enable: true,
    subjectId: subjectDetails?.data?.id?.toString(),
  });

  const formik = useFormik<FormTableAnalyticParameter>({
    initialValues: initialValues || emptyFormikValues,
    validationSchema: tableAnalyticParameterValidatonSchema,
    enableReinitialize: true,
    onSubmit: (values, formikHelper) => {
      onChange({
        ...values,
        scale: values.scale === 'NONE' ? undefined : values.scale,
      });
      formikHelper.resetForm();
    },
  });

  const handleOnChange = (values: FormTableAnalyticParameter) => {
    onChange({
      ...values,
      scale: values.scale === 'NONE' ? undefined : values.scale,
    });
  };

  const rowOptions = useMemo(() => {
    if (tableAnalyticOptions?.data?.rowOptions) {
      return tableAnalyticOptions?.data?.rowOptions.filter(
        (option) => option.value !== formik.values.columnColumn?.value
      );
    }
  }, [tableAnalyticOptions?.data?.rowOptions, formik.values.columnColumn]);

  const columnOptions = useMemo(() => {
    if (tableAnalyticOptions?.data?.columnOptions) {
      return tableAnalyticOptions?.data?.columnOptions.filter(
        (option) => option.value !== formik.values.rowColumn?.value
      );
    }
  }, [tableAnalyticOptions?.data?.columnOptions, formik.values.rowColumn]);

  return (
    <Stack width='100%' gap={2}>
      <Stack direction='row' gap={2}>
        <Stack width='25%'>
          <Autocomplete
            options={columnOptions || []}
            value={formik.values.columnColumn}
            sx={{ ...autocompleteStyles, width: '100%' }}
            disabled={loading}
            onChange={(event, newValue) => {
              formik.setFieldValue('columnColumn', newValue);
              handleOnChange({ ...formik.values, columnColumn: newValue });
            }}
            clearIcon={null}
            renderInput={(params) => <TextField {...params} label='Column' placeholder='Select Attribute...' />}
          />
        </Stack>
        <Stack width='25%'>
          <Autocomplete
            options={rowOptions || []}
            value={formik.values.rowColumn}
            disabled={loading}
            sx={{ ...autocompleteStyles, width: '100%' }}
            onChange={(event, newValue) => {
              formik.setFieldValue('rowColumn', newValue);
              handleOnChange({ ...formik.values, rowColumn: newValue });
            }}
            clearIcon={null}
            renderInput={(params) => <TextField {...params} label='Row' placeholder='Select Attribute...' />}
          />
        </Stack>
        <Stack width='25%'>
          <Autocomplete
            options={tableAnalyticOptions?.data?.dataOptions || []}
            value={formik.values.dataColumn}
            disabled={loading}
            sx={{ ...autocompleteStyles, width: '100%' }}
            onChange={(event, newValue) => {
              formik.setFieldValue('dataColumn', newValue);
              handleOnChange({ ...formik.values, dataColumn: newValue });
            }}
            clearIcon={null}
            renderInput={(params) => <TextField {...params} label='Data' placeholder='Select Attribute...' />}
          />
        </Stack>
      </Stack>
      <Stack direction='row' gap={2} alignItems='center'>
        <Stack width='15%'>
          <TextField
            sx={textInputStyles}
            name='dataDecimals'
            label='Data Decimals'
            disabled={loading}
            value={formik.values.dataDecimals}
            onChange={(e) => {
              if (/^[0-9]*$/.test(e.target.value)) {
                formik.handleChange(e);
                handleOnChange({ ...formik.values, dataDecimals: e.target.value });
              }
            }}
            onBlur={formik.handleBlur}
            variant='outlined'
            size='small'
            placeholder='e.g. 3'
          />
        </Stack>
        <Stack width='30%' flexDirection='column'>
          <Typography
            variant='input-label-gray'
            sx={{
              fontWeight: 500,
              fontSize: '10px',
            }}
          >
            Scale
          </Typography>
          <Stack width='100%'>
            <RadioGroup
              value={formik.values.scale}
              onChange={(event, value) => {
                formik.setFieldValue('scale', value);
                if (value === TABLE_ANALYTIC_SCALE_OPTION_VALUE.MILLION) {
                  formik.setFieldValue('dataDecimals', '2');
                  handleOnChange({ ...formik.values, scale: value, dataDecimals: '2' });
                } else {
                  handleOnChange({ ...formik.values, scale: value });
                }
              }}
            >
              <Stack direction='row' spacing={1}>
                {TABLE_ANALYTICS_SCALE_OPTIONS.map((option) => (
                  <FormControlLabel
                    disabled={loading}
                    value={option.value}
                    sx={radioButtonLabelStyle}
                    control={<Radio sx={radioButtonStyle} />}
                    label={option.label}
                  />
                ))}
              </Stack>
            </RadioGroup>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
