import { useState, useMemo, useRef, useCallback, useEffect } from 'react';
import {
  DataGridProProps,
  GRID_TREE_DATA_GROUPING_FIELD,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import { CustomDataTable } from 'components/DatatableComponent';
import { StandardViewPanelOptionValues, StandardViewSettingPanel } from './components/StandardViewSettingPanel';
import { TenantHomeDashboardNavigationBar } from 'components/LayoutComponent/VerticalLayout/HeaderBar';
import { useParams } from 'react-router-dom';
import ModalInfoComponent, { ModalInfoProps } from 'components/ModalComponent/ModalInfoComponent';
import {
  useGetStandardViewData,
  useGetStandardViewMemberList,
} from 'services/v1/Tenant/AROView/DisplayStandardViewService';
import { formatCubeNumber } from 'utils/NumberUtils';
import { CUBE_PERCENTAGE_TYPE } from 'constant/CubeConstant';
import { jsonToGridData } from 'pages/Tenant/AroCube/CubeDataEntryPage/utils/CubeDataEntryUtils';
import { CustomGridTreeDataGroupingCell } from 'pages/Tenant/AroCube/CubeDataEntryPage/components/CustomGridTreeDataGroupingCell';
import { getColumnDataType } from 'config/RulesImplementation/dataTypeMapping';
import { getFilterOperatorByRuleTypeCode, RulesImplementation } from 'config/RulesImplementation/rulesMapping';
import { convertRuleTypeParamsToSnakeCase } from 'utils/String';
import { Column } from 'types/api/Tenant/AROKMS/DisplayTableTypes';
import { number } from 'yup';

export default function DisplayStandardViewPage() {
  const cubeSettingPanelRef = useRef<{
    isLoadingOptionsData: boolean;
  }>(null);

  const { viewId } = useParams<{ viewId: string }>();

  const [initialData, setInitialData] = useState<any>([]);
  const [modalInfo, setModalInfo] = useState<ModalInfoProps>({
    title: '',
    descriptionMessage: '',
    visible: false,
  });
  const [optionValue, setOptionValue] = useState<{
    date: string | null;
    memberId: string | null;
  }>({
    date: null,
    memberId: null,
  });

  const {
    data: viewData,
    isLoading: isLoadingStandardViewData,
    error: errorViewData,
  } = useGetStandardViewData(viewId, optionValue.memberId, optionValue.date);
  const { isLoading: isFetchingMember, error: errorMemberList } = useGetStandardViewMemberList(viewId);

  const error = errorMemberList ?? errorViewData;

  const renderCellCubeAttribute = useCallback(
    (params: GridRenderCellParams, decimalRule: string | null, percentageRule: string | null) => {
      const decimalPlaces = decimalRule ? +decimalRule : 0;
      if (percentageRule === CUBE_PERCENTAGE_TYPE.PERCENTAGE) {
        return params?.value && formatCubeNumber(params?.value, decimalPlaces, true);
      }
      return params?.value && formatCubeNumber(params?.value, decimalPlaces);
    },
    []
  );

  // @ts-ignore
  const columns: GridColDef[] = useMemo(() => {
    if (!viewData?.data) return [];

    return viewData?.data?.attributes?.map((item) => {
      if (item.cubeAttribute) {
        return {
          field: item.name,
          headerName: item.displayName,
          minWidth: 140,
          maxWidth: 300,
          type: number,
          headerClassName: 'custom-cube-tree-data-header',
          renderCell: (params) => renderCellCubeAttribute(params, item.decimal, item.percentage),
        };
      } else {
        return {
          editable: true,
          field: item.name,
          type: item.dataTypeCode ? getColumnDataType(item.dataTypeCode) : 'string',
          headerName: item.displayName,
          width: 150,
          align: 'left',
          headerAlign: 'left',

          // @ts-ignore
          ...RulesImplementation(
            {
              allDropdownOptions: [],
              allowEmpty: true,
              colSubjectId: item.id,
              dataTypeCode: item.dataTypeCode,
              dropdownOptions: [],
              editable: false,
              ktreeOptions: [],
              nullable: true,
              ruleTypeCode: item.ruleTypeCode,
              renderAsRuleTypeCode: item.renderAsRuleTypeCode,
              renderAsRuleTypeParams: item.renderAsRuleTypeParams,
            } as Column,
            // @ts-ignore
            convertRuleTypeParamsToSnakeCase(item.ruleTypeParams)
          ),

          filterOperators: getFilterOperatorByRuleTypeCode(item.ruleTypeCode),
        };
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewData?.data]);

  const handleOnOptionChange = (data: StandardViewPanelOptionValues) => {
    const { date, member } = data;
    setOptionValue({
      date: date,
      memberId: member?.value?.toString() ?? null,
    });
  };

  const isLoadingData = isLoadingStandardViewData || isFetchingMember;
  const calculatedAttributes = useMemo(() => {
    return viewData?.data.members.filter((attribute) => attribute.isCalculated).map((attr) => attr.name);
  }, [viewData]);

  const checkGetCellClassName = useCallback(
    (params) => {
      const { field } = params;
      const cellAttribute = params?.id?.split('#')?.[0] || '';
      const isCellAttributeInCalculated = calculatedAttributes?.includes(cellAttribute);
      if (field === '__tree_data_group__') {
        return isCellAttributeInCalculated ? 'font-bold' : '';
      }
      let className = params.indexRelativeToCurrentPage % 2 === 0 ? '' : 'cube-odd';
      if (isCellAttributeInCalculated) className += ' calculated';
      return className;
    },
    [calculatedAttributes]
  );

  useEffect(() => {
    if (viewData?.data?.data) {
      if (Object.keys(viewData.data.data).length === 0) return;
      setInitialData(
        jsonToGridData(
          {
            attributes: viewData?.data.members,
            data: viewData?.data.data,
          },
          true
        )
      );
    }
  }, [viewData?.data]);

  const groupingColDef: DataGridProProps['groupingColDef'] = {
    headerName: '',
    pinnable: true,
    filterable: true,
    leafField: 'name',
    minWidth: 240,
    maxWidth: 500,
    cellClassName: 'custom-cube-tree-data-grouping-cell',
    headerClassName: 'custom-cube-tree-data-header',
    renderCell: CustomGridTreeDataGroupingCell,
  };
  return (
    <>
      <TenantHomeDashboardNavigationBar error={error}>
        <StandardViewSettingPanel
          ref={cubeSettingPanelRef}
          disabled={isLoadingData}
          onOptionChange={handleOnOptionChange}
        />

        <CustomDataTable
          loading={isLoadingData}
          initialState={{
            pinnedColumns: { left: [GRID_TREE_DATA_GROUPING_FIELD] },
            detailPanel: {
              expandedRowIds: [1],
            },
          }}
          columns={columns}
          rows={initialData}
          treeData
          getRowId={(row) => row?.id || 0}
          getTreeDataPath={(row) => row.path}
          groupingColDef={groupingColDef}
          disableSelectionOnClick
          getCellClassName={checkGetCellClassName}
          checkboxSelection={false}
          rowsPerPageOptions={[5, 10, 20]}
          isGroupExpandedByDefault={(params) => {
            return true;
          }}
          paginationMode='client'
          editMode='cell'
          sortingMode='client'
          columnBuffer={2}
          columnThreshold={2}
          sx={{ height: 300 }}
          filterMode='client'
          autoHeight={false}
          components={{
            Toolbar: null,
            Pagination: null,
          }}
        />
      </TenantHomeDashboardNavigationBar>
      <ModalInfoComponent
        visible={modalInfo.visible}
        title={modalInfo.title}
        descriptionMessage={modalInfo.descriptionMessage}
        onClose={() => setModalInfo({ ...modalInfo, visible: false })}
      />
    </>
  );
}
