import { useState } from 'react';
import { AxiosResponse } from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import qs from 'query-string';
import axios from 'libs/Axios';

import { AxiosDefaultErrorEntity } from 'types/api/Common/ErrorTypes';
import { PaginationAndSortingParams } from 'types/api/Common/PaginationTypes';
import { WithOptimisticUpdate } from 'utils/WithOptimisticUpdate';
import {
  DataInputFileUploadResponse,
  DataInputImportActivity,
  DataInputObjectFile,
  DataInputUploadFileImportRequest,
  DisplayDynamicFormResponse,
  DisplayTableDeletePayload,
  DisplayTableResponse,
  DynamicDataItem,
  Field,
  GetPullAttributeMappingResponse,
  RequestNextPrimaryKeySuggestion,
  ResponseNextPrimaryKeySuggestion,
} from 'types/api/Tenant/AROKMS/DisplayTableTypes';
import {
  getAdditionalConfigFormByRule,
  disabledFieldForNonEditColumn,
} from 'pages/Tenant/AROKMS/DataInput/components/FormInputConfig';
import { usePrefetchQueryManualKey } from 'hooks/usePrefetchQuery';

export interface DisplayTableParams extends PaginationAndSortingParams {
  subjectId?: string | number | undefined;
  procedureId?: string;
}

export const DISPLAY_TABLE_QUERY_KEY = {
  DATA_LIST: 'DATA_LIST',
  COLUMNS: 'COLUMNS',
  FORM_INPUT: 'FORM_INPUT',
  LIST_PULL_ATTRIBUTE_MAPPING: 'LIST_PULL_ATTRIBUTE_MAPPING',
  DATA_INPUT_CURRENT_IMPORT_ACTIVITY: 'DATA_INPUT_CURRENT_IMPORT_ACTIVITY',
  DATA_INPUT_IMPORT_HISTORY: 'DATA_INPUT_IMPORT_HISTORY',
  DATA_INPUT_FILE_OBJECTS_LIST: 'DATA_INPUT_FILE_OBJECTS_LIST',
};

//  Get Table Data
export function useDisplayTable(filter: DisplayTableParams) {
  return useQuery<AxiosResponse<DisplayTableResponse>, AxiosDefaultErrorEntity>(
    [DISPLAY_TABLE_QUERY_KEY.DATA_LIST, { filter }],
    () => {
      return axios.get(`/api/v1/display-table/fetch?${qs.stringify(filter)}`).then((res) => res);
    },
    {
      enabled: !!filter.subjectId,
      staleTime: 50000,
    }
  );
}

export function usePrefetchDataInputTable() {
  return usePrefetchQueryManualKey(
    (filter: DisplayTableParams) => axios.get(`/api/v1/display-table/fetch?${qs.stringify(filter)}`).then((res) => res),
    {
      staleTime: 100000,
    }
  );
}

// Get Table Colunms
// We have to seperate this from useDisplayTable, because whenever filter changes the columns will be re-rendered
// and it will cause the table to re-render as well and the filter will be reset
// so we have to seperate the columns and the data in to seperate query
export function useDisplayTableColumns(filter: DisplayTableParams) {
  return useQuery<AxiosResponse<DisplayTableResponse>, AxiosDefaultErrorEntity>(
    [DISPLAY_TABLE_QUERY_KEY.COLUMNS, { subjectId: filter.subjectId }],
    () => {
      return axios.get(`/api/v1/display-table/fetch?${qs.stringify(filter)}`).then((res) => res);
    },
    {
      enabled: !!filter.subjectId,
    }
  );
}

// Insert Data or Data Input
export function useUpsertDisplayTable(filter: DisplayTableParams) {
  const queryClient = useQueryClient();
  const listQuery = [DISPLAY_TABLE_QUERY_KEY.DATA_LIST, { filter }];
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, DynamicDataItem>(
    (bodyRequest) => axios.post(`/api/v1/display-table/input?${qs.stringify(filter)}`, bodyRequest),
    {
      onMutate: async (bodyRequest) =>
        WithOptimisticUpdate<DynamicDataItem>(bodyRequest, listQuery, queryClient, 'UPDATE'),
      onError: (err, variables, context) => {
        //@ts-ignore
        if (context?.previousData) {
          //@ts-ignore
          queryClient.setQueryData(listQuery, context.previousData);
        }
      },
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries(listQuery);
        queryClient.invalidateQueries([DISPLAY_TABLE_QUERY_KEY.FORM_INPUT]);
      },
    }
  );
}

export function useUpdateDisplayData(filter: DisplayTableParams) {
  const queryClient = useQueryClient();
  const listQuery = [DISPLAY_TABLE_QUERY_KEY.DATA_LIST, { filter }];
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, DynamicDataItem>(
    (bodyRequest) => axios.post(`/api/v1/display-table/edit?${qs.stringify(filter)}`, bodyRequest),
    {
      onMutate: async (bodyRequest) =>
        WithOptimisticUpdate<DynamicDataItem>(bodyRequest, listQuery, queryClient, 'UPDATE'),
      onError: (err, variables, context) => {
        //@ts-ignore
        if (context?.previousData) {
          //@ts-ignore
          queryClient.setQueryData(listQuery, context.previousData);
        }
      },
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries(listQuery);
        queryClient.invalidateQueries([DISPLAY_TABLE_QUERY_KEY.FORM_INPUT]);
      },
    }
  );
}

export function useDeleteDisplayData(filter: DisplayTableParams) {
  const queryClient = useQueryClient();
  const listQuery = [DISPLAY_TABLE_QUERY_KEY.DATA_LIST, { filter }];
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, DisplayTableDeletePayload>(
    ({ subjectId, ids }) =>
      axios.post(
        `/api/v1/display-table/delete?${qs.stringify({
          subjectId,
        })}`,
        ids
      ),
    {
      onMutate: async (bodyRequest) =>
        WithOptimisticUpdate<DisplayTableDeletePayload>(bodyRequest, listQuery, queryClient, 'DELETE'),
      onError: (err, variables, context) => {
        //@ts-ignore
        if (context?.previousData) {
          //@ts-ignore
          queryClient.setQueryData(listQuery, context.previousData);
        }
      },
      onSuccess(data, variables, context) {
        queryClient.refetchQueries(listQuery);
        queryClient.refetchQueries([DISPLAY_TABLE_QUERY_KEY.COLUMNS]);
      },
    }
  );
}

export function useDeleteDataInputObject() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, { ids: string[] }>(
    ({ ids }) => axios.post(`/api/v1/display-table/objects/delete`, ids),
    {
      onSuccess() {
        queryClient.refetchQueries([DISPLAY_TABLE_QUERY_KEY.DATA_INPUT_FILE_OBJECTS_LIST]);
      },
    }
  );
}

export function useDisplayFormInput(filter: {
  subjectId: string | undefined | number;
  recordId?: string;
  procedureId?: string;
}) {
  return useQuery<DisplayDynamicFormResponse, AxiosDefaultErrorEntity>(
    [
      DISPLAY_TABLE_QUERY_KEY.FORM_INPUT,
      { subjectId: filter.subjectId, recordId: filter.recordId, procedureId: filter.procedureId },
    ],
    () => {
      return axios
        .get(
          `/api/v1/display-table/form-input?${qs.stringify({
            subjectId: filter.subjectId,
            recordId: filter.recordId,
            procedureId: filter.procedureId,
          })}`
        )
        .then((res) => {
          const isEdit = !!filter.recordId;
          return {
            ...res.data,
            fields: res.data.fields.map((field: Field) => {
              return {
                ...field,
                ...disabledFieldForNonEditColumn(field, isEdit),
                ...getAdditionalConfigFormByRule(field, isEdit),
              };
            }),
          };
        });
    }
  );
}

export function useDataInputFileUpload() {
  const [progress, setProgress] = useState(0);

  const mutation = useMutation<AxiosResponse<DataInputFileUploadResponse>, AxiosDefaultErrorEntity, FormData>(
    (formData) => {
      return axios.post(`/api/v1/display-table/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total) - 5;
          setProgress(percentCompleted);
        },
      });
    }
  );

  return {
    ...mutation,
    progress,
  };
}

export function useGetDisplayObjects(objectIds: string[], subjectId: string) {
  return useQuery<AxiosResponse<DataInputObjectFile[]>, AxiosDefaultErrorEntity>(
    [DISPLAY_TABLE_QUERY_KEY.DATA_INPUT_FILE_OBJECTS_LIST, { subjectId, objectIds }],
    () => {
      return axios
        .get(
          `/api/v1/display-table/objects?${qs.stringify({
            objectIds,
          })}`
        )
        .then((res) => res);
    },
    {
      enabled: objectIds.length > 0 && !!subjectId,
    }
  );
}

export function useGetDataInputPullAttributeMapping(subjectId?: string | number) {
  return useQuery<AxiosResponse<GetPullAttributeMappingResponse>, AxiosDefaultErrorEntity>(
    [DISPLAY_TABLE_QUERY_KEY.LIST_PULL_ATTRIBUTE_MAPPING, { subjectId }],
    () => {
      return axios.get(`/api/v1/display-table/pull-attribute-mapping/${subjectId}`).then((res) => res);
    },
    {
      enabled: Boolean(subjectId),
    }
  );
}

export function useGetNextPrimaryKeySuggestion() {
  return useMutation<
    AxiosResponse<ResponseNextPrimaryKeySuggestion>,
    AxiosDefaultErrorEntity,
    RequestNextPrimaryKeySuggestion
  >((bodyRequest) =>
    axios.post(`/api/v1/display-table/primary-key/${bodyRequest.subjectId}`, { comparatorId: bodyRequest.comparatorId })
  );
}

export function useDataInputDownloadImportTemplate() {
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, { subjectId: string; fileType?: string }>(
    (bodyRequest) => {
      return axios.get(
        `/api/v1/display-table/import/sample-file?${qs.stringify({
          fileType: bodyRequest.fileType,
          subjectId: bodyRequest.subjectId,
        })}`,
        {
          responseType: 'blob',
        }
      );
    }
  );
}

export function useUploadDataInputImportFile() {
  const queryClient = useQueryClient();
  return useMutation<
    AxiosResponse<{
      operationId: string;
    }>,
    AxiosDefaultErrorEntity,
    DataInputUploadFileImportRequest
  >(
    (bodyRequest) => {
      const { formData, fileType, subjectId } = bodyRequest;
      return axios.post(
        `/api/v1/display-table/import/?${qs.stringify({
          fileType,
          subjectId,
        })}`,
        formData
      );
    },
    {
      onSuccess: (data, variables) => {
        queryClient.refetchQueries([DISPLAY_TABLE_QUERY_KEY.DATA_INPUT_CURRENT_IMPORT_ACTIVITY, variables.subjectId]);
        queryClient.refetchQueries([DISPLAY_TABLE_QUERY_KEY.DATA_INPUT_IMPORT_HISTORY, variables.subjectId]);
      },
    }
  );
}

export function useGetCurrentImportActivity(subjectId?: string, options?: { refetchInterval: number | false }) {
  return useQuery<AxiosResponse<DataInputImportActivity>, AxiosDefaultErrorEntity>(
    [DISPLAY_TABLE_QUERY_KEY.DATA_INPUT_CURRENT_IMPORT_ACTIVITY, subjectId],
    () => {
      return axios.get(`api/v1/display-table/${subjectId}/import/activity`).then((res) => res);
    },
    {
      enabled: !!subjectId,
      refetchInterval: options?.refetchInterval ?? false,
    }
  );
}
export function useGetDataInputImportHistory(subjectId?: string) {
  return useQuery<AxiosResponse<DataInputImportActivity[]>, AxiosDefaultErrorEntity>(
    [DISPLAY_TABLE_QUERY_KEY.DATA_INPUT_IMPORT_HISTORY, subjectId],
    () => {
      return axios.get(`api/v1/display-table/${subjectId}/import/history`).then((res) => res);
    },
    {
      enabled: !!subjectId,
    }
  );
}
export function useIgnoreImportNotification() {
  const queryClient = useQueryClient();
  return useMutation<
    AxiosResponse<string>,
    AxiosDefaultErrorEntity,
    {
      subjectId: string;
      importHistoryId: string;
      description: string | null;
    }
  >(
    (bodyRequest) => {
      const { description, importHistoryId, subjectId } = bodyRequest;
      return axios.put(`/api/v1/display-table/${subjectId}/import/ignore-notification/${importHistoryId}`, {
        description,
      });
    },
    {
      onSuccess: (data, variables) => {
        queryClient.refetchQueries([DISPLAY_TABLE_QUERY_KEY.DATA_INPUT_CURRENT_IMPORT_ACTIVITY, variables.subjectId]);
        queryClient.refetchQueries([DISPLAY_TABLE_QUERY_KEY.DATA_INPUT_IMPORT_HISTORY, variables.subjectId]);
      },
    }
  );
}
