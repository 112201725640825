import { useState, useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import {
  GridRowModesModel,
  GridRowModes,
  GridRowParams,
  GridFilterModel,
  useGridApiRef,
  GridSelectionModel,
  GridRowId,
  GridActionsCellItem,
  GridColumns,
  GridEnrichedColDef,
} from '@mui/x-data-grid-pro';
import { toast } from 'react-toastify';
import { PaginationAndSortingParams } from 'types/api/Common/PaginationTypes';
import { CustomDataTable } from 'components/DatatableComponent';
import { MainLayoutComponent } from 'components/LayoutComponent/SidebarLayout/MainLayoutComponent';
import ModalDeleteComponent, { ModalDeleteState } from 'components/ModalComponent/ModalDeleteComponent';
import { getErrorMessage } from 'utils/Error';
import { DASHBOARD_VIEW_MODE } from 'constant/DashboardTypeConstant';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import { useDeleteSubjectProcess } from 'services/v1/SystemTenant/AROKMS/SubjectProcessService';
import {
  useSolutionDefinitionList,
  useUpsertSolutionDefinitionData,
} from 'services/v1/SystemTenant/AROKMS/SolutionDefinitionService';
import { SolutionDefinitionRowItem } from 'types/api/SystemTenant/AROKMS/SolutionDefinitionTypes';

interface MemberListFilter extends PaginationAndSortingParams {
  subjectId?: string | number;
}

const panelStyle = {
  transform: 'translate3d(704px, 140.667px, 0px) !important',

  '& .MuiDataGrid-panelFooter': {
    display: 'none',
  },
};

export default function SolutionDefinitionPage() {
  const { t } = useTranslation();
  const { dashboardViewMode } = useSelector((state: RootState) => state.auth);
  const apiRef = useGridApiRef();
  const [muiDataTableKey] = useState<number>(0);

  const [rowData, setRowData] = useState<SolutionDefinitionRowItem[]>([]);
  const [selectedRowId, setSelectedRowId] = useState<GridRowId[]>([]);
  const [modalDeleteState, setModalDeleteState] = useState<ModalDeleteState>({ open: false, message: '', key: 0 });
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [filterButtonElement, setFilterButtonElement] = useState<HTMLButtonElement | null>(null);
  const [filter, setFilter] = useState<MemberListFilter>({
    page: 1,
    size: 10,
    filterValue: '',
    sortType: 'asc',
    filterOperator: 'contains',
  });

  const { isLoading, data: solutionDefinitionListData } = useSolutionDefinitionList();
  const { mutate: createSolutionDefinition } = useUpsertSolutionDefinitionData();
  const {
    mutateAsync: deleteSubjectProcess,
    isError: isErrorDelete,
    error: errorDelete,
    reset: resetStateDelete,
  } = useDeleteSubjectProcess();

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const isAnyEdit = useMemo(() => {
    return rowModesModel && Object.values(rowModesModel).some((item) => item.mode === GridRowModes.Edit);
  }, [rowModesModel]);

  const isBuilderViewOnly = dashboardViewMode === DASHBOARD_VIEW_MODE.VIEW_ONLY;

  const columns = useMemo<GridColumns>(() => {
    const actionColumn: GridEnrichedColDef = {
      field: 'actions',
      type: 'actions',
      headerName: 'Edit',
      width: 30,
      cellClassName: 'actions',

      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          // @ts-ignore
          return [<GridActionsCellItem icon={<SaveIcon />} label='Save' onClick={handleSaveClick(id)} />];
        }

        return [
          // @ts-ignore
          <GridActionsCellItem disabled={isAnyEdit} icon={<EditIcon />} label='Edit' onClick={handleEditClick(id)} />,
        ];
      },
    };
    const ktreeColumns: GridColumns = [
      {
        field: 'solutionName',
        headerName: 'Solution Name',
        width: 380,
        editable: true,
        filterable: true,
        sortable: true,
      },
    ];

    if (isBuilderViewOnly) return ktreeColumns;
    return [actionColumn, ...ktreeColumns];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [solutionDefinitionListData?.data, rowModesModel, isAnyEdit, isBuilderViewOnly]);

  useEffect(() => {
    if (solutionDefinitionListData?.data) {
      setRowData(solutionDefinitionListData.data);
    }
  }, [solutionDefinitionListData?.data]);

  // Process row Update will be triggered when
  // user modify or insert new row the table
  const handleProcessRowUpdate = (data: SolutionDefinitionRowItem, oldData: SolutionDefinitionRowItem) => {
    if (data?.isNew) {
      createSolutionDefinition({
        solutionName: data.solutionName,
      });
    } else {
      createSolutionDefinition({
        solutionName: data.solutionName,
        id: data.id,
      });
    }

    return data;
  };

  const handleChangePage = (newPage: number) => {
    setFilter({ ...filter, page: newPage + 1 });
  };
  const handleChangePageSize = (newPageSize: number) => {
    setFilter({ ...filter, size: newPageSize });
  };

  const handleOnFilter = (params: GridFilterModel) => {
    let filterObj = {};
    if (params.items.length) {
      filterObj = {
        filterValue: params.items[0].value,
        filterOperator: params.items[0].operatorValue,
      };
    } else {
      filterObj = {
        filterValue: '',
        filterOperator: 'contains',
      };
    }
    setFilter({
      ...filter,
      ...filterObj,
    });
  };

  const handleSelectionModelChange = (newSelection: GridSelectionModel) => {
    setSelectedRowId(newSelection);
  };

  const handleDeleteData = async () => {
    try {
      if (selectedRowId.length === 0) return;
      const multipleDeleteRequest = selectedRowId.map((id) => deleteSubjectProcess({ id }));
      const results = await Promise.allSettled(multipleDeleteRequest);
      const hasError = results.some((result) => result.status === 'rejected');

      if (hasError) {
        const errorMessages = results
          .filter((result) => result.status === 'rejected')
          .map((result) => (result as PromiseRejectedResult).reason.message)
          .join(', ');

        throw new Error(`Failed to delete some processes: ${errorMessages}`);
      }
      setModalDeleteState({
        ...modalDeleteState,
        open: false,
        message: '',
      });
      toast(`Successfully delete ${selectedRowId.length} process(es)`, {
        toastId: 'delete-process',
        type: 'success',
      });
    } catch (err: any) {
      toast.update('delete-process', { type: 'error', render: getErrorMessage(err), isLoading: false });
    }
  };

  const handleOnClickDeleteButton = () => {
    if (selectedRowId.length) {
      setModalDeleteState({
        open: true,
        message: `Are you sure you want to delete ${selectedRowId.length} Process(es)?`,
      });
    }
  };

  const handleOnRowDoubleClick = useCallback(
    (params: GridRowParams, event: React.MouseEvent) => {
      if (isBuilderViewOnly || isAnyEdit) return;

      const { id } = params;
      handleEditClick(id as string);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setRowModesModel, isBuilderViewOnly, isAnyEdit]
  );

  const handleCloseModalDelete = () => {
    setModalDeleteState({ ...modalDeleteState, open: false, key: modalDeleteState && +modalDeleteState + 1 });
    resetStateDelete();
  };

  return (
    <MainLayoutComponent pageTitle='Solution Definition' breadcrumbs={[t('SIDEBAR_MENU.DASHBOARD'), 'Subject']}>
      <CustomDataTable
        key={muiDataTableKey}
        apiRef={apiRef}
        initialState={{
          pagination: {
            page: 0,
          },
        }}
        loading={isLoading}
        rows={rowData}
        columns={columns}
        rowCount={0}
        page={0}
        pageSize={filter.size}
        getRowId={(row) => row.id}
        rowModesModel={rowModesModel}
        disableSelectionOnClick
        rowsPerPageOptions={[5, 10, 20]}
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        experimentalFeatures={{ newEditingApi: true }}
        onFilterModelChange={handleOnFilter}
        onSelectionModelChange={handleSelectionModelChange}
        processRowUpdate={handleProcessRowUpdate}
        onRowDoubleClick={handleOnRowDoubleClick}
        onPageChange={handleChangePage}
        onPageSizeChange={handleChangePageSize}
        autoHeight={rowData.length !== 0 && rowData.length > 5}
        componentsProps={{
          panel: {
            sx: panelStyle,
            anchorEl: filterButtonElement,
          },
          toolbar: {
            setRows: setRowData,
            setRowModesModel,
            onDeleteData: handleOnClickDeleteButton,
            setFilterButtonElement: setFilterButtonElement,
            isEditing: rowModesModel && Object.values(rowModesModel).some((item) => item.mode === GridRowModes.Edit),
            disabled: isLoading || isBuilderViewOnly,
            config: {
              deleteButtonText: 'Delete',
              fieldFocusOnInsert: 'subject',
              insertButtonText: 'Add New Solution',
              insertInitialObject: {
                processName: '',
                isNew: true,
              },
            },
          },
        }}
      />

      <ModalDeleteComponent
        visible={modalDeleteState.open}
        message={modalDeleteState.message}
        key={modalDeleteState.key}
        onApprove={handleDeleteData}
        isError={isErrorDelete}
        errorMessage={getErrorMessage(errorDelete as any)}
        onClose={handleCloseModalDelete}
        onCancel={handleCloseModalDelete}
      />
    </MainLayoutComponent>
  );
}
