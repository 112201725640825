import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';

export const InsightViewRelativeTimeType = {
  PAST_TIME: 'PAST',
  CURRENT_TIME: 'CURRENT',
  FUTURE_TIME: 'FUTURE',
};

export const InsightViewType = {
  REPORT: 'REPORT',
  CHART: 'CHART',
};

export const InsightViewRelativeTimeCategory = {
  PERIOD_TIME: 'PERIOD',
  YEAR_TIME: 'YEAR',
};

export const TOTAL_RULE_DROPDOWN_OPTIONS = [
  {
    label: 'None',
    value: 'NONE',
  },
  {
    label: 'Rolling Quarter',
    value: 'ROLLING_QUARTER',
  },
  {
    label: 'Year to Date',
    value: 'YEAR_TO_DATE',
  },
];

export const ACCUMULATION_TYPE_DROPDOWN_OPTIONS = [
  {
    label: 'Individual',
    value: 'INDIVIDUAL',
  },
  {
    label: 'Year to Date',
    value: 'YEAR_TO_DATE',
  },
  {
    label: 'Rolling Quarter',
    value: 'ROLLING_QUARTER',
  },
  {
    label: 'Rolling Year',
    value: 'ROLLING_YEAR',
  },
];

export const MAP_INSIGHT_VIEW_RULE_WITH_LABEL = new Map([
  ['NONE', 'None'],
  ['ROLLING_QUARTER', 'Rolling Quarter'],
  ['YEAR_TO_DATE', 'Year to Date'],
]);

export const MAP_INSIGHT_VIEW_ACCUMULATION_TYPE_WITH_LABEL = new Map([
  ['INDIVIDUAL', 'Individual'],
  ['YEAR_TO_DATE', 'Year to Date'],
  ['ROLLING_QUARTER', 'Rolling Quarter'],
  ['ROLLING_YEAR', 'Rolling Year'],
]);

export const INSIGHT_VIEW_CUBE_ADDITIONAL_DROPDOWN_OPTIONS = [
  { label: 'Any Monthly Cubes', value: 'ANY_MONTHLY_CUBES' },
  { label: 'Any Weekly Cubes', value: 'ANY_WEEKLY_CUBES' },
];

export const INSIGHT_VIEW_CELL_COLOR_CODE = {
  RED: 'RED',
  ORANGE: 'ORANGE',
  YELLOW: 'YELLOW',
  GREEN: 'GREEN',
  BLUE: 'BLUE',
  INDIGO: 'INDIGO',
  VIOLET: 'VIOLET',
  PINK: 'PINK',
  BROWN: 'BROWN',
  GRAY: 'GRAY',
};

export const INSIGHT_VIEW_MEASUREMENT_TYPE = {
  MEASUREMENT_MEMBER: 'MEASUREMENT_MEMBER',
  MAIN_MEMBER: 'MAIN_MEMBER',
  INSIGHT_COLUMNS_MEMBER: 'INSIGHT_COLUMNS_MEMBER',
  SECOND_MEMBER: 'SECOND_MEMBER',
};

export const INSIGHT_VIEW_MEASURE_TYPE_DROPDOWN_OPTIONS: AutoCompleteItem[] = [
  {
    label: 'Measurement',
    value: INSIGHT_VIEW_MEASUREMENT_TYPE.MEASUREMENT_MEMBER,
  },
  {
    label: 'Insight Columns',
    value: INSIGHT_VIEW_MEASUREMENT_TYPE.INSIGHT_COLUMNS_MEMBER,
  },
  {
    label: 'kTree Member',
    value: INSIGHT_VIEW_MEASUREMENT_TYPE.MAIN_MEMBER,
  },
];

export const getInsightViewMeasureTypeDropdowns = (ktreeName?: string): AutoCompleteItem[] => {
  if (ktreeName) {
    return INSIGHT_VIEW_MEASURE_TYPE_DROPDOWN_OPTIONS.map((item) => {
      if (item.value === INSIGHT_VIEW_MEASUREMENT_TYPE.MAIN_MEMBER) {
        return {
          ...item,
          label: ktreeName,
        };
      }
      return item;
    });
  }
  return INSIGHT_VIEW_MEASURE_TYPE_DROPDOWN_OPTIONS;
};

export const getInsightViewDimensionFilterLabel = (
  mainDimensionKTreeName?: string,
  secondDimensionKTreeName?: string,
  selectedXDimensionTypeValue?: string,
  selectedYDimensionTypeValue?: string
) => {
  if (mainDimensionKTreeName) {
    if (
      (selectedXDimensionTypeValue === INSIGHT_VIEW_MEASUREMENT_TYPE.MEASUREMENT_MEMBER &&
        selectedYDimensionTypeValue === INSIGHT_VIEW_MEASUREMENT_TYPE.MAIN_MEMBER) ||
      (selectedXDimensionTypeValue === INSIGHT_VIEW_MEASUREMENT_TYPE.MAIN_MEMBER &&
        selectedYDimensionTypeValue === INSIGHT_VIEW_MEASUREMENT_TYPE.MEASUREMENT_MEMBER)
    ) {
      return 'Period';
    }
    if (
      (selectedXDimensionTypeValue === INSIGHT_VIEW_MEASUREMENT_TYPE.INSIGHT_COLUMNS_MEMBER &&
        selectedYDimensionTypeValue === INSIGHT_VIEW_MEASUREMENT_TYPE.MEASUREMENT_MEMBER) ||
      (selectedXDimensionTypeValue === INSIGHT_VIEW_MEASUREMENT_TYPE.MEASUREMENT_MEMBER &&
        selectedYDimensionTypeValue === INSIGHT_VIEW_MEASUREMENT_TYPE.INSIGHT_COLUMNS_MEMBER)
    ) {
      return mainDimensionKTreeName;
    }

    if (
      (selectedXDimensionTypeValue === INSIGHT_VIEW_MEASUREMENT_TYPE.INSIGHT_COLUMNS_MEMBER &&
        selectedYDimensionTypeValue === INSIGHT_VIEW_MEASUREMENT_TYPE.MAIN_MEMBER) ||
      (selectedXDimensionTypeValue === INSIGHT_VIEW_MEASUREMENT_TYPE.MAIN_MEMBER &&
        selectedYDimensionTypeValue === INSIGHT_VIEW_MEASUREMENT_TYPE.INSIGHT_COLUMNS_MEMBER)
    ) {
      return 'Measure';
    }
    if (
      (selectedXDimensionTypeValue === INSIGHT_VIEW_MEASUREMENT_TYPE.INSIGHT_COLUMNS_MEMBER &&
        selectedYDimensionTypeValue === INSIGHT_VIEW_MEASUREMENT_TYPE.SECOND_MEMBER) ||
      (selectedXDimensionTypeValue === INSIGHT_VIEW_MEASUREMENT_TYPE.SECOND_MEMBER &&
        selectedYDimensionTypeValue === INSIGHT_VIEW_MEASUREMENT_TYPE.INSIGHT_COLUMNS_MEMBER)
    ) {
      return mainDimensionKTreeName;
    }

    if (
      (selectedXDimensionTypeValue === INSIGHT_VIEW_MEASUREMENT_TYPE.MEASUREMENT_MEMBER &&
        selectedYDimensionTypeValue === INSIGHT_VIEW_MEASUREMENT_TYPE.SECOND_MEMBER) ||
      (selectedXDimensionTypeValue === INSIGHT_VIEW_MEASUREMENT_TYPE.SECOND_MEMBER &&
        selectedYDimensionTypeValue === INSIGHT_VIEW_MEASUREMENT_TYPE.MEASUREMENT_MEMBER)
    ) {
      return mainDimensionKTreeName;
    }

    if (
      (selectedXDimensionTypeValue === INSIGHT_VIEW_MEASUREMENT_TYPE.MAIN_MEMBER &&
        selectedYDimensionTypeValue === INSIGHT_VIEW_MEASUREMENT_TYPE.SECOND_MEMBER) ||
      (selectedXDimensionTypeValue === INSIGHT_VIEW_MEASUREMENT_TYPE.INSIGHT_COLUMNS_MEMBER &&
        selectedYDimensionTypeValue === INSIGHT_VIEW_MEASUREMENT_TYPE.INSIGHT_COLUMNS_MEMBER)
    ) {
      return 'Measure';
    }
  }
  return 'Member';
};

export const MAP_COLOR_CODE_WITH_COLOR_HEXA = new Map([
  [INSIGHT_VIEW_CELL_COLOR_CODE.RED, '#ef6d6d'],
  [INSIGHT_VIEW_CELL_COLOR_CODE.ORANGE, '#eda828'],
  [INSIGHT_VIEW_CELL_COLOR_CODE.YELLOW, '#f5f55e'],
  [INSIGHT_VIEW_CELL_COLOR_CODE.GREEN, '#6ecb6e'],
  [INSIGHT_VIEW_CELL_COLOR_CODE.BLUE, '#4466d8'],
  [INSIGHT_VIEW_CELL_COLOR_CODE.INDIGO, '#a16bc9'],
  [INSIGHT_VIEW_CELL_COLOR_CODE.VIOLET, '#EE82EE'],
  [INSIGHT_VIEW_CELL_COLOR_CODE.PINK, '#fbd6dc'],
  [INSIGHT_VIEW_CELL_COLOR_CODE.BROWN, '#ab6464c4'],
  [INSIGHT_VIEW_CELL_COLOR_CODE.GRAY, '#afaeae'],
]);

export const MAP_COLOR_CODE_WITH_CLASS_NAME = new Map([
  [INSIGHT_VIEW_CELL_COLOR_CODE.RED, 'insight-view-cell-red'],
  [INSIGHT_VIEW_CELL_COLOR_CODE.ORANGE, 'insight-view-cell-orange'],
  [INSIGHT_VIEW_CELL_COLOR_CODE.YELLOW, 'insight-view-cell-yellow'],
  [INSIGHT_VIEW_CELL_COLOR_CODE.GREEN, 'insight-view-cell-green'],
  [INSIGHT_VIEW_CELL_COLOR_CODE.BLUE, 'insight-view-cell-blue'],
  [INSIGHT_VIEW_CELL_COLOR_CODE.INDIGO, 'insight-view-cell-indigo'],
  [INSIGHT_VIEW_CELL_COLOR_CODE.VIOLET, 'insight-view-cell-violet'],
  [INSIGHT_VIEW_CELL_COLOR_CODE.PINK, 'insight-view-cell-pink'],
  [INSIGHT_VIEW_CELL_COLOR_CODE.BROWN, 'insight-view-cell-brown'],
  [INSIGHT_VIEW_CELL_COLOR_CODE.GRAY, 'insight-view-cell-gray'],
]);

export const INSIGHT_VIEW_CELL_COLOR_OPTIONS: AutoCompleteItem[] = [
  {
    label: 'Red',
    value: INSIGHT_VIEW_CELL_COLOR_CODE.RED,
  },
  {
    label: 'Orange',
    value: INSIGHT_VIEW_CELL_COLOR_CODE.ORANGE,
  },
  {
    label: 'Yellow',
    value: INSIGHT_VIEW_CELL_COLOR_CODE.YELLOW,
  },
  {
    label: 'Green',
    value: INSIGHT_VIEW_CELL_COLOR_CODE.GREEN,
  },
  {
    label: 'Blue',
    value: INSIGHT_VIEW_CELL_COLOR_CODE.BLUE,
  },
  {
    label: 'Indigo',
    value: INSIGHT_VIEW_CELL_COLOR_CODE.INDIGO,
  },
  {
    label: 'Violet',
    value: INSIGHT_VIEW_CELL_COLOR_CODE.VIOLET,
  },
  {
    label: 'Pink',
    value: INSIGHT_VIEW_CELL_COLOR_CODE.PINK,
  },
  {
    label: 'Brown',
    value: INSIGHT_VIEW_CELL_COLOR_CODE.BROWN,
  },
  {
    label: 'Gray',
    value: INSIGHT_VIEW_CELL_COLOR_CODE.GRAY,
  },
].sort((a, b) => a.label.localeCompare(b.label));
