import { useState, useRef, useMemo, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import ButtonLoading from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import ActivityIndicator from 'components/ActivityIndicatorComponent';
import { Formik, FormikProps } from 'formik';
import { TenantHomeDashboardNavigationBar } from 'components/LayoutComponent/VerticalLayout/HeaderBar';
import { ButtonBackToTop } from 'components/ButtonComponent/ButtonBackToTop';
import { PATH_CONSTANT } from 'constant/PathConstant';
import { useListCurrentSystemDropdown } from 'services/v1/TenantMaster/MasterTable/SystemTableDataService';
import { useGetListOrganizationDropdown } from 'services/v1/TenantMaster/MasterTable/TenantTableDataService';
import { TenantDetails } from 'types/api/TenantMaster/MasterTable/TenantTableDataTypes';
import { useUsersForDeployTenant } from 'services/v1/SystemTenant/TenantTableData';
import { useServerListDropdown } from 'services/v1/TenantMaster/MasterTable/ServerTableDataService';
import { CreateAndDeployTenantValidationSchema } from 'config/FormValidaton/TenantMaster/MasterTable/TenantTableValidationSchema';
import { useTenantDetails, useUpdateTenant } from 'services/v1/TenantMaster/MasterTable/TenantTableDataService';
import {
  getRolesBySelectedTenantCode,
  autocompleteStyles,
  userSelectionDataGridStyle,
  textInputAutoCompleteStyles,
} from './AddTenantPage';
import { useSearchParams } from 'react-router-dom';

const formInitialValues: TenantDetails = {
  serverId: null,
  systemId: null,
  displayName: null,
  organizationId: null,
  userId: [],
};

const textInputStyles = {
  width: '55%',
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

export default function EditTenantPage() {
  const formikRef = useRef<FormikProps<TenantDetails>>(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const [selectedFormValue, setSelectedFormValue] = useState<TenantDetails>(formInitialValues);
  const { data: allSystemListDropdown, isLoading: isLoadingSystem } = useListCurrentSystemDropdown();
  const { data: organizationsDropdown, isLoading: isLoadingOrganization } = useGetListOrganizationDropdown(
    selectedFormValue.systemId?.value || undefined
  );
  const { data: usersData, isLoading: isLoadingUsers } = useUsersForDeployTenant(
    getRolesBySelectedTenantCode(selectedFormValue.organizationId?.label),
    selectedFormValue.organizationId?.label
  );
  const { data: serverOptions, isLoading: isLoadingServer } = useServerListDropdown();
  const { data: tenantDetails, isLoading } = useTenantDetails(id);
  const { mutate: updateTenant, isLoading: isDeploying, error, isError } = useUpdateTenant();
  const isAnyLoading = isLoadingSystem || isLoadingOrganization || isLoadingServer || isLoadingUsers;

  const columns: GridColDef[] = [
    { field: 'fullName', headerName: 'Username', width: 180 },
    { field: 'roleType', headerName: 'Role Type', width: 100 },
    {
      field: 'userName',
      headerName: 'Email',
      width: 240,
    },
  ];

  const selectedIds = useMemo(() => {
    if (selectedFormValue.userId) {
      return selectedFormValue.userId.map((user) => user.id);
    }
    return [];
  }, [selectedFormValue.userId]);

  const handleOnSave = (data: TenantDetails) => {
    const payloadData = {
      systemId: data.systemId?.value || null,
      serverId: data.serverId?.value || null,
      organizationId: data.organizationId?.value || null,
      userId: data.userId,
      displayName: data.displayName,
      id,
    };
    updateTenant(payloadData, {
      onSuccess: () => {
        handleOnCancelInput();
        toast.success('Tenant has been updated successfully!');
      },
    });
  };
  useEffect(() => {
    if (tenantDetails) {
      formikRef.current?.setValues(tenantDetails.data);
      setSelectedFormValue(tenantDetails.data);
    }
  }, [tenantDetails]);

  const handleOnCancelInput = () => {
    const url =
      searchParams.get('refBackParam') != null
        ? searchParams.get('refBackParam')
        : PATH_CONSTANT.TENANT_MASTER.MASTER_TABLE.TENANT;
    const redirectPath = decodeURIComponent(url as string);
    navigate(redirectPath);
  };

  return (
    <TenantHomeDashboardNavigationBar>
      <ButtonBackToTop />
      <Box sx={{ height: '100%' }}>
        {isLoading ? (
          <Stack direction='column' sx={{ height: 500 }} justifyContent='center'>
            <ActivityIndicator />
          </Stack>
        ) : (
          <Formik
            innerRef={formikRef}
            initialValues={formInitialValues}
            validationSchema={CreateAndDeployTenantValidationSchema}
            onSubmit={handleOnSave}
          >
            {(formikProps: FormikProps<TenantDetails>) => {
              const { values, setFieldValue, handleChange, handleBlur, errors, touched } = formikProps;
              return (
                <Box sx={{ backgroundColor: '#fff', pb: 3, px: 3 }} alignItems='center'>
                  <Stack
                    direction='row'
                    alignItems='center'
                    py={1}
                    justifyContent='space-between'
                    sx={{ backgroundColor: '#fff' }}
                    spacing={2}
                  >
                    <Stack>
                      <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                        Edit Tenant
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack>
                    <Typography variant='input-label' fontStyle='italic' sx={{ pt: 1, fontSize: 13 }}>
                      (<span style={{ color: 'red' }}>*</span>) indicates required fields
                    </Typography>
                  </Stack>

                  <Grid
                    container
                    marginTop={2}
                    sx={{ backgroundColor: '#fff', py: 1, borderTop: '1px solid #E3EBF6' }}
                    justifyContent='space-between'
                    display='flex'
                    flexWrap='wrap'
                    alignItems='flex-start'
                  >
                    <Grid container item xs={6} direction='column'>
                      <Stack
                        direction='row'
                        spacing={2}
                        alignItems='center'
                        justifyContent='space-between'
                        sx={{ mt: 2 }}
                      >
                        <Typography variant='input-label'>
                          System
                          <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                        </Typography>
                        <Autocomplete
                          options={allSystemListDropdown?.data || []}
                          // @ts-ignore
                          value={values.systemId}
                          disabled
                          getOptionLabel={(option) => option.label}
                          clearIcon={null}
                          contentEditable={false}
                          sx={autocompleteStyles}
                          renderInput={(params) => (
                            <TextField {...params} sx={textInputAutoCompleteStyles} placeholder='Select System' />
                          )}
                        />
                      </Stack>
                      <Stack
                        direction='row'
                        spacing={2}
                        alignItems='center'
                        justifyContent='space-between'
                        sx={{ mt: 2 }}
                      >
                        <Typography variant='input-label'>
                          Organization
                          <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                        </Typography>
                        <Autocomplete
                          options={organizationsDropdown?.data || []}
                          // @ts-ignore
                          value={values.organizationId}
                          disabled
                          getOptionLabel={(option) => option.label}
                          clearIcon={null}
                          contentEditable={false}
                          sx={autocompleteStyles}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={textInputAutoCompleteStyles}
                              placeholder={isAnyLoading ? 'Loading...' : 'Select Organization'}
                            />
                          )}
                        />
                      </Stack>
                      <Stack
                        direction='row'
                        spacing={2}
                        alignItems='center'
                        justifyContent='space-between'
                        sx={{ mt: 2 }}
                      >
                        <Typography variant='input-label'>
                          Server
                          <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                        </Typography>
                        <Autocomplete
                          options={serverOptions || []}
                          // @ts-ignore
                          value={values.serverId}
                          disabled
                          getOptionLabel={(option) => option.label}
                          clearIcon={null}
                          contentEditable={false}
                          sx={autocompleteStyles}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={textInputAutoCompleteStyles}
                              placeholder={isAnyLoading ? 'Loading...' : 'Select Organization'}
                            />
                          )}
                        />
                      </Stack>
                      <Stack
                        direction='row'
                        spacing={2}
                        alignItems='center'
                        justifyContent='space-between'
                        sx={{ mt: 2 }}
                      >
                        <Typography variant='input-label'>Display Name</Typography>
                        <TextField
                          sx={textInputStyles}
                          size='small'
                          placeholder='e.g. Teamwork'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.displayName}
                          name='displayName'
                          error={touched.displayName && Boolean(errors.displayName)}
                          helperText={touched.displayName && errors.displayName}
                        />
                      </Stack>
                      <Stack direction='column' spacing={2} justifyContent='space-between' sx={{ mt: 2 }}>
                        <Typography variant='input-label' mb={2}>
                          Assign Users
                          <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                        </Typography>
                        <div style={{ height: 250, width: '100%' }}>
                          {!isLoadingUsers && (
                            <DataGridPro
                              loading={isLoadingUsers}
                              selectionModel={selectedIds}
                              sx={userSelectionDataGridStyle}
                              density='compact'
                              rows={usersData?.data || []}
                              columns={columns}
                              onSelectionModelChange={(newSelection) => {
                                if (usersData?.data) {
                                  const newUserIdValues = newSelection.map((id: any) => {
                                    return usersData.data.find((user) => user.id === id);
                                  });
                                  // @ts-ignore
                                  setSelectedFormValue((prevState) => ({
                                    ...prevState,
                                    userId: newUserIdValues,
                                  }));
                                  setFieldValue('userId', newUserIdValues);
                                }
                              }}
                              pagination={false}
                              hideFooter
                              rowsPerPageOptions={[5]}
                              checkboxSelection={
                                !isAnyLoading || (!!values.systemId && !!values.organizationId && !!values.serverId)
                              }
                            />
                          )}
                        </div>
                      </Stack>
                      {isError && (
                        <Stack mt={2}>
                          <Alert severity='error'>{error.response?.data.message}</Alert>
                        </Stack>
                      )}
                    </Grid>
                  </Grid>
                  <Stack direction='row' spacing={2} justifyContent='flex-end'>
                    <Button disabled={isDeploying} onClick={handleOnCancelInput} variant='main-table-panel-border'>
                      Cancel
                    </Button>

                    <ButtonLoading
                      loading={isDeploying}
                      variant='main-table-panel'
                      onClick={() => formikProps.handleSubmit()}
                      disabled={!CreateAndDeployTenantValidationSchema?.isValidSync(formikProps.values) || isDeploying}
                    >
                      Save
                    </ButtonLoading>
                  </Stack>
                </Box>
              );
            }}
          </Formik>
        )}
      </Box>
    </TenantHomeDashboardNavigationBar>
  );
}
