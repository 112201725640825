export function PDFViewer(props: { url: string }) {
  if (!props.url) return <p>Invalid URL</p>;
  return (
    <object
      data={props.url}
      type='application/pdf'
      style={{
        minHeight: '100vh',
        width: '100%',
      }}
    >
      <p>
        Your browser does not support PDFs. Download the PDF to view it: <a href={props.url}>Download PDF</a>.
      </p>
    </object>
  );
}
