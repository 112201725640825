import { useState } from 'react';
import { PeopleConfigListComponent } from './MyViewDashboardList';
import { MyViewDashboardDetail } from './MyViewDashboardDetail';
import { MyViewDashboardDefinitionItem } from 'types/api/Tenant/ConfigurationTypes';

enum PageContentID {
  PEOPLE_CONFIG_LIST = 'PEOPLE_CONFIG_LIST',
  PEOPLE_CONFIG_DETAILS = 'PEOPLE_CONFIG_DETAILS',
}

export enum ViewDetailsMode {
  INSERT = 'INSERT',
  EDIT = 'EDIT',
}

export const MyViewConfigurationPage = () => {
  const [pageContent, setPageContent] = useState<PageContentID>(PageContentID.PEOPLE_CONFIG_LIST);
  const [selectedDashboard, setSelectedDashboard] = useState<MyViewDashboardDefinitionItem | null>(null);
  const [viewDetailsMode, setViewDetailsMode] = useState<ViewDetailsMode | null>(null);

  const handleOnEdit = (data: MyViewDashboardDefinitionItem) => {
    setSelectedDashboard(data);
    setPageContent(PageContentID.PEOPLE_CONFIG_DETAILS);
    setViewDetailsMode(ViewDetailsMode.EDIT);
  };

  const handleOnAdd = () => {
    setPageContent(PageContentID.PEOPLE_CONFIG_DETAILS);
    setViewDetailsMode(ViewDetailsMode.INSERT);
  };

  switch (pageContent) {
    case PageContentID.PEOPLE_CONFIG_LIST:
      return <PeopleConfigListComponent onEdit={handleOnEdit} onAdd={handleOnAdd} />;

    case PageContentID.PEOPLE_CONFIG_DETAILS:
      return (
        <MyViewDashboardDetail
          detailMode={viewDetailsMode}
          selectedDashboard={selectedDashboard}
          onClose={() => {
            setPageContent(PageContentID.PEOPLE_CONFIG_LIST);
            setSelectedDashboard(null);
          }}
        />
      );
    default:
      return null;
  }
};
