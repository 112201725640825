import jwt_decode from 'jwt-decode';
import { Navigate, useLocation, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { DEFAUL_LOGIN_REDIRECT_URL, ROLES } from 'constant/PermissonConstant';

import { RootState } from 'store';
import { PATH_CONSTANT } from 'constant/PathConstant';
import { TENANT_INITIAL_SETUP_STATUS } from 'constant/TenantConfigConstant';
import { useGetMyPersonalization } from 'services/v1/Tenant/ConfigurationService';
import { getQuickAccessNavigateURL } from 'components/LayoutComponent/VerticalLayout/QuickAccess';

interface PrivateRouteProps {
  children: JSX.Element;
  isPrivate?: boolean;
  allowedRoles: string[];
  accessAdminRightLevel?: number;
}

type Props = PrivateRouteProps & RouteProps;

const CONSTANT_URL = {
  DEFAULT_AUTHENTICATED_REDIRECT: PATH_CONSTANT.SYSTEM.KMS.DATA_BUILDER.SUBJECT,
  DEFAULT_LOGIN_URL: PATH_CONSTANT.AUTH.LOGIN,
  SELECT_ROLE_URL: PATH_CONSTANT.AUTH.SELECT_ROLE,
  INITIAL_SETUP_URL: PATH_CONSTANT.AUTH.INITIAL_SETUP,
};

const AUTH_AND_CONFIG_PATH = Object.values(PATH_CONSTANT.AUTH);

export function checkAccess(allowedRoles: string[], currentRole: string) {
  if (!currentRole) return true;
  return allowedRoles.includes(currentRole);
}
export function checkAccessByAdminRightLevel(
  requiredAdminRightLevel: number | undefined,
  currentAdminRightLevel: number
) {
  if (!requiredAdminRightLevel) return true;
  return currentAdminRightLevel >= requiredAdminRightLevel;
}

export function PrivateRouteWrapper({ children, isPrivate = false, allowedRoles = [], accessAdminRightLevel }: Props) {
  const { pathname } = useLocation();
  const { accessToken, threadUserAsRole, tenants } = useSelector((state: RootState) => state.auth);
  const { setupStatus } = useSelector((state: RootState) => state.setting);
  const { data: userPersonalization } = useGetMyPersonalization({ enabled: true });
  const role = threadUserAsRole;
  const defaultURL = getQuickAccessNavigateURL(userPersonalization?.data.defaultLoginView, false, undefined, role);
  const decodeToken = accessToken && (jwt_decode(accessToken) as { adminRightLevel: number });

  const hasAccess =
    role === ROLES.ADMIN && accessAdminRightLevel
      ? checkAccess(allowedRoles, role) &&
        // @ts-ignore
        checkAccessByAdminRightLevel(accessAdminRightLevel, decodeToken.adminRightLevel || 0)
      : checkAccess(allowedRoles, role);

  // If not private route return children
  if (!isPrivate) return children;

  // If user has logged in and the not yet select role, redirect to select role page
  if (isPrivate && accessToken && !role && pathname !== CONSTANT_URL.SELECT_ROLE_URL && tenants.length > 1) {
    return <Navigate to={CONSTANT_URL.SELECT_ROLE_URL} />;
  }

  if (
    isPrivate &&
    accessToken &&
    setupStatus &&
    setupStatus !== TENANT_INITIAL_SETUP_STATUS.COMPLETED &&
    !AUTH_AND_CONFIG_PATH.includes(pathname)
  ) {
    return <Navigate to={CONSTANT_URL.INITIAL_SETUP_URL} />;
  }

  // if the user access root page
  if (pathname === '/') {
    // if logged in, redirect to default authenticated route
    if (accessToken) {
      return <Navigate to={defaultURL ?? DEFAUL_LOGIN_REDIRECT_URL[role]} />;
    }
    // otherwise , rediredt to login page
    if (!accessToken) return <Navigate to={CONSTANT_URL.DEFAULT_LOGIN_URL} />;
  }
  // if the user logged in and try to access the login page, redirect to the home page
  if (accessToken && pathname === '/auth/login') {
    return <Navigate to={defaultURL ?? DEFAUL_LOGIN_REDIRECT_URL[role]} />;
  }

  if (isPrivate && !accessToken) {
    return <Navigate to={`${CONSTANT_URL.DEFAULT_LOGIN_URL}?redirect=${pathname}`} />;
  }

  if (accessToken && role && !hasAccess) {
    return <Navigate to='/page-not-found' />;
  }

  return children;
}
