// @ts-nocheck
import { useCallback, useState, useMemo, useEffect, useRef } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { DataGridProProps, GRID_TREE_DATA_GROUPING_FIELD } from '@mui/x-data-grid-pro';
import { Stack } from '@mui/material';

import ActivityIndicator from 'components/ActivityIndicatorComponent';
import { CustomDataTable } from 'components/DatatableComponent';
import { getErrorMessage, getErrorTitle } from 'utils/Error';
import { CUBE_PERCENTAGE_TYPE } from 'constant/CubeConstant';
import { formatCubeNumber } from 'utils/NumberUtils';
import { getCellClassName, jsonToGridData } from '../CubeDataEntryPage/utils/CubeDataEntryUtils';
import { CustomGridTreeDataGroupingCell } from '../CubeDataEntryPage/components/CustomGridTreeDataGroupingCell';
import { CubeDimensionalViewPanel, DimensionalViewOptionValues } from './components/CubeDimensionalViewPanel';
import { useGetCubeDimensionalView } from 'services/v1/Tenant/AROCube/CubeDimensionalViewService';
import { RequestCubeDimensionalView } from 'types/api/Tenant/AROCube/CubeDataDimensionalViewTypes';
import {
  generateBreadCumbItemsV2,
  TenantHomeDashboardNavigationBar,
} from 'components/LayoutComponent/VerticalLayout/HeaderBar';
import { useGetBreadcumbItems } from 'services/v1/Common/NavBarMenuService';
import { NAVIGATION_COMPONENT_TYPE } from 'constant/NavigationConstant';
import { AnimatedPage } from 'components/AnimatedPage';
import { getPageAnimationByRefNavigation } from 'utils/Animation';
import { sortAttributeColumnByOrderKtree } from 'utils/Object';
import { RULES_CONSTANT } from 'constant/RuleConstant';
import { CubeDataEntryAttribute } from 'types/api/Tenant/AROCube/CubeDataEntryTypes';
import { useGetCubeDefinitionDetails } from 'services/v1/SystemTenant/AROCube/CubeBuilderService';
import { CubeDataEntryShowDataDetails } from '../CubeDataEntryPage/components/CubeDataEntryPanel';
import { ModalCubeEventDataDetails } from '../CubeDataEntryPage/components/ModalEventData';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';

const CUBE_DATA_TABS = {
  CUBE_ENTRY: 'CUBE_ENTRY',
  INITIAL_VALUE: 'INITIAL_VALUE',
  BASE_VALUE: 'BASE_VALUE',
};

const initialStateModalDetails = {
  timePeriod: null,
  cubeMember: null,
  eventSubjectId: null,
  eventSubjectName: null,
  open: false,
};

export default function CubeDimensionalViewPage() {
  const { cubeId } = useParams<{ cubeId: string }>();
  const cubeSettingPanelRef = useRef<{
    isLoadingOptionsData: boolean;
  }>(null);
  const [initialData, setInitialData] = useState<any>([]);
  const [selectedOptionValue, setSelectedOptionValue] = useState<AutoCompleteItem | null>(null);
  const [optionValue, setOptionValue] = useState<{
    xDimensionType: string | undefined;
    yDimensionType: string | undefined;
    layerId: string | undefined;
    memberId: string | undefined;
    xMemberId: string | undefined;
  }>({
    xDimensionType: undefined,
    yDimensionType: undefined,
    layerId: undefined,
    memberId: undefined,
    xMemberId: undefined,
  });

  const [modalDataDetails, setModalDataDetails] = useState<{
    open: boolean;
    eventSubjectId: string | null;
    eventSubjectName: string | null;
    cubeMember: string | null;
    timePeriod: string | null;
  }>(initialStateModalDetails);

  const { data: breadCrumbItems } = useGetBreadcumbItems(cubeId, NAVIGATION_COMPONENT_TYPE.CUBE);
  const [selectedTab] = useState(CUBE_DATA_TABS.CUBE_ENTRY);
  const [searchParams] = useSearchParams();
  const navigationRef = searchParams.get('ref');

  const { data: cubeDefinitionDetails } = useGetCubeDefinitionDetails(cubeId, {
    refetchInterval: false,
  });

  const [isLoadingOptionsData, setIsLoadingOptionsData] = useState(false);

  const dimensionalRequest: RequestCubeDimensionalView = {
    cubeId: cubeId,
    layerId: optionValue.layerId,
    filterValue: optionValue.memberId,
    secondaryFilterValue: optionValue.xMemberId,
    selectedXOptionType: optionValue.xDimensionType,
    selectedYOptionType: optionValue.yDimensionType,
  };
  const {
    data: cubeEntryData,
    isLoading: isLoadingDataEntry,
    isError: isErrorCubeData,
    error: errorCubeData,
  } = useGetCubeDimensionalView(dimensionalRequest);

  const pullFromEventAttributesMap = useMemo(() => {
    return new Map(
      cubeEntryData?.data.attributes
        .filter((attribute) => attribute.ruleTypeCode === RULES_CONSTANT.CUBE.RULE_CUBE_PULL_FROM_EVENT)
        .map((attr) => [attr.name, attr])
    );
  }, [cubeEntryData?.data.attributes]);

  const percentageRenderAttributes = useMemo(() => {
    if (cubeEntryData?.data) {
      return cubeEntryData?.data.attributes
        .filter((attr) => attr.percentage === CUBE_PERCENTAGE_TYPE.PERCENTAGE)
        ?.map((attr) => attr.name);
    }
    return [];
  }, [cubeEntryData?.data]);

  const decipalPlacesRenderAttributes: { [key: string]: number } = useMemo(() => {
    if (cubeEntryData?.data) {
      return cubeEntryData?.data.attributes.reduce((acc, attr) => {
        acc[attr.name] = attr.decimal;
        return acc;
      }, {} as { [key: string]: number });
    }
    return {};
  }, [cubeEntryData?.data]);

  const columns = useMemo(() => {
    if (cubeEntryData?.data) {
      const mapAttributeWithParent = new Map<string, string>();
      let columns;
      if (cubeEntryData?.data?.columnGrouping?.length > 0) {
        cubeEntryData?.data?.columnGrouping.forEach((col) => {
          mapAttributeWithParent.set(col?.member, col?.parent);
        });
        const allMembersAndParents = Object.keys(cubeEntryData?.data?.data);
        columns = sortAttributeColumnByOrderKtree(allMembersAndParents, mapAttributeWithParent);
      } else {
        columns = Object.keys(cubeEntryData?.data?.data);
      }

      return columns.map((attribute) => ({
        field: attribute,
        headerName: attribute,
        type: 'number',
        editable: false,
        minWidth: 120,
        headerClassName: 'custom-cube-tree-data-header',
        cellClassName: (params) => getCellClassName(params, initialData),
        renderCell: (params) => {
          const attributeName = params.id.split('#')[0];
          const isValueClickAble = !cubeEntryData.data.consolidateView && pullFromEventAttributesMap.has(attributeName);
          const decimalPlaces = decipalPlacesRenderAttributes?.[attributeName] || 0;

          let value;
          if (percentageRenderAttributes?.includes(attributeName)) {
            value = params?.value && formatCubeNumber(params?.value, decimalPlaces, true);
          } else {
            value = params?.value && formatCubeNumber(params?.value, decimalPlaces);
          }

          if (isValueClickAble) {
            return (
              <span
                style={{ color: 'blue', textDecoration: 'none', cursor: 'pointer' }}
                onClick={(e) => {
                  e.preventDefault();
                  handleOnCellValueClick({
                    attributeName,
                    timePeriod: attribute,
                  });
                }}
                onMouseEnter={(e) => {
                  e.target.style.textDecoration = 'underline';
                }}
                onMouseLeave={(e) => {
                  e.target.style.textDecoration = 'none';
                }}
              >
                {value}
              </span>
            );
          }

          return value;
        },
      }));
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cubeEntryData?.data, initialData, percentageRenderAttributes, decipalPlacesRenderAttributes]);

  const handleOnShowDataDetails = useCallback(
    (data: CubeDataEntryShowDataDetails) => {
      setModalDataDetails({
        eventSubjectName: data.eventSubjectName,
        eventSubjectId: data.eventSubjectId,
        cubeMember: data.cubeMember || optionValue.memberId,
        timePeriod: data.timePeriod,
        open: true,
      });
    },
    [optionValue.memberId]
  );

  const handleOnCellValueClick = useCallback(
    ({ attributeName, timePeriod }: { attributeName: string; timePeriod: string }) => {
      const cubeAttribute: CubeDataEntryAttribute | null = pullFromEventAttributesMap.get(attributeName);
      const attributeSubject = cubeDefinitionDetails?.data.attributes.find((attr) => attr.id === cubeAttribute?.id);
      if (attributeSubject && timePeriod) {
        handleOnShowDataDetails({
          timePeriod,
          cubeMember: cubeEntryData?.data.member?.value,
          eventSubjectId: attributeSubject.subjectEvent.value,
          eventSubjectName: attributeSubject.subjectEvent.label,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [optionValue.memberId, cubeDefinitionDetails?.data.attributes, pullFromEventAttributesMap]
  );

  useEffect(() => {
    if (cubeEntryData?.data) {
      if (Object.keys(cubeEntryData.data.data).length === 0) return;
      setInitialData(jsonToGridData(cubeEntryData?.data, cubeEntryData?.data?.selectedXOption.type === 'MAIN_MEMBER'));
    }
  }, [cubeEntryData?.data, selectedTab]);

  const breadCrumbs = useMemo(() => {
    return generateBreadCumbItemsV2(breadCrumbItems?.data);
  }, [breadCrumbItems?.data]);

  const groupingColDef: DataGridProProps['groupingColDef'] = {
    headerName: '',
    pinnable: true,
    filterable: true,
    leafField: 'name',
    minWidth: 200,
    maxWidth: 400,
    cellClassName: 'custom-cube-tree-data-grouping-cell',
    headerClassName: 'custom-cube-tree-data-header',
    renderCell: CustomGridTreeDataGroupingCell,
  };

  const calculatedAttributes = useMemo(() => {
    if (cubeEntryData?.data) {
      return cubeEntryData?.data.attributes.filter((attribute) => attribute.isCalculated).map((attr) => attr.name);
    }
    return [];
  }, [cubeEntryData?.data]);

  const checkGetCellClassName = useCallback(
    (params) => {
      const { field } = params;
      const cellAttribute = params?.id?.split('#')?.[0] || '';
      const isCellAttributeInCalculated = calculatedAttributes?.includes(cellAttribute);

      if (field === '__tree_data_group__') {
        return isCellAttributeInCalculated ? 'font-bold' : '';
      }

      let className = params.indexRelativeToCurrentPage % 2 === 0 ? '' : 'cube-odd';
      if (isCellAttributeInCalculated) className += ' calculated';
      return className;
    },
    [calculatedAttributes]
  );

  const handleOnOptionChange = (data: DimensionalViewOptionValues) => {
    setOptionValue({
      xDimensionType: data.xDimensionType?.type,
      yDimensionType: data.yDimensionType?.type,
      layerId: data.layer?.value,
      memberId: data.member?.value,
      xMemberId: data.xMember?.value,
    });
    const selectedOptionValue: AutoCompleteItem = {
      label: data.member?.label,
      value: data.member?.value,
    };
    setSelectedOptionValue(selectedOptionValue);
  };

  const isLoadingData = isLoadingDataEntry;

  const initialValuesSelection = useMemo(() => {
    if (cubeEntryData) {
      const initialValues = {
        xDimensionType: cubeEntryData?.data.selectedXOption,
        yDimensionType: cubeEntryData?.data.selectedYOption,
      };

      return initialValues;
    }
  }, [cubeEntryData]);

  const initialScrollColumnIndex = useMemo(() => {
    if (cubeEntryData?.data) {
      return cubeEntryData?.data.todayTimePeriodIndex + 2;
    }
    return 0;
  }, [cubeEntryData?.data]);

  return (
    <TenantHomeDashboardNavigationBar
      breadCrumbs={breadCrumbs}
      containerSx={{ height: '100%', flex: 1, flexDirection: 'column', overflowY: 'auto', display: 'flex' }}
      rootContainerSx={{ height: '95vh' }}
    >
      <AnimatedPage
        key={cubeId || 'cube-id-insight'}
        variants={getPageAnimationByRefNavigation(navigationRef)}
        style={{
          height: '100%',
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
        }}
      >
        <CubeDimensionalViewPanel
          ref={cubeSettingPanelRef}
          cubeDefinitionId={dimensionalRequest.cubeId}
          disabled={isLoadingData}
          initialValues={initialValuesSelection}
          xDimensionOptions={cubeEntryData?.data?.xoptions}
          yDimensionOptions={cubeEntryData?.data?.yoptions}
          loading={isLoadingData}
          setIsLoadingOptionsData={setIsLoadingOptionsData}
          onOptionChange={handleOnOptionChange}
        />

        {isLoadingData || isLoadingOptionsData ? (
          <Stack direction='column' sx={{ bgcolor: '#fff', py: 3, height: '300px', px: 2 }} justifyContent='center'>
            <ActivityIndicator />
          </Stack>
        ) : (
          <CustomDataTable
            loading={isLoadingData}
            isError={isErrorCubeData}
            errorTitle={getErrorTitle(errorCubeData)}
            errorMessage={getErrorMessage(errorCubeData)}
            initialState={{
              pinnedColumns: { left: [GRID_TREE_DATA_GROUPING_FIELD] },
              pagination: {
                page: 0,
              },
              detailPanel: {
                expandedRowIds: [1],
              },
            }}
            columns={columns}
            rows={isErrorCubeData ? [] : initialData}
            treeData={!isErrorCubeData}
            getRowId={(row) => row?.rowid || 0}
            getTreeDataPath={(row) => row.path}
            groupingColDef={!isErrorCubeData && groupingColDef}
            disableSelectionOnClick
            checkboxSelection={false}
            rowsPerPageOptions={[5, 10, 20]}
            isCellEditable={() => false}
            getCellClassName={checkGetCellClassName}
            isGroupExpandedByDefault={(params) => {
              return true;
            }}
            paginationMode='client'
            editMode='cell'
            sortingMode='client'
            columnBuffer={2}
            columnThreshold={2}
            sx={{ minHeight: 390, height: '100%', width: '100%' }}
            filterMode='client'
            autoHeight={false}
            hideFooter
            initialScrollColumnIndex={initialScrollColumnIndex}
            components={{
              Toolbar: null,
            }}
          />
        )}
      </AnimatedPage>
      <ModalCubeEventDataDetails
        open={modalDataDetails.open}
        onClose={() => {
          setModalDataDetails(initialStateModalDetails);
        }}
        cubeId={cubeId}
        cubeMemberName={selectedOptionValue?.label}
        title={`${selectedOptionValue?.label} - Data Details`}
        subtitle={`${modalDataDetails.eventSubjectName} • ${modalDataDetails.timePeriod}`}
        timePeriod={modalDataDetails.timePeriod || undefined}
        cubeMember={modalDataDetails.cubeMember || undefined}
        subjectId={modalDataDetails.eventSubjectId || undefined}
      />
    </TenantHomeDashboardNavigationBar>
  );
}
