import { ROLES } from 'constant/PermissonConstant';
import { LoginPage } from 'pages/Auth/LoginPage';
import { SelectRolePage } from 'pages/Auth/SelectRolePage';
import { InitialSetupPage } from 'pages/Auth/InitialSetupPage';
import KTreeListPage from 'pages/SystemTenant/AROKMS/KTree/KTreeList';

// System Tenant
import SubjectPage from 'pages/SystemTenant/AROKMS/Subject';
import AttributePage from 'pages/SystemTenant/AROKMS/Attribute';
import TableDefinitionListPage, { TABLE_DEFINITION_TYPE } from 'pages/SystemTenant/AROKMS/TableDefinition';
import CubeLayerListPage from 'pages/SystemTenant/AROCube/CubeLayerPage';
import CubeLayerSetListPage from 'pages/SystemTenant/AROCube/CubeLayerSetPage';
import CubeDefinitionListPage from 'pages/SystemTenant/AROCube/CubeDefinition/CubeDefinitionListPage';
import CreateCubeDefinitionPage from 'pages/SystemTenant/AROCube/CubeDefinition/CreateCubeDefinitionPage';
import EditCubeDefinitionPage from 'pages/SystemTenant/AROCube/CubeDefinition/EditCubeDefinitionPage';
import TimePeriodSetListPage from 'pages/SystemTenant/AROCube/CubeTimePeriodSetPage';
import FunctionDefinitionPage from 'pages/SystemTenant/AROFunction/FunctionDefinition';
import CreateFunctionDefinitionPage from 'pages/SystemTenant/AROFunction/FunctionDefinition/CreateFunctionDefinition';
import EditFunctionDefinitionPage from 'pages/SystemTenant/AROFunction/FunctionDefinition/EditFunctionDefinition';
import StandardViewDefinitionPage from 'pages/SystemTenant/AROView/StandardViewDefinition';
import CreateStandardViewDefinitionPage from 'pages/SystemTenant/AROView/StandardViewDefinition/CreateStandardViewDefinition';
import EditStandardViewDefinitionPage from 'pages/SystemTenant/AROView/StandardViewDefinition/EditStandardViewDefinition';
import InsightViewDefinitionPage from 'pages/SystemTenant/AROView/InsightViewDefinition';
import CreateInsightViewDefinitionPage from 'pages/SystemTenant/AROView/InsightViewDefinition/CreateInsightViewDefinition';
import EditInsightViewDefinitionPage from 'pages/SystemTenant/AROView/InsightViewDefinition/EditInsightViewDefinition';
import SubjectProsessPage from 'pages/SystemTenant/AROKMS/SubjectProcess';
import SubjectProcedurePage from 'pages/SystemTenant/AROKMS/SubjectProcedure';
import SolutionDefinitionPage from 'pages/SystemTenant/AROKMS/SolutionDefinition';
// Tenant
import DataInputPage from 'pages/Tenant/AROKMS/DataInput';
import MemberListPage from 'pages/Tenant/AROKMS/MemberList';
import DataInputDedicatedInsert from 'pages/Tenant/AROKMS/DataInput/insert';
import DataInputDedicatedUpdate from 'pages/Tenant/AROKMS/DataInput/edit';
import TenantConfigurationPage from 'pages/Tenant/ConfigurationPage';
import EventTablePage from 'pages/Tenant/AROEvent/Event';
import EventInputDedicatedInsert from 'pages/Tenant/AROEvent/Event/insert';
import EventInputDedicatedEdit from 'pages/Tenant/AROEvent/Event/edit';
import DataMigrationPage from 'pages/Tenant/MigrationPage';
import CubePage from 'pages/Tenant/AroCube/CubeDataEntryPage';
import CubeInsightViewPage from 'pages/Tenant/AroCube/CubeInsightViewPage';
import CubeDimensionalViewPage from 'pages/Tenant/AroCube/CubeDimensionalViewPageManager';
import TenantHomePage from 'pages/Tenant/CustomerDashboardPage';
import ViewKtreeDataPage from 'pages/Tenant/AROKMS/KTree/ViewKtreeData';
import DisplayStandardViewPage from 'pages/Tenant/AROView/StandardView';
// Tenant Master
import AdminTenantHomePage from 'pages/TenantMaster/AdminDashboard';
import SystemTableDataPage from 'pages/TenantMaster/MasterTable/SystemTable/SystemTableDataPage';
import AddSystemPage from 'pages/TenantMaster/MasterTable/SystemTable/AddSystemPage';
import EditSystemPage from 'pages/TenantMaster/MasterTable/SystemTable/EditSystemPage';
import ServerTableDataPage from 'pages/TenantMaster/MasterTable/ServerTableDataPage';
import AddServerTableDataPage from 'pages/TenantMaster/MasterTable/ServerTable/AddServerTableData';
import EditServerTableDataPage from 'pages/TenantMaster/MasterTable/ServerTable/EditServerTableData';
import TenantTableDataPage from 'pages/TenantMaster/MasterTable/TenantTable/TenantTableDataPage';
import AddTenantTablePage from 'pages/TenantMaster/MasterTable/TenantTable/AddTenantPage';
import EditTenantPage from 'pages/TenantMaster/MasterTable/TenantTable/EditTenantPage';
import PersonTableDataPage from 'pages/TenantMaster/MasterTable/PersonTableDataPage';
import AddPersonTablePage from 'pages/TenantMaster/MasterTable/PersonTable/AddPersonTableData';
import EditPersonTableData from 'pages/TenantMaster/MasterTable/PersonTable/EditPersonTableData';
import DisplayTableDataPage from 'pages/TenantMaster/MasterTable/DisplayTableDataPage';
import EditDisplayTablePage from 'pages/TenantMaster/MasterTable/DisplayTable/EditDisplayTable';
import AddDatawarehousePage from 'pages/TenantMaster/MasterTable/TenantExternalDataSourceTable/AddDataWarehouse';
import EditDatawarehousePage from 'pages/TenantMaster/MasterTable/TenantExternalDataSourceTable/EditDataWarehouse';
import AddTenantDataChannelPage from 'pages/TenantMaster/MasterTable/TenantDataChannel/AddDataChannel';
import DataWareHousePage from 'pages/TenantMaster/MasterTable/Datawarehouse';
import DashboardPage from 'pages/Tenant/AroDashboard';
import { PATH_CONSTANT } from 'constant/PathConstant';

export const APP_ROUTES = [
  {
    id: 'auth-login',
    path: PATH_CONSTANT.AUTH.LOGIN,
    private: false,
    exact: true,
    allowedRoles: [],
    component: <LoginPage />,
  },
  {
    id: 'auth-select-role',
    path: PATH_CONSTANT.AUTH.SELECT_ROLE,
    private: true,
    exact: true,
    allowedRoles: [
      ROLES.ADMIN,
      ROLES.BUILDER,
      ROLES.MANAGER,
      ROLES.TESTER,
      ROLES.PACKAGER,
      ROLES.SUPPORT,
      ROLES.TRAINER,
    ],
    component: <SelectRolePage />,
  },
  {
    id: 'auth-initial-setup',
    path: PATH_CONSTANT.AUTH.INITIAL_SETUP,
    private: true,
    exact: true,
    allowedRoles: [
      ROLES.ADMIN,
      ROLES.BUILDER,
      ROLES.MANAGER,
      ROLES.TESTER,
      ROLES.PACKAGER,
      ROLES.SUPPORT,
      ROLES.TRAINER,
    ],
    component: <InitialSetupPage />,
  },
  {
    id: 'subject-list',
    path: PATH_CONSTANT.SYSTEM.KMS.DATA_BUILDER.SUBJECT,
    exact: true,
    private: true,
    allowedRoles: [ROLES.ADMIN, ROLES.BUILDER],
    component: <SubjectPage />,
  },
  {
    id: 'subject-process',
    path: PATH_CONSTANT.SYSTEM.KMS.SUBJECT_PROCESS,
    exact: true,
    private: true,
    allowedRoles: [ROLES.ADMIN, ROLES.BUILDER],
    component: <SubjectProsessPage />,
  },
  {
    id: 'subject-procedure-page',
    path: PATH_CONSTANT.SYSTEM.KMS.SUBJECT_PROCEDURE,
    exact: true,
    private: true,
    allowedRoles: [ROLES.ADMIN, ROLES.BUILDER],
    component: <SubjectProcedurePage />,
  },
  {
    id: 'solution-definition-page',
    path: PATH_CONSTANT.SYSTEM.KMS.SOLUTION_DEFINITION,
    exact: true,
    private: true,
    allowedRoles: [ROLES.ADMIN, ROLES.BUILDER],
    component: <SolutionDefinitionPage />,
  },
  {
    id: 'home',
    path: '/',
    exact: true,
    private: true,
    allowedRoles: [ROLES.MANAGER, ROLES.ADMIN, ROLES.BUILDER, ROLES.TESTER, ROLES.TRAINER],
    component: <SubjectPage />,
  },
  {
    id: 'attribute-list',
    path: PATH_CONSTANT.SYSTEM.KMS.DATA_BUILDER.ATTRIBUTE,
    exact: true,
    private: true,
    allowedRoles: [ROLES.ADMIN, ROLES.BUILDER],
    component: <AttributePage />,
  },
  {
    id: 'member-list',
    path: PATH_CONSTANT.TENANT.KMS.MEMBER_LIST,
    exact: true,
    private: true,
    allowedRoles: [ROLES.ADMIN, ROLES.MANAGER, ROLES.TESTER, ROLES.PACKAGER, ROLES.SUPPORT, ROLES.TRAINER],
    component: <MemberListPage />,
  },
  {
    id: 'table-definition',
    path: PATH_CONSTANT.SYSTEM.KMS.DATA_BUILDER.TABLE_DEFINITION,
    exact: true,
    private: true,
    allowedRoles: [ROLES.ADMIN, ROLES.BUILDER],
    component: <TableDefinitionListPage type={TABLE_DEFINITION_TYPE.TABLE_DEFINITION} />,
  },
  {
    id: 'event-definition',
    path: PATH_CONSTANT.SYSTEM.KMS.DATA_EVENT.EVENT_DEFINTION,
    exact: true,
    private: true,
    allowedRoles: [ROLES.ADMIN, ROLES.BUILDER],
    component: <TableDefinitionListPage type={TABLE_DEFINITION_TYPE.EVENT_DEFINITION} />,
  },
  {
    id: 'function-definition',
    path: PATH_CONSTANT.SYSTEM.FUNCTION.FUNCTION_DEFINITION,
    exact: true,
    private: true,
    allowedRoles: [ROLES.ADMIN, ROLES.BUILDER],
    component: <FunctionDefinitionPage />,
  },
  {
    id: 'create-function-definition',
    path: PATH_CONSTANT.SYSTEM.FUNCTION.CREATE_FUNCTION_DEFINITION,
    exact: true,
    private: true,
    allowedRoles: [ROLES.ADMIN, ROLES.BUILDER],
    component: <CreateFunctionDefinitionPage />,
  },
  {
    id: 'edit-function-definition',
    path: PATH_CONSTANT.SYSTEM.FUNCTION.EDIT_FUNCTION_DEFINITION,
    exact: true,
    private: true,
    allowedRoles: [ROLES.ADMIN, ROLES.BUILDER],
    component: <EditFunctionDefinitionPage />,
  },
  {
    id: 'standard-view-definition',
    path: PATH_CONSTANT.SYSTEM.VIEW.STANDARD_VIEW_DEFINITION,
    exact: true,
    private: true,
    allowedRoles: [ROLES.ADMIN, ROLES.BUILDER],
    component: <StandardViewDefinitionPage />,
  },
  {
    id: 'create-standard-view-definition',
    path: PATH_CONSTANT.SYSTEM.VIEW.CREATE_STANDARD_VIEW_DEFINITION,
    exact: true,
    private: true,
    allowedRoles: [ROLES.ADMIN, ROLES.BUILDER],
    component: <CreateStandardViewDefinitionPage />,
  },
  {
    id: 'edit-standard-view-definition',
    path: PATH_CONSTANT.SYSTEM.VIEW.EDIT_STANDARD_VIEW_DEFINITION,
    exact: true,
    private: true,
    allowedRoles: [ROLES.ADMIN, ROLES.BUILDER],
    component: <EditStandardViewDefinitionPage />,
  },
  {
    id: 'insight-view-definition',
    path: PATH_CONSTANT.SYSTEM.VIEW.INSIGHT_VIEW_DEFINITION,
    exact: true,
    private: true,
    allowedRoles: [ROLES.ADMIN, ROLES.BUILDER],
    component: <InsightViewDefinitionPage />,
  },
  {
    id: 'create-insight-view-definition',
    path: PATH_CONSTANT.SYSTEM.VIEW.CREATE_INSIGHT_VIEW_DEFINITION,
    exact: true,
    private: true,
    allowedRoles: [ROLES.ADMIN, ROLES.BUILDER],
    component: <CreateInsightViewDefinitionPage />,
  },
  {
    id: 'edit-insight-view-definition',
    path: PATH_CONSTANT.SYSTEM.VIEW.EDIT_INSIGHT_VIEW_DEFINITION,
    exact: true,
    private: true,
    allowedRoles: [ROLES.ADMIN, ROLES.BUILDER],
    component: <EditInsightViewDefinitionPage />,
  },
  {
    id: 'master-table-system',
    path: PATH_CONSTANT.TENANT_MASTER.MASTER_TABLE.SYSTEM,
    exact: true,
    private: true,
    allowedRoles: [ROLES.ADMIN],
    component: <SystemTableDataPage />,
  },
  {
    id: 'add-master-table-system',
    path: PATH_CONSTANT.TENANT_MASTER.MASTER_TABLE.ADD_SYSTEM,
    exact: true,
    private: true,
    allowedRoles: [ROLES.ADMIN],
    component: <AddSystemPage />,
  },
  {
    id: 'edit-master-table-system',
    path: PATH_CONSTANT.TENANT_MASTER.MASTER_TABLE.EDIT_SYSTEM,
    exact: true,
    private: true,
    allowedRoles: [ROLES.ADMIN],
    component: <EditSystemPage />,
  },
  {
    id: 'master-table-server',
    path: PATH_CONSTANT.TENANT_MASTER.MASTER_TABLE.SERVER,
    exact: true,
    private: true,
    allowedRoles: [ROLES.ADMIN],
    component: <ServerTableDataPage />,
  },
  {
    id: 'add-master-table-server',
    path: PATH_CONSTANT.TENANT_MASTER.MASTER_TABLE.ADD_SERVER,
    exact: true,
    private: true,
    allowedRoles: [ROLES.ADMIN],
    component: <AddServerTableDataPage />,
  },
  {
    id: 'edit-master-table-server',
    path: PATH_CONSTANT.TENANT_MASTER.MASTER_TABLE.EDIT_SERVER,
    exact: true,
    private: true,
    allowedRoles: [ROLES.ADMIN],
    component: <EditServerTableDataPage />,
  },
  {
    id: 'master-table-tenant',
    path: PATH_CONSTANT.TENANT_MASTER.MASTER_TABLE.TENANT,
    exact: true,
    private: true,
    allowedRoles: [ROLES.ADMIN],
    component: <TenantTableDataPage />,
  },
  {
    id: 'add-master-table-tenant',
    path: PATH_CONSTANT.TENANT_MASTER.MASTER_TABLE.ADD_TENANT,
    exact: true,
    private: true,
    allowedRoles: [ROLES.ADMIN],
    component: <AddTenantTablePage />,
  },
  {
    id: 'edit-master-table-tenant',
    path: PATH_CONSTANT.TENANT_MASTER.MASTER_TABLE.EDIT_TENANT,
    exact: true,
    private: true,
    allowedRoles: [ROLES.ADMIN],
    component: <EditTenantPage />,
  },
  {
    id: 'master-table-person',
    path: PATH_CONSTANT.TENANT_MASTER.MASTER_TABLE.PERSON,
    exact: true,
    private: true,
    allowedRoles: [ROLES.ADMIN],
    component: <PersonTableDataPage />,
  },
  {
    id: 'add-master-table-person',
    path: PATH_CONSTANT.TENANT_MASTER.MASTER_TABLE.ADD_PERSON,
    exact: true,
    private: true,
    allowedRoles: [ROLES.ADMIN],
    component: <AddPersonTablePage />,
  },
  {
    id: 'edit-master-table-person',
    path: PATH_CONSTANT.TENANT_MASTER.MASTER_TABLE.EDIT_PERSON,
    exact: true,
    private: true,
    allowedRoles: [ROLES.ADMIN],
    component: <EditPersonTableData />,
  },
  {
    id: 'display-table',
    path: PATH_CONSTANT.TENANT_MASTER.DISPLAY_TABLE,
    exact: true,
    private: true,
    allowedRoles: [ROLES.ADMIN],
    accressAdminRightLevel: 5,
    component: <DisplayTableDataPage />,
  },
  {
    id: 'edit-display-table',
    path: PATH_CONSTANT.TENANT_MASTER.EDIT_DISPLAY_TABLE,
    exact: true,
    private: true,
    allowedRoles: [ROLES.ADMIN],
    accressAdminRightLevel: 5,
    component: <EditDisplayTablePage />,
  },
  {
    id: 'data-input',
    path: PATH_CONSTANT.TENANT.KMS.DATA_INPUT,
    exact: true,
    private: true,
    allowedRoles: [
      ROLES.MANAGER,
      ROLES.ADMIN,
      ROLES.BUILDER,
      ROLES.TESTER,
      ROLES.PACKAGER,
      ROLES.SUPPORT,
      ROLES.TRAINER,
    ],
    component: <DataInputPage />,
  },
  {
    id: 'data-cube',
    path: PATH_CONSTANT.TENANT.CUBE.CUBE,
    exact: true,
    private: true,
    allowedRoles: [
      ROLES.MANAGER,
      ROLES.ADMIN,
      ROLES.BUILDER,
      ROLES.TESTER,
      ROLES.PACKAGER,
      ROLES.SUPPORT,
      ROLES.TRAINER,
    ],
    component: <CubePage />,
  },
  {
    id: 'data-cube-insight-view',
    path: PATH_CONSTANT.TENANT.CUBE.INSIGHT,
    exact: true,
    private: true,
    allowedRoles: [
      ROLES.MANAGER,
      ROLES.ADMIN,
      ROLES.BUILDER,
      ROLES.TESTER,
      ROLES.PACKAGER,
      ROLES.SUPPORT,
      ROLES.TRAINER,
    ],
    component: <CubeInsightViewPage />,
  },
  {
    id: 'data-cube-dimensional-view',
    path: PATH_CONSTANT.TENANT.CUBE.CUBE_DIMENSIONAL_VIEW,
    exact: true,
    private: true,
    allowedRoles: [
      ROLES.MANAGER,
      ROLES.ADMIN,
      ROLES.BUILDER,
      ROLES.TESTER,
      ROLES.PACKAGER,
      ROLES.SUPPORT,
      ROLES.TRAINER,
    ],
    component: <CubeDimensionalViewPage />,
  },
  {
    id: 'standard-view',
    path: PATH_CONSTANT.TENANT.VIEW.STANDARD_VIEW,
    exact: true,
    private: true,
    allowedRoles: [
      ROLES.MANAGER,
      ROLES.ADMIN,
      ROLES.BUILDER,
      ROLES.TESTER,
      ROLES.PACKAGER,
      ROLES.SUPPORT,
      ROLES.TRAINER,
    ],
    component: <DisplayStandardViewPage />,
  },
  {
    id: 'data-input-dedicated-insert',
    path: PATH_CONSTANT.TENANT.KMS.DATA_INPUT_DEDICATED_PAGE_INSERT,
    exact: true,
    private: true,
    allowedRoles: [ROLES.ADMIN, ROLES.BUILDER, ROLES.TESTER, ROLES.PACKAGER, ROLES.SUPPORT],
    component: <DataInputDedicatedInsert />,
  },
  {
    id: 'data-input-dedicated-edit',
    path: PATH_CONSTANT.TENANT.KMS.DATA_INPUT_DEDICATED_PAGE_EDIT,
    exact: true,
    private: true,
    allowedRoles: [ROLES.ADMIN, ROLES.BUILDER, ROLES.TESTER, ROLES.PACKAGER, ROLES.SUPPORT],
    component: <DataInputDedicatedUpdate />,
  },
  {
    id: 'ktree-definition',
    path: PATH_CONSTANT.SYSTEM.KMS.KTREE_DEFINITION,
    exact: true,
    private: true,
    allowedRoles: [ROLES.MANAGER, ROLES.TESTER, ROLES.PACKAGER, ROLES.SUPPORT, ROLES.BUILDER, ROLES.TRAINER],
    component: <KTreeListPage />,
  },
  {
    id: 'cube-definition-list',
    path: PATH_CONSTANT.SYSTEM.CUBE.CUBE_DEFINITION,
    exact: true,
    private: true,
    allowedRoles: [ROLES.MANAGER, ROLES.TESTER, ROLES.PACKAGER, ROLES.SUPPORT, ROLES.BUILDER, ROLES.TRAINER],
    component: <CubeDefinitionListPage />,
  },
  {
    id: 'create-cube-definition',
    path: PATH_CONSTANT.SYSTEM.CUBE.CUBE_DEFINITION_CREATE,
    exact: true,
    private: true,
    allowedRoles: [ROLES.MANAGER, ROLES.TESTER, ROLES.PACKAGER, ROLES.SUPPORT, ROLES.BUILDER, ROLES.TRAINER],
    component: <CreateCubeDefinitionPage />,
  },
  {
    id: 'edit-cube-definition',
    path: PATH_CONSTANT.SYSTEM.CUBE.CUBE_DEFINITION_EDIT,
    exact: true,
    private: true,
    allowedRoles: [ROLES.MANAGER, ROLES.TESTER, ROLES.PACKAGER, ROLES.SUPPORT, ROLES.BUILDER, ROLES.TRAINER],
    component: <EditCubeDefinitionPage />,
  },
  {
    id: 'cube-layer',
    path: PATH_CONSTANT.SYSTEM.CUBE.CUBE_LAYER,
    exact: true,
    private: true,
    allowedRoles: [ROLES.MANAGER, ROLES.TESTER, ROLES.PACKAGER, ROLES.SUPPORT, ROLES.BUILDER, ROLES.TRAINER],
    component: <CubeLayerListPage />,
  },
  {
    id: 'cube-layer-set',
    path: PATH_CONSTANT.SYSTEM.CUBE.CUBE_LAYER_SET,
    exact: true,
    private: true,
    allowedRoles: [ROLES.MANAGER, ROLES.TESTER, ROLES.PACKAGER, ROLES.SUPPORT, ROLES.BUILDER, ROLES.TRAINER],
    component: <CubeLayerSetListPage />,
  },
  {
    id: 'time-period-set',
    path: PATH_CONSTANT.SYSTEM.CUBE.TIME_PERIOD_SET,
    exact: true,
    private: true,
    allowedRoles: [ROLES.MANAGER, ROLES.TESTER, ROLES.PACKAGER, ROLES.SUPPORT, ROLES.BUILDER, ROLES.TRAINER],
    component: <TimePeriodSetListPage />,
  },

  {
    id: 'tenant-home-page',
    path: PATH_CONSTANT.TENANT.HOME,
    exact: true,
    private: true,
    allowedRoles: [ROLES.MANAGER, ROLES.BUILDER, ROLES.TESTER, ROLES.PACKAGER, ROLES.SUPPORT, ROLES.TRAINER],
    component: <TenantHomePage />,
  },
  {
    id: 'admin-tenant-home-page',
    path: PATH_CONSTANT.TENANT_MASTER.HOME,
    exact: true,
    private: true,
    allowedRoles: [ROLES.ADMIN],
    component: <AdminTenantHomePage />,
  },
  {
    id: 'tenant-home-page-data-input',
    path: PATH_CONSTANT.TENANT.KMS.KTREE_DATA,
    exact: true,
    private: true,
    allowedRoles: [
      ROLES.MANAGER,
      ROLES.ADMIN,
      ROLES.BUILDER,
      ROLES.TESTER,
      ROLES.PACKAGER,
      ROLES.SUPPORT,
      ROLES.TRAINER,
    ],
    component: <ViewKtreeDataPage />,
  },

  {
    id: 'tenant-data-migration-page',
    path: PATH_CONSTANT.TENANT.CONFIGURATION.DATA_MIGRATION,
    exact: true,
    private: true,
    allowedRoles: [ROLES.ADMIN, ROLES.TESTER, ROLES.PACKAGER, ROLES.SUPPORT, ROLES.BUILDER],
    component: <DataMigrationPage />,
  },

  {
    id: 'tenant-configuration-page',
    path: PATH_CONSTANT.TENANT.CONFIGURATION.CONFIGURATION,
    exact: true,
    private: true,
    allowedRoles: [ROLES.ADMIN, ROLES.TESTER, ROLES.PACKAGER, ROLES.SUPPORT, ROLES.BUILDER],
    component: <TenantConfigurationPage />,
  },
  {
    id: 'tenant-event-list-page',
    path: PATH_CONSTANT.TENANT.EVENT.EVENT,
    exact: true,
    private: true,
    allowedRoles: [ROLES.ADMIN, ROLES.TESTER, ROLES.PACKAGER, ROLES.SUPPORT, ROLES.TRAINER, ROLES.MANAGER],
    component: <EventTablePage />,
  },
  {
    id: 'add-event-page',
    path: PATH_CONSTANT.TENANT.EVENT.ADD_EVENT,
    exact: true,
    private: true,
    allowedRoles: [ROLES.ADMIN, ROLES.TESTER, ROLES.PACKAGER, ROLES.SUPPORT],
    component: <EventInputDedicatedInsert />,
  },
  {
    id: 'edit-event-page',
    path: PATH_CONSTANT.TENANT.EVENT.EDIT_EVENT,
    exact: true,
    private: true,
    allowedRoles: [ROLES.ADMIN, ROLES.TESTER, ROLES.PACKAGER, ROLES.SUPPORT],
    component: <EventInputDedicatedEdit />,
  },
  {
    id: 'add-datawarehouse',
    path: PATH_CONSTANT.TENANT_MASTER.DATAWAREHOUSE.ADD,
    exact: true,
    private: true,
    allowedRoles: [ROLES.ADMIN],
    component: <AddDatawarehousePage />,
  },
  {
    id: 'edit-datawarehouse',
    path: PATH_CONSTANT.TENANT_MASTER.DATAWAREHOUSE.EDIT,
    exact: true,
    private: true,
    allowedRoles: [ROLES.ADMIN],
    component: <EditDatawarehousePage />,
  },
  {
    id: 'add-tenant-data-channel',
    path: PATH_CONSTANT.TENANT_MASTER.DATAWAREHOUSE.CHANNEL,
    exact: true,
    private: true,
    allowedRoles: [ROLES.ADMIN],
    component: <AddTenantDataChannelPage />,
  },
  {
    id: 'list-tenant-datasource',
    path: PATH_CONSTANT.TENANT_MASTER.DATAWAREHOUSE.LIST,
    exact: true,
    private: true,
    allowedRoles: [ROLES.ADMIN],
    component: <DataWareHousePage />,
  },
  {
    id: 'tenant-dashboard',
    path: PATH_CONSTANT.TENANT.DASHBOARD.DASHBOARD,
    exact: true,
    private: true,
    allowedRoles: [ROLES.MANAGER, ROLES.BUILDER, ROLES.TESTER, ROLES.PACKAGER, ROLES.SUPPORT, ROLES.TRAINER],
    component: <DashboardPage />,
  },
];
