import Stack from '@mui/material/Stack';
import { useEffect, useMemo, useRef } from 'react';
import IframeResizer from 'iframe-resizer-react';
import { DashboardPanel } from './DashboardPanel';
import { InsightViewDimensionalOptionValue } from 'types/api/Tenant/AROCube/CubeInisghtViewTypes';
import { Box } from '@mui/material';
import { TenantHomeDashboardNavigationBar } from 'components/LayoutComponent/VerticalLayout/HeaderBar';
import { useParams, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setHomeNavigationState } from 'store/reducer/homeReducer';
import { generateBreadCrumbItems } from 'utils/NavigationUtil';

import { BreadcrumbItem } from 'types/api/Tenant/HomeDashboardTypes';
import { AnimatedPage } from 'components/AnimatedPage';
import { NAVIGATION_COMPONENT_TYPE } from 'constant/NavigationConstant';
import { DashboardMockData } from 'types/api/Tenant/ARODashboard/DashboardDataTypes';
import { usePrefetchDashboardData } from 'services/v1/Tenant/ARODashboard/DashboardPrefetchDataService';
import { getPageAnimationByRefNavigation } from 'utils/Animation';

export const getDashboardListData = (): DashboardMockData[] => {
  return [
    {
      name: 'Recent Weeks Summary',
      insightId: '65279b3f-725b-49ff-bdd2-1798ffecaae2',
      cubeId: 'BFB575C203F54DA996EE1266526961B5',
      insightName: 'Recent Weeks',
      cubeName: 'Truck Performance',
      charts: [
        {
          title: 'Revenue',
          value: 0.6,
        },
        {
          title: 'Revenue per KM',
          value: 0.8,
        },
        {
          title: 'KM',
          value: 0.4,
        },
        {
          title: 'Direct Costs',
          value: 0.7,
        },
        {
          title: 'Gross Margin',
          value: 0.8,
        },
        {
          title: 'Gross Margin % ROC',
          value: 0.86,
        },
      ],
      breadcrums: [
        {
          id: '794a613f-e026-4d36-8e0b-98415f474226',
          name: 'Truck',
          componentType: NAVIGATION_COMPONENT_TYPE.SUBJECT,
        },
        {
          id: 'BFB575C203F54DA996EE1266526961B5',
          name: 'Truck Performance',
          componentType: NAVIGATION_COMPONENT_TYPE.CUBE,
        },
        {
          id: '65279b3f-725b-49ff-bdd2-1798ffecaae2',
          name: 'Recent Weeks Summary',
          componentType: NAVIGATION_COMPONENT_TYPE.DASHBOARD,
        },
      ],
    },
    {
      name: 'Rolling Quarter & YTD Summary',
      insightId: '625f1abb-a53c-45d0-bbbd-63da85a3e3ec',
      cubeId: 'BFB575C203F54DA996EE1266526961B5',
      insightName: 'Rolling Quarter & YTD',
      cubeName: 'Truck Performance',
      charts: [
        {
          title: 'Revenue',
          value: 0.6,
        },
        {
          title: 'Revenue per KM',
          value: 0.8,
        },
      ],
      breadcrums: [
        {
          id: '794a613f-e026-4d36-8e0b-98415f474226',
          name: 'Truck',
          componentType: NAVIGATION_COMPONENT_TYPE.SUBJECT,
        },
        {
          id: 'BFB575C203F54DA996EE1266526961B5',
          name: 'Truck Performance',
          componentType: NAVIGATION_COMPONENT_TYPE.CUBE,
        },
        {
          id: '65279b3f-725b-49ff-bdd2-1798ffecaae2',
          name: 'Rolling Quarter & YTD Summary',
          componentType: NAVIGATION_COMPONENT_TYPE.DASHBOARD,
        },
      ],
    },
    {
      name: 'Weekly Trend Summary',
      insightId: 'c33fcbfa-2253-4d04-8883-7d119a40983e',
      insightName: 'Weekly Trend',
      cubeName: 'Truck Performance',
      cubeId: 'BFB575C203F54DA996EE1266526961B5',
      charts: [
        {
          title: 'Revenue',
          value: 0.6,
        },
        {
          title: 'Revenue per KM',
          value: 0.8,
        },
        {
          title: 'KM',
          value: 0.4,
        },
      ],
      breadcrums: [
        {
          id: '794a613f-e026-4d36-8e0b-98415f474226',
          name: 'Truck',
          componentType: NAVIGATION_COMPONENT_TYPE.SUBJECT,
        },
        {
          id: 'BFB575C203F54DA996EE1266526961B5',
          name: 'Truck Performance',
          componentType: NAVIGATION_COMPONENT_TYPE.CUBE,
        },
        {
          id: '65279b3f-725b-49ff-bdd2-1798ffecaae2',
          name: 'Weekly Trend Summary',
          componentType: NAVIGATION_COMPONENT_TYPE.DASHBOARD,
        },
      ],
    },
    {
      insightId: '7798441e-31d8-4fe6-9a9b-f0e26b7a96a0',
      insightName: 'Recent Monthly Actual',
      cubeId: '0E8DDF3700FE4565BE569BC76B9E2F64',
      name: 'Recent Month Summary',
      cubeName: 'Rolling Month',
      charts: [
        {
          title: 'Revenue',
          value: 0.6,
        },
        {
          title: 'Revenue per KM',
          value: 0.8,
        },
        {
          title: 'KM',
          value: 0.4,
        },
      ],
      breadcrums: [
        {
          id: '794a613f-e026-4d36-8e0b-98415f474226',
          name: 'Truck',
          componentType: NAVIGATION_COMPONENT_TYPE.SUBJECT,
        },
        {
          id: 'BFB575C203F54DA996EE1266526961B5',
          name: 'Rolling Month',
          componentType: NAVIGATION_COMPONENT_TYPE.CUBE,
        },
        {
          id: '65279b3f-725b-49ff-bdd2-1798ffecaae2',
          name: 'Recent Month Summary',
          componentType: NAVIGATION_COMPONENT_TYPE.DASHBOARD,
        },
      ],
    },
  ];
};

export const getDashboardMockData = (id?: string) => {
  if (!id) return null;
  const data = getDashboardListData();
  switch (id) {
    case 'Recent Weeks Summary':
      return data[0];
    case 'Rolling Quarter & YTD Summary':
      return data[1];
    case 'Weekly Trend Summary':
      return data[2];
    case 'Recent Month Summary':
      return data[3];

    default:
      return null;
  }
};

export default function DashboardViewPage() {
  const { dashboardId } = useParams<{ dashboardId: string }>();
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const [searchParams] = useSearchParams();
  const navigationRef = searchParams.get('ref');
  const memberId = searchParams.get('memberId');

  const mockData = getDashboardMockData(dashboardId);

  // Prefetch Insight and Cube Data
  usePrefetchDashboardData(mockData, memberId);

  // end of prefetch

  const dispatch = useDispatch();
  const cubeSettingPanelRef = useRef<{
    isLoadingOptionsData: boolean;
  }>(null);

  const initialValuesSelection = useMemo(() => {
    const initialValues = {
      xDimensionType: {
        label: '17-03-2024',
        value: 'MEASURE_ATTRIBUTE',
        type: 'MEASUREMENT_MEMBER',
      },
      yDimensionType: {
        label: 'Insight Column',
        value: 'INSIGHT_COLUMN_MEMBER',
        type: 'INSIGHT_COLUMNS_MEMBER',
      },
      member: null,
    };

    return initialValues;
  }, []);

  const handleOnOptionChange = (data: InsightViewDimensionalOptionValue) => {
    // if xDimesnionType or yDimensionType is changed, reset the column sort model
  };

  useEffect(() => {
    if (mockData?.breadcrums) {
      dispatch(setHomeNavigationState(generateBreadCrumbItems(mockData?.breadcrums as BreadcrumbItem[])));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mockData?.breadcrums]);
  const iframeUrl =
    'http://localhost:5001/embed/dashboard/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyZXNvdXJjZSI6eyJkYXNoYm9hcmQiOjF9LCJwYXJhbXMiOnt9LCJleHAiOjE3NDgwMDY3Mjl9.3ck3SqXzX8lAwL8LKVeBrVKJQhaFR1lgh9Frgd80W_4#bordered=false&titled=false';
  useEffect(() => {
    const iframe = iframeRef.current;
    const setHeightAuto = () => {
      if (!iframe) return;

      try {
        const document = iframe.contentWindow?.document;
        if (document) {
          const body = document.body;
          const html = document.documentElement;
          const height = Math.max(
            body.scrollHeight,
            body.offsetHeight,
            html.clientHeight,
            html.scrollHeight,
            html.offsetHeight
          );
          iframe.style.height = `${height}px`;
          console.log('iframe height:', height);
        }
      } catch (error) {
        console.error('Error adjusting iframe height:', error);
      }
    };

    if (iframe) {
      iframe.addEventListener('load', setHeightAuto);
    }
    return () => {
      if (iframe) {
        iframe.removeEventListener('load', setHeightAuto);
      }
    };
  }, [iframeUrl]); // Re-run when iframeUrl changes

  return (
    <TenantHomeDashboardNavigationBar>
      <AnimatedPage variants={getPageAnimationByRefNavigation(navigationRef)}>
        <Stack direction='column' px={2}>
          <Box flex={1} overflow='auto'>
            <DashboardPanel
              ref={cubeSettingPanelRef}
              disabled={false}
              initialValues={initialValuesSelection}
              loading={false}
              dashboardId={dashboardId}
              onOptionChange={handleOnOptionChange}
            />

            <Stack
              direction='row'
              gap={1}
              pb={2}
              justifyContent='flex-start'
              sx={{
                gap: 2,
                rowGap: 2,
                flexWrap: 'wrap',
              }}
            >
              <IframeResizer frameBorder={0} src={iframeUrl} style={{ width: '1px', minWidth: '100%' }} />
              {/* <iframe
                ref={iframeRef}
                src={iframeUrl}
                frameBorder='0'
                width='100%'
                style={{ height: 'auto' }}
                allowTransparency
              /> */}
            </Stack>
          </Box>
        </Stack>
      </AnimatedPage>
    </TenantHomeDashboardNavigationBar>
  );
}
