import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Modal, Checkbox } from '@mui/material';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import Divider from '@mui/material/Divider';
import ButtonLoading from '@mui/lab/LoadingButton';
import {
  GridActionsCellItem,
  GridColDef,
  GridEnrichedColDef,
  GridRenderCellParams,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowOrderChangeParams,
  GridSelectionModel,
} from '@mui/x-data-grid-pro';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import { renderSelectEditInputCellKtreeDefinition } from 'pages/SystemTenant/AROKMS/KTree/components/SelectEditInputCellKTreeDefinition';
import { CustomDataTable, SETTING_PANEL_MENU_OPTIONS } from 'components/DatatableComponent';
import { useTableDefinitionColOrderList } from 'services/v1/SystemTenant/AROKMS/TableDefinitionService';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { SubjectProcedureFieldRowItem } from 'types/api/SystemTenant/AROKMS/SubjectProcedureTypes';
import {
  useGetSubjectProcedureFields,
  useUpsertSubjectProcedureData,
} from 'services/v1/SystemTenant/AROKMS/SubjectProcedureService';
import ModalDeleteComponent, { ModalDeleteState } from 'components/ModalComponent/ModalDeleteComponent';
const ModalContentStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  minHeight: 600,
  borderRadius: 1,
  px: 3,
  py: 2,
};

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

type RenderCheckBoxProps = GridRenderCellParams<any, any> & {
  onCheckboxChange: (checked: boolean, id: any) => void;
};

function updateRowPosition(
  oldIndex: number,
  newIndex: number,
  rows: Array<GridRowModel<SubjectProcedureFieldRowItem>>
) {
  const rowsClone = [...rows];
  const [row] = rowsClone.splice(oldIndex, 1);
  rowsClone.splice(newIndex, 0, row);

  for (let i = 0; i < rowsClone.length; i++) {
    rowsClone[i].colIndexOrder = i + 1;
  }

  return rowsClone;
}

function RenderCheckBox(props: RenderCheckBoxProps) {
  const [checked, setChecked] = React.useState(props.value);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    props.onCheckboxChange(event.target.checked, props.id); // Notify parent component
  };

  return (
    <Checkbox
      checked={checked}
      onChange={handleChange}
      sx={{
        color: '#828C99',
        width: '100%',
      }}
    />
  );
}

export interface ModalSubjectProcedureFieldDefinitionProps {
  open: boolean;
  onClose: () => void;
  subjectId?: string;
  procedureId?: string;
}

export function ModalSubjectProcedureFieldDefinition(props: ModalSubjectProcedureFieldDefinitionProps) {
  const { onClose, open, subjectId, procedureId } = props;

  const [rows, setRows] = React.useState<SubjectProcedureFieldRowItem[]>([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [selectedTableDefinitionIds, setSelectedTableDefinitionIds] = React.useState(new Set<string>());
  const [selectedRowId, setSelectedRowId] = React.useState<GridRowId[]>([]);
  const [modalDeleteState, setModalDeleteState] = React.useState<ModalDeleteState>({
    open: false,
    message: '',
    key: 0,
  });
  const { mutate: upsertSubjectProcedure, isLoading: isSubmitting } = useUpsertSubjectProcedureData();
  const { data: tableDefColOrder } = useTableDefinitionColOrderList(subjectId);
  const { data: subjectProcedureFields } = useGetSubjectProcedureFields(procedureId);

  const handleOnClose = React.useCallback(() => {
    onClose();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckboxChange = React.useCallback(
    (checked: boolean, id: any, columnName: string) => {
      const newRows = rows.map((row) => {
        if (row.id === id) {
          return { ...row, [columnName]: checked };
        }
        return row;
      });

      setRows(newRows);
    },
    [rows]
  );

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleEditClick = (id: GridRowId) => () => {};

  const handleProcessRowUpdate = async (data: SubjectProcedureFieldRowItem) => {
    const index = rows.findIndex((item) => item.id === data.id);
    if (data.tableDefinition.value) {
      if (data.isNew) {
        const newRows = [...rows];
        newRows[index] = { ...data, isNew: true };
        setRows(newRows);
      } else {
        const index = rows.findIndex((item) => item.id === data.id);
        const newRows = [...rows];
        newRows[index] = data;
        setRows(newRows);
      }
      setRowModesModel({ ...rowModesModel, [data.id]: { mode: GridRowModes.View } });
      if (!selectedTableDefinitionIds.has(data.tableDefinition.value.toString())) {
        setSelectedTableDefinitionIds(new Set(selectedTableDefinitionIds.add(data.tableDefinition.value.toString())));
      }
      return data;
    }
  };

  const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
    const newRows = updateRowPosition(params.oldIndex, params.targetIndex, rows);
    setRows(newRows);
  };

  const columnOptions: AutoCompleteItem[] = React.useMemo(() => {
    if (!tableDefColOrder?.data) return [];
    return tableDefColOrder?.data
      ?.map((item) => ({
        value: item.id,
        label: item.attributeName,
      }))
      .filter((item) => !selectedTableDefinitionIds.has(item.value));
  }, [tableDefColOrder?.data, selectedTableDefinitionIds]);

  const isAnyEdit = React.useMemo(() => {
    return rowModesModel && Object.values(rowModesModel).some((item) => item.mode === GridRowModes.Edit);
  }, [rowModesModel]);

  const columns = React.useMemo<GridColDef[]>(() => {
    const actionColumn: GridEnrichedColDef = {
      field: 'actions',
      type: 'actions',
      headerName: 'Edit',
      width: 30,
      cellClassName: 'actions',

      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          // @ts-ignore
          return [<GridActionsCellItem icon={<SaveIcon />} label='Save' onClick={handleSaveClick(id)} />];
        }

        return [
          // @ts-ignore
          <GridActionsCellItem disabled={isAnyEdit} icon={<EditIcon />} label='Edit' onClick={handleEditClick(id)} />,
        ];
      },
    };
    return [
      actionColumn,

      {
        field: 'tableDefinition',
        headerName: 'Attribute',
        width: 250,
        editable: true,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
          return <span>{params?.value?.label}</span>;
        },
        renderEditCell: (params) =>
          renderSelectEditInputCellKtreeDefinition({
            ...params,
            // @ts-ignore
            optionsSource: columnOptions,
          }),
      },
      {
        field: 'isReadOnly',
        headerName: 'Read Only',
        width: 100,
        editable: false,
        filterable: false,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <RenderCheckBox
              {...params}
              onCheckboxChange={(checked, id) => handleCheckboxChange(checked, id, 'isReadOnly')}
            />
          );
        },
      },
      {
        field: 'isMandatory',
        headerName: 'Mandatory',
        width: 100,
        editable: false,
        filterable: false,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <RenderCheckBox
              {...params}
              onCheckboxChange={(checked, id) => handleCheckboxChange(checked, id, 'isMandatory')}
            />
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnOptions, rowModesModel, isAnyEdit, rows]);

  const handleSaveData = () => {
    if (procedureId) {
      upsertSubjectProcedure(
        {
          id: procedureId,
          procedureFieldList: rows.map((item) => ({
            tableDefinitionId: item.tableDefinition.value?.toString(),
            isMandatory: item.isMandatory,
            isReadOnly: item.isReadOnly,
            id: item.isNew ? undefined : item.id,
            colIndexOrder: item.colIndexOrder,
          })),
        },
        {
          onSuccess: () => {
            onClose();
          },
        }
      );
    }
  };

  const handleOnClickDeleteButton = () => {
    if (selectedRowId.length) {
      setModalDeleteState({
        open: true,
        message: `Are you sure you want to delete ${selectedRowId.length} Fields(s)?`,
      });
    }
  };

  const handleSelectionModelChange = (newSelection: GridSelectionModel) => {
    setSelectedRowId(newSelection);
  };

  const handleDeleteData = () => {
    if (selectedRowId.length) {
      setRows(rows.filter((item) => !selectedRowId.includes(item.id)));
      setModalDeleteState({ ...modalDeleteState, open: false });
    }
  };

  React.useEffect(() => {
    if (subjectProcedureFields?.data) {
      setRows(subjectProcedureFields?.data);
      setSelectedTableDefinitionIds(
        new Set(subjectProcedureFields?.data.map((item) => item.tableDefinition.value.toString()))
      );
    }
  }, [subjectProcedureFields?.data]);

  return (
    <Modal open={open} onClose={handleOnClose}>
      <>
        <Stack direction='column' sx={ModalContentStyle} gap={1}>
          <Stack direction='column'>
            <Stack justifyContent='space-between' direction='row' alignItems='center' spacing={2}>
              <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                Define Procedure Field
              </Typography>
              <IconButton onClick={handleOnClose} component='label'>
                <HighlightOffRoundedIcon sx={closeIconStyle} />
              </IconButton>
            </Stack>
            <Divider sx={{ mb: 0 }} />
          </Stack>
          <div style={{ position: 'relative', overflow: 'auto', height: '100%', width: '100%' }}>
            <CustomDataTable
              initialState={{
                pagination: {
                  page: 0,
                },
                sorting: {
                  sortModel: [],
                },
              }}
              rows={rows}
              columns={columns}
              getRowId={(row) => row.id}
              disableColumnReorder={isSubmitting}
              rowModesModel={rowModesModel}
              onSelectionModelChange={handleSelectionModelChange}
              disableSelectionOnClick
              getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
              experimentalFeatures={{ newEditingApi: true }}
              processRowUpdate={handleProcessRowUpdate}
              paginationMode='client'
              sortingMode='client'
              rowReordering
              onRowOrderChange={handleRowOrderChange}
              sx={{
                '& .MuiOutlinedInput-input': {
                  padding: '0px 14px',
                },
              }}
              components={{
                Pagination: null,
              }}
              componentsProps={{
                toolbar: {
                  setRows: setRows,
                  setRowModesModel,
                  menuOptions: [SETTING_PANEL_MENU_OPTIONS.DELETE],
                  onDeleteData: handleOnClickDeleteButton,
                  isEditing:
                    rowModesModel && Object.values(rowModesModel).some((item) => item.mode === GridRowModes.Edit),
                  disabled: false,
                  disabledDeleteButton: false,
                  config: {
                    withExport: false,
                    infoText: null,
                    fieldFocusOnInsert: 'tableDefinition',
                    insertButtonText: 'Add New Field',
                    insertInitialObject: {
                      isMandatory: true,
                      isReadOnly: false,
                      tableDefinition: null,
                      isNew: true,
                    },
                  },
                },
              }}
            />
          </div>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Stack />
            <Stack direction='row' justifyContent='flex-end' spacing={2} alignItems='center' sx={{ py: 1 }}>
              <ButtonLoading variant='main-table-panel' onClick={handleSaveData} loading={isSubmitting}>
                Save
              </ButtonLoading>
            </Stack>
          </Stack>
        </Stack>
        <ModalDeleteComponent
          visible={modalDeleteState.open}
          message={modalDeleteState.message}
          onApprove={handleDeleteData}
          onClose={() => {
            setModalDeleteState({ ...modalDeleteState, open: false });
          }}
          onCancel={() => setModalDeleteState({ ...modalDeleteState, open: false })}
        />
      </>
    </Modal>
  );
}
