import { useState, useRef, useMemo } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import ButtonLoading from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import { Formik, FormikProps } from 'formik';
import { TenantHomeDashboardNavigationBar } from 'components/LayoutComponent/VerticalLayout/HeaderBar';
import { ButtonBackToTop } from 'components/ButtonComponent/ButtonBackToTop';
import { PATH_CONSTANT } from 'constant/PathConstant';
import { useListCurrentSystemDropdown } from 'services/v1/TenantMaster/MasterTable/SystemTableDataService';
import {
  useDeployTenant,
  useGetListOrganizationDropdown,
} from 'services/v1/TenantMaster/MasterTable/TenantTableDataService';
import { MEMBER_LIST_STATIC_RECORD } from 'data/MemberListRecord';
import { TenantDetails } from 'types/api/TenantMaster/MasterTable/TenantTableDataTypes';
import { useUsersForDeployTenant } from 'services/v1/SystemTenant/TenantTableData';
import { useServerListDropdown } from 'services/v1/TenantMaster/MasterTable/ServerTableDataService';
import { CreateAndDeployTenantValidationSchema } from 'config/FormValidaton/TenantMaster/MasterTable/TenantTableValidationSchema';

type ChipColor = 'success' | 'warning';

export const userSelectionDataGridStyle = {
  '& .MuiCheckbox-root svg': {
    backgroundColor: 'transparent',
    color: '#98A2AE',
    borderRadius: 2,
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: '#EDF2FF',
    borderTop: '1px solid #E3EBF6',
    borderBottom: '1px solid #E3EBF6',
    textAlign: 'center',
    display: 'flex',
  },
};

export const autocompleteStyles = {
  width: '55%',
  '& .MuiOutlinedInput-root': {
    padding: '1px 2px',
  },
  '& .MuiAutocomplete-popper': {
    fontSize: '10px',
  },
};

export const textInputAutoCompleteStyles = {
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

const textInputStyles = {
  width: '55%',
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

const chipStyle = {
  mr: 1,
  fontSize: '10px',
};

const optionStyles = { '& > span': { fontSize: '14px', mr: 2, flexShrink: 0 } };

export const getRolesBySelectedTenantCode = (tenantCode?: string | undefined) => {
  if (!tenantCode) return undefined;
  // if organization code contains "dev"
  if (/dev/.test(tenantCode.toLowerCase())) {
    return ['Builder', 'Tester', 'Manager', 'Admin'];
  }
  // if organization code contains "dem"
  if (/dem/.test(tenantCode.toLowerCase())) {
    return ['Packager', 'Trainer', 'Manager', 'Admin'];
  }
  return ['Manager', 'Support', 'Admin'];
};

function getChipColor(type: string, status: string): ChipColor {
  const colorsMapping: Record<string, Record<string, string>> = {
    codeStatus: {
      [MEMBER_LIST_STATIC_RECORD.CODE_STATUS.LOCKED]: 'success',
      [MEMBER_LIST_STATIC_RECORD.CODE_STATUS.CHANGING]: 'warning',
    },
    demoStatus: {
      [MEMBER_LIST_STATIC_RECORD.DEMO_STATUS.LOCKED]: 'success',
      [MEMBER_LIST_STATIC_RECORD.DEMO_STATUS.CHANGING]: 'warning',
    },
    fieldStatus: {
      [MEMBER_LIST_STATIC_RECORD.FIELD_STATUS.RELEASED]: 'success',
      [MEMBER_LIST_STATIC_RECORD.FIELD_STATUS.NOT_AVAILABLE]: 'warning',
    },
  };
  return colorsMapping[type][status] as ChipColor;
}

function getChiplabel(status: string) {
  const labelMapping = {
    [MEMBER_LIST_STATIC_RECORD.CODE_STATUS.LOCKED]: 'CS: Locked',
    [MEMBER_LIST_STATIC_RECORD.CODE_STATUS.CHANGING]: 'CS: Changing',
    [MEMBER_LIST_STATIC_RECORD.DEMO_STATUS.LOCKED]: 'DS: Locked',
    [MEMBER_LIST_STATIC_RECORD.DEMO_STATUS.CHANGING]: 'DS: Changing',
    [MEMBER_LIST_STATIC_RECORD.FIELD_STATUS.RELEASED]: 'FS: Released',
    [MEMBER_LIST_STATIC_RECORD.FIELD_STATUS.NOT_AVAILABLE]: 'FS: Not Available',
  };
  return labelMapping?.[status] || '';
}

const formInitialValues: TenantDetails = {
  serverId: null,
  systemId: null,
  organizationId: null,
  displayName: null,
  userId: [],
};

const chipRenderFieldOtions = ['codeStatus', 'demoStatus', 'fieldStatus'];

export default function AddTenantPage() {
  const formikRef = useRef<FormikProps<TenantDetails>>(null);
  const navigate = useNavigate();
  const [selectedFormValue, setSelectedFormValue] = useState<TenantDetails>(formInitialValues);
  const { data: allSystemListDropdown, isLoading: isLoadingSystem } = useListCurrentSystemDropdown();
  const { data: organizationsDropdown, isLoading: isLoadingOrganization } = useGetListOrganizationDropdown(
    selectedFormValue.systemId?.value || undefined
  );
  const { data: usersData, isLoading: isLoadingUsers } = useUsersForDeployTenant(
    getRolesBySelectedTenantCode(selectedFormValue.organizationId?.label),
    selectedFormValue.organizationId?.label
  );
  const { data: serverOptions, isLoading: isLoadingServer } = useServerListDropdown();
  const { mutate: deploySystem, isLoading: isDeploying, error, isError } = useDeployTenant();

  const isAnyLoading = isLoadingSystem || isLoadingOrganization || isLoadingServer || isLoadingUsers;

  const columns: GridColDef[] = [
    { field: 'fullName', headerName: 'Username', width: 180 },
    { field: 'roleType', headerName: 'Role Type', width: 100 },
    {
      field: 'userName',
      headerName: 'Email',
      width: 240,
    },
  ];

  const selectedIds = useMemo(() => {
    if (selectedFormValue.userId) {
      return selectedFormValue.userId.map((user) => user.id);
    }
    return [];
  }, [selectedFormValue.userId]);

  const handleOnSave = (data: TenantDetails) => {
    const payloadData = {
      systemId: data.systemId?.value || null,
      serverId: data.serverId?.value || null,
      organizationId: data.organizationId?.value || null,
      userId: data.userId,
      displayName: data.displayName,
    };
    deploySystem(payloadData, {
      onSuccess: () => {
        handleOnCancelInput();
        toast.success('Tenant has been created successfully!');
      },
    });
  };

  const handleOnCancelInput = () => {
    navigate(PATH_CONSTANT.TENANT_MASTER.MASTER_TABLE.TENANT);
  };
  return (
    <TenantHomeDashboardNavigationBar>
      <ButtonBackToTop />
      <Box sx={{ height: '100%' }}>
        <Formik
          innerRef={formikRef}
          initialValues={formInitialValues}
          validationSchema={CreateAndDeployTenantValidationSchema}
          onSubmit={handleOnSave}
        >
          {(formikProps: FormikProps<TenantDetails>) => {
            const { values, setFieldValue, handleBlur, handleChange, touched, errors } = formikProps;
            return (
              <Box sx={{ backgroundColor: '#fff', pb: 3, px: 3 }} alignItems='center'>
                <Stack
                  direction='row'
                  alignItems='center'
                  py={1}
                  justifyContent='space-between'
                  sx={{ backgroundColor: '#fff' }}
                  spacing={2}
                >
                  <Stack>
                    <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                      Add Tenant
                    </Typography>
                  </Stack>
                </Stack>
                <Stack>
                  <Typography variant='input-label' fontStyle='italic' sx={{ pt: 1, fontSize: 13 }}>
                    (<span style={{ color: 'red' }}>*</span>) indicates required fields
                  </Typography>
                </Stack>

                <Grid
                  container
                  marginTop={2}
                  sx={{ backgroundColor: '#fff', py: 1, borderTop: '1px solid #E3EBF6' }}
                  justifyContent='space-between'
                  display='flex'
                  flexWrap='wrap'
                  alignItems='flex-start'
                >
                  <Grid container item xs={6} direction='column'>
                    <Stack
                      direction='row'
                      spacing={2}
                      alignItems='center'
                      justifyContent='space-between'
                      sx={{ mt: 2 }}
                    >
                      <Typography variant='input-label'>
                        System
                        <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                      </Typography>
                      <Autocomplete
                        options={allSystemListDropdown?.data || []}
                        // @ts-ignore
                        value={values.systemId}
                        getOptionLabel={(option) => option.label}
                        clearIcon={null}
                        contentEditable={false}
                        onChange={(event, value) => {
                          setSelectedFormValue((prevState) => ({
                            ...prevState,
                            organizationId: null,
                            serverId: null,
                            systemId: value,
                            userId: [],
                          }));
                          setFieldValue('systemId', value);
                          setFieldValue('organizationId', null);
                          setFieldValue('serverId', null);
                          setFieldValue('userId', []);
                        }}
                        renderOption={(props, option) => (
                          <Box component='li' sx={optionStyles} {...props}>
                            <span>{option.label}</span>
                            {chipRenderFieldOtions.map((field) => (
                              <Chip
                                // @ts-ignore
                                label={getChiplabel(option[field] || '')}
                                // @ts-ignore
                                color={getChipColor(field, option[field])}
                                variant='outlined'
                                size='small'
                                sx={chipStyle}
                              />
                            ))}
                          </Box>
                        )}
                        sx={autocompleteStyles}
                        renderInput={(params) => (
                          <TextField {...params} sx={textInputAutoCompleteStyles} placeholder='Select System' />
                        )}
                      />
                    </Stack>
                    <Stack
                      direction='row'
                      spacing={2}
                      alignItems='center'
                      justifyContent='space-between'
                      sx={{ mt: 2 }}
                    >
                      <Typography variant='input-label'>
                        Organization
                        <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                      </Typography>
                      <Autocomplete
                        options={organizationsDropdown?.data || []}
                        // @ts-ignore
                        value={values.organizationId}
                        disabled={isAnyLoading || !values.systemId}
                        getOptionLabel={(option) => option.label}
                        clearIcon={null}
                        contentEditable={false}
                        onChange={(event, value) => {
                          setSelectedFormValue((prevState) => ({
                            ...prevState,
                            organizationId: value,
                          }));
                          setFieldValue('organizationId', value);
                        }}
                        renderOption={(props, option) => (
                          <Box component='li' sx={optionStyles} {...props}>
                            <span>{option.label}</span>
                          </Box>
                        )}
                        sx={autocompleteStyles}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={textInputAutoCompleteStyles}
                            placeholder={isAnyLoading ? 'Loading...' : 'Select Organization'}
                          />
                        )}
                      />
                    </Stack>
                    <Stack
                      direction='row'
                      spacing={2}
                      alignItems='center'
                      justifyContent='space-between'
                      sx={{ mt: 2 }}
                    >
                      <Typography variant='input-label'>
                        Server
                        <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                      </Typography>
                      <Autocomplete
                        options={serverOptions || []}
                        // @ts-ignore
                        value={values.serverId}
                        disabled={isAnyLoading || !values.systemId || !values.organizationId}
                        getOptionLabel={(option) => option.label}
                        clearIcon={null}
                        contentEditable={false}
                        onChange={(event, value) => {
                          setFieldValue('serverId', value);
                        }}
                        renderOption={(props, option) => (
                          <Box component='li' sx={optionStyles} {...props}>
                            <span>{option.label}</span>
                          </Box>
                        )}
                        sx={autocompleteStyles}
                        renderInput={(params) => (
                          <TextField {...params} sx={textInputAutoCompleteStyles} placeholder='Select Server' />
                        )}
                      />
                    </Stack>
                    <Stack
                      direction='row'
                      spacing={2}
                      alignItems='center'
                      justifyContent='space-between'
                      sx={{ mt: 2 }}
                    >
                      <Typography variant='input-label'>Display Name</Typography>
                      <TextField
                        sx={textInputStyles}
                        size='small'
                        placeholder='e.g. Teamwork'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.displayName}
                        name='displayName'
                        error={touched.displayName && Boolean(errors.displayName)}
                        helperText={touched.displayName && errors.displayName}
                      />
                    </Stack>
                    <Stack direction='column' spacing={2} justifyContent='space-between' sx={{ mt: 2 }}>
                      <Typography variant='input-label' mb={2}>
                        Assign Users
                        <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                      </Typography>
                      <div style={{ height: 250, width: '100%' }}>
                        {!isLoadingUsers && (
                          <DataGridPro
                            loading={isLoadingUsers}
                            selectionModel={selectedIds}
                            sx={userSelectionDataGridStyle}
                            density='compact'
                            rows={usersData?.data || []}
                            columns={columns}
                            onSelectionModelChange={(newSelection) => {
                              if (usersData?.data) {
                                const newUserIdValues = newSelection.map((id: any) => {
                                  return usersData.data.find((user) => user.id === id);
                                });
                                // @ts-ignore
                                setSelectedFormValue((prevState) => ({
                                  ...prevState,
                                  userId: newUserIdValues,
                                }));
                                setFieldValue('userId', newUserIdValues);
                              }
                            }}
                            pagination={false}
                            hideFooter
                            rowsPerPageOptions={[5]}
                            checkboxSelection={
                              !isAnyLoading || (!!values.systemId && !!values.organizationId && !!values.serverId)
                            }
                          />
                        )}
                      </div>
                    </Stack>
                    {isError && (
                      <Stack mt={2}>
                        <Alert severity='error'>{error.response?.data.message}</Alert>
                      </Stack>
                    )}
                  </Grid>
                </Grid>
                <Stack direction='row' spacing={2} justifyContent='flex-end'>
                  <Button disabled={isDeploying} onClick={handleOnCancelInput} variant='main-table-panel-border'>
                    Cancel
                  </Button>

                  <ButtonLoading
                    loading={isDeploying}
                    variant='main-table-panel'
                    onClick={() => formikProps.handleSubmit()}
                    disabled={!CreateAndDeployTenantValidationSchema?.isValidSync(formikProps.values) || isDeploying}
                  >
                    Save
                  </ButtonLoading>
                </Stack>
              </Box>
            );
          }}
        </Formik>
      </Box>
    </TenantHomeDashboardNavigationBar>
  );
}
