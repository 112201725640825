import { TenantHomeDashboardNavigationBar } from 'components/LayoutComponent/VerticalLayout/HeaderBar';
import { Box, Paper, Typography, Stack, Grid, List, ListItem, ListItemButton, Popper } from '@mui/material';

import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import Divider from '@mui/material/Divider';
import { useGetMyPersonalization } from 'services/v1/Tenant/ConfigurationService';
import { useMemo, useState } from 'react';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { MyViewDashboardItem } from 'types/api/Tenant/ConfigurationTypes';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { getQuickAccessNavigateURL } from 'components/LayoutComponent/VerticalLayout/QuickAccess';
import { PAGE_REF_NAVIGATION } from 'utils/Animation';
import { useNavigate } from 'react-router-dom';
import { useDashboardNaviagationPrefetchHandler } from 'hooks/useDashboardNavigationPrefetchHandler';
import { RootState } from 'store';
import { useSelector } from 'react-redux';

const listContainerStyle = {
  backgroundColor: '#fff',
  minWidth: 150,
  height: '100%',
  overflowY: 'auto',
  my: 1,
  px: 2,
};

const menuItemStyle = {
  py: 0,
  px: 0.5,
  borderRadius: '4px',
  color: '#797979',
  '&:hover': {
    outline: '1px solid #aeafb0',
  },
};

const menuItemTextStyle = { fontSize: 15, py: 0.5 };

export default function AdminTenantHomePage() {
  const { role } = useSelector((state: RootState) => state.auth);
  const { data: allMyViewsData } = useGetMyPersonalization({ enabled: true });
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState<HTMLDivElement | null>(null);
  const [activeSolution, setActiveSolution] = useState<AutoCompleteItem | null>(null);
  const prefetchHandler = useDashboardNaviagationPrefetchHandler();
  const navigate = useNavigate();

  const mapSolutionWithDashboard = useMemo(() => {
    const map = new Map();
    if (allMyViewsData?.data) {
      allMyViewsData.data.solutions.forEach((solution) => {
        const dashboards = allMyViewsData.data.dashboards.filter((dsb) => dsb.solutionDefinition.id === solution.value);
        map.set(solution.value.toString(), dashboards);
      });
    }

    return map;
  }, [allMyViewsData?.data]);
  const myViewDashboardList: MyViewDashboardItem[] = useMemo(() => {
    if (activeSolution?.value) {
      return mapSolutionWithDashboard.get(activeSolution.value);
    }
    return [];
  }, [activeSolution, mapSolutionWithDashboard]);
  const handleOnSolutionSelect = (item: AutoCompleteItem, event: React.MouseEvent<HTMLDivElement>) => {
    setSubMenuAnchorEl(event.currentTarget);
    setActiveSolution(item);
  };

  const handleOnDashboardClick = (dashboardItem: MyViewDashboardItem) => {
    const myViewItem = dashboardItem.myViews[0];
    const navigateURL = getQuickAccessNavigateURL(myViewItem, true, PAGE_REF_NAVIGATION.LEFT, role);
    navigateURL && navigate(navigateURL);
  };

  const handleOnPrefetchMyViewItem = (dashboardItem: MyViewDashboardItem) => {
    const myViewItem = dashboardItem.myViews[0];
    const nextMyViewItem = dashboardItem.myViews?.[1];
    prefetchHandler(myViewItem, nextMyViewItem);
  };

  return (
    <TenantHomeDashboardNavigationBar
      breadCrumbs={[]}
      containerSx={{ height: '100%', flex: 1, flexDirection: 'column', overflowY: 'auto', display: 'flex' }}
      rootContainerSx={{ height: '90vh' }}
    >
      <Grid container spacing={2} height='100%'>
        <Grid item xs={12}>
          <Paper
            variant='outlined'
            sx={{
              height: '100%',
            }}
          >
            <Box p={2}>
              <Stack direction='row' gap={1} mb={1}>
                <StarBorderOutlinedIcon
                  fontSize='medium'
                  sx={{
                    color: '#42BB93',
                  }}
                />
                <Typography variant='body1' fontWeight='bold' color='text.primary'>
                  myView
                </Typography>
              </Stack>
              <Divider sx={{ mb: 1 }} />
              <Stack height='100%'>
                <Stack direction='row' gap={1} height='100%'>
                  <Stack width={'20%'} overflow='auto' height='100%'>
                    <List sx={listContainerStyle}>
                      {allMyViewsData?.data.solutions.map((solution) => (
                        <ListItem disablePadding key={solution.value} sx={{ mb: 1 }}>
                          <ListItemButton
                            selected={activeSolution?.value === solution.value}
                            onMouseEnter={(event) => {
                              handleOnSolutionSelect(solution, event);
                            }}
                            sx={{
                              ...menuItemStyle,
                              paddingLeft: `10px`,
                              marginBottom: 0,
                              paddingBottom: 0,
                            }}
                          >
                            <Typography variant='input-label' sx={menuItemTextStyle}>
                              {solution.label}
                            </Typography>
                            <KeyboardArrowRight sx={{ marginLeft: 'auto' }} />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </Stack>
                  <Divider orientation='vertical' variant='middle' flexItem />
                  <Popper
                    open={Boolean(subMenuAnchorEl)}
                    anchorEl={subMenuAnchorEl}
                    placement='auto-start'
                    sx={{ marginLeft: '30px !important' }}
                    modifiers={[
                      {
                        name: 'offset',
                        options: {
                          offset: [-20, 0], // Adjust these values as needed
                        },
                      },
                      {
                        name: 'preventOverflow',
                        options: {
                          boundary: 'viewport',
                        },
                      },
                    ]}
                  >
                    <List sx={listContainerStyle}>
                      {myViewDashboardList.map((dashboardItem) => (
                        <ListItem disablePadding key={dashboardItem.id}>
                          <ListItemButton
                            onClick={(event) => {
                              handleOnDashboardClick(dashboardItem);
                            }}
                            onMouseEnter={() => handleOnPrefetchMyViewItem(dashboardItem)}
                            sx={{
                              ...menuItemStyle,
                              paddingLeft: `10px`,
                              marginBottom: 0,
                              paddingBottom: 0,
                            }}
                          >
                            <Typography variant='input-label' sx={menuItemTextStyle}>
                              {dashboardItem.dashboardName}
                            </Typography>
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </Popper>
                </Stack>
              </Stack>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </TenantHomeDashboardNavigationBar>
  );
}
