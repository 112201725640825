import { useQueryClient } from 'react-query';
import { AxiosResponse } from 'axios';

// Custom reusable hook for prefetching with parameters
export function usePrefetchQueryWithParams<T = unknown>(
  queryKeyBase: string | unknown[],
  fetchFn: (params: any) => Promise<AxiosResponse<T>>,
  options = {}
) {
  const queryClient = useQueryClient();

  const prefetch = (params: any) => {
    const queryKey = Array.isArray(queryKeyBase) ? [...queryKeyBase, params] : [queryKeyBase, params];
    queryClient.prefetchQuery(queryKey, () => fetchFn(params), options);
  };

  return prefetch;
}

export function usePrefetchQueryManualKey<T = unknown>(
  fetchFn: (params: any) => Promise<AxiosResponse<T> | any>,
  options = {}
) {
  const queryClient = useQueryClient();

  const prefetch = (manualKey: unknown[], params: any) => {
    queryClient.prefetchQuery(manualKey, () => fetchFn(params), options);
  };

  return prefetch;
}
