import * as React from 'react';
import * as Yup from 'yup';
import { styled, alpha } from '@mui/material/styles';
import { Backdrop, FormControlLabel, Popper, Radio, RadioGroup } from '@mui/material';
import Menu, { MenuProps } from '@mui/material/Menu';
import { Paper, Stack } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ButtonLoading from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import { useGetSubjectDetails } from 'services/v1/SystemTenant/AROKMS/SubjectService';
import { useGetTableAnalyticOptions } from 'services/v1/SystemTenant/AROKMS/TableAnalyticService';
import { useFormik } from 'formik';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { useParams } from 'react-router-dom';

const StyledPopper = styled(Popper)(({ theme }) => ({
  zIndex: 1000,
  '&[x-placement*="bottom"] $arrow': {
    top: 0,
    left: 0,
    marginTop: '-0.71em',
    marginLeft: 4,
    marginRight: 4,
    '&::before': {
      transformOrigin: '0 100%',
    },
  },
  '&[x-placement*="top"] $arrow': {
    bottom: 0,
    left: 0,
    marginBottom: '-0.71em',
    marginLeft: 4,
    marginRight: 4,
    '&::before': {
      transformOrigin: '100% 0',
    },
  },
  '&[x-placement*="right"] $arrow': {
    left: 0,
    marginLeft: '-0.71em',
    height: '1em',
    width: '0.71em',
    marginTop: 4,
    marginBottom: 4,
    '&::before': {
      transformOrigin: '100% 100%',
    },
  },
  '&[x-placement*="left"] $arrow': {
    right: 0,
    marginRight: '-0.71em',
    height: '1em',
    width: '0.71em',
    marginTop: 4,
    marginBottom: 4,
    '&::before': {
      transformOrigin: '0 0',
    },
  },
}));

export const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      color: '#42BB93',
      fontSize: 13,
      '& .MuiSvgIcon-root': {
        fontSize: 16,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },

      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

export enum FileType {
  CSV = 'CSV',
  EXCEL = 'EXCEL',
}

export interface FormTableAnalyticParameter {
  rowColumn: AutoCompleteItem | null;
  columnColumn: AutoCompleteItem | null;
  dataColumn: AutoCompleteItem | null;
  dataDecimals: string;
  scale?: string;
}

interface ButtonTableAnalyticsProps {
  onDisplayData?: (data: FormTableAnalyticParameter) => void;
  disabled?: boolean | undefined;
}

const autocompleteStyles = {
  width: '55%',
  '& .MuiOutlinedInput-root': {
    padding: '1px 2px',
  },
  '& .MuiAutocomplete-popper': {
    fontSize: '10px',
  },
};

const textInputStyles = {
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

const radioButtonLabelStyle = {
  '& .MuiFormControlLabel-label': {
    fontSize: '14px',
    color: '#98A2AE',
  },
};

const radioButtonStyle = {
  color: '#98A2AE',
  '& .MuiSvgIcon-root': {
    fontSize: 20,
  },
  '&.Mui-checked': {
    color: '#42BB93',
  },
};
export const TABLE_ANALYTIC_SCALE_OPTION_VALUE = {
  THOUSHAND: 'thousand',
  MILLION: 'MILLION',
};

export const TABLE_ANALYTIC_SCALE_ABBREVIATION = new Map([
  [TABLE_ANALYTIC_SCALE_OPTION_VALUE.THOUSHAND, 'K'],
  [TABLE_ANALYTIC_SCALE_OPTION_VALUE.MILLION, 'M'],
]);
export const TABLE_ANALYTICS_SCALE_OPTIONS = [
  {
    label: 'None',
    value: 'NONE',
  },
  {
    label: 'Thousand',
    value: TABLE_ANALYTIC_SCALE_OPTION_VALUE.THOUSHAND,
  },
  {
    label: 'Million',
    value: TABLE_ANALYTIC_SCALE_OPTION_VALUE.MILLION,
  },
];

export const tableAnalyticParameterValidatonSchema = Yup.object().shape({
  rowColumn: Yup.object().required('Required'),
  columnColumn: Yup.object().required('Required'),
  dataColumn: Yup.object().required('Required'),
  dataDecimals: Yup.string().required('Required'),
});

const formikInitialValues: FormTableAnalyticParameter = {
  rowColumn: null,
  columnColumn: null,
  dataColumn: {
    label: 'Count',
    value: 'COUNT',
  },
  dataDecimals: '0',
  scale: 'NONE',
};

export function ButtonTableAnalytics(props: ButtonTableAnalyticsProps) {
  const { onDisplayData, disabled } = props;
  const { subjectId } = useParams<{ subjectId: string }>();
  const { data: subjectDetails } = useGetSubjectDetails(subjectId);

  const { data: tableAnalyticOptions } = useGetTableAnalyticOptions({
    enable: true,
    subjectId: subjectDetails?.data?.id?.toString(),
  });

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleOnCancelInput = () => {
    handlePopoverClose();
  };

  const open = Boolean(anchorEl);

  const formik = useFormik<FormTableAnalyticParameter>({
    initialValues: formikInitialValues,
    validationSchema: tableAnalyticParameterValidatonSchema,
    onSubmit: (values, formikHelper) => {
      handlePopoverClose();
      onDisplayData &&
        onDisplayData({
          ...values,
          scale: values.scale === 'NONE' ? undefined : values.scale,
        });
      formikHelper.resetForm();
    },
  });

  const rowOptions = React.useMemo(() => {
    if (tableAnalyticOptions?.data?.rowOptions) {
      return tableAnalyticOptions?.data?.rowOptions.filter(
        (option) => option.value !== formik.values.columnColumn?.value
      );
    }
  }, [tableAnalyticOptions?.data?.rowOptions, formik.values.columnColumn]);

  const columnOptions = React.useMemo(() => {
    if (tableAnalyticOptions?.data?.columnOptions) {
      return tableAnalyticOptions?.data?.columnOptions.filter(
        (option) => option.value !== formik.values.rowColumn?.value
      );
    }
  }, [tableAnalyticOptions?.data?.columnOptions, formik.values.rowColumn]);

  return (
    <div>
      <Backdrop open={open} sx={{ zIndex: 999 }} />
      <Tooltip title='Table Analytics'>
        <span data-testid='button-export-label'>
          <IconButton data-testid='button-export' onClick={handlePopoverOpen}>
            <TroubleshootIcon sx={{ color: '#42BB93' }} />
          </IconButton>
        </span>
      </Tooltip>

      <StyledPopper
        open={open}
        anchorEl={anchorEl}
        placement='bottom'
        modifiers={[
          {
            name: 'flip',
            enabled: true,
            options: {
              altBoundary: true,
              rootBoundary: 'document',
              padding: 8,
            },
          },
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              altAxis: true,
              altBoundary: true,
              tether: true,
              rootBoundary: 'document',
              padding: 8,
            },
          },
        ]}
      >
        <Paper sx={{ py: 2, px: 3, display: 'flex', flexDirection: 'column' }}>
          <Stack width={330} mb={3}>
            <Typography fontWeight='bold' sx={{ fontSize: '18px', mb: 1 }}>
              Table Analytics
            </Typography>
          </Stack>
          <Stack width='100%' mb={2} flexDirection='row' gap={2} justifyContent='center' alignItems='center'>
            <Stack width='30%'>
              <Typography variant='input-label' fontWeight={800}>
                Column
              </Typography>
            </Stack>
            <Stack width='70%'>
              <Autocomplete
                options={columnOptions || []}
                value={formik.values.columnColumn}
                sx={{ ...autocompleteStyles, width: '100%' }}
                onChange={(event, newValue) => {
                  formik.setFieldValue('columnColumn', newValue);
                }}
                clearIcon={null}
                renderInput={(params) => <TextField {...params} placeholder='Select Attribute...' />}
              />
            </Stack>
          </Stack>
          <Stack width='100%' mb={2} flexDirection='row' gap={2} justifyContent='center' alignItems='center'>
            <Stack width='30%'>
              <Typography variant='input-label' fontWeight={800}>
                Row
              </Typography>
            </Stack>
            <Stack width='70%'>
              <Autocomplete
                options={rowOptions || []}
                value={formik.values.rowColumn}
                sx={{ ...autocompleteStyles, width: '100%' }}
                onChange={(event, newValue) => {
                  formik.setFieldValue('rowColumn', newValue);
                }}
                clearIcon={null}
                renderInput={(params) => <TextField {...params} placeholder='Select Attribute...' />}
              />
            </Stack>
          </Stack>
          <Stack width='100%' mb={2} flexDirection='row' gap={2} justifyContent='center' alignItems='center'>
            <Stack width='30%'>
              <Typography variant='input-label' fontWeight={800}>
                Data
              </Typography>
            </Stack>
            <Stack width='70%'>
              <Autocomplete
                options={tableAnalyticOptions?.data?.dataOptions || []}
                value={formik.values.dataColumn}
                sx={{ ...autocompleteStyles, width: '100%' }}
                onChange={(event, newValue) => {
                  formik.setFieldValue('dataColumn', newValue);
                }}
                clearIcon={null}
                renderInput={(params) => <TextField {...params} placeholder='Select Attribute...' />}
              />
            </Stack>
          </Stack>
          <Stack width='100%' mb={2} flexDirection='row' gap={2} justifyContent='center' alignItems='center'>
            <Stack width='30%'>
              <Typography variant='input-label' fontWeight={800}>
                Data Decimals
              </Typography>
            </Stack>
            <Stack width='70%'>
              <TextField
                sx={textInputStyles}
                name='dataDecimals'
                hiddenLabel
                value={formik.values.dataDecimals}
                onChange={(e) => {
                  if (/^[0-9]*$/.test(e.target.value)) {
                    formik.handleChange(e);
                  }
                }}
                onBlur={formik.handleBlur}
                variant='outlined'
                size='small'
                placeholder='e.g. 3'
              />
            </Stack>
          </Stack>
          <Stack width='100%' mb={2} flexDirection='row' gap={2} justifyContent='center' alignItems='center'>
            <Stack width='15%'>
              <Typography variant='input-label' fontWeight={800}>
                Scale
              </Typography>
            </Stack>
            <Stack width='85%'>
              <RadioGroup
                value={formik.values.scale}
                onChange={(event, value) => {
                  formik.setFieldValue('scale', value);
                  if (value === TABLE_ANALYTIC_SCALE_OPTION_VALUE.MILLION) {
                    formik.setFieldValue('dataDecimals', '2');
                  }
                }}
              >
                <Stack direction='row' spacing={1}>
                  {TABLE_ANALYTICS_SCALE_OPTIONS.map((option) => (
                    <FormControlLabel
                      value={option.value}
                      sx={radioButtonLabelStyle}
                      control={<Radio sx={radioButtonStyle} />}
                      label={option.label}
                    />
                  ))}
                </Stack>
              </RadioGroup>
            </Stack>
          </Stack>

          <Stack direction='row' spacing={2} justifyContent='flex-end'>
            <Button onClick={handleOnCancelInput} variant='main-table-panel-border'>
              Cancel
            </Button>

            <ButtonLoading
              variant='main-table-panel'
              onClick={() => formik.handleSubmit()}
              disabled={!tableAnalyticParameterValidatonSchema.isValidSync(formik.values) || disabled}
            >
              Display
            </ButtonLoading>
          </Stack>
        </Paper>
      </StyledPopper>
    </div>
  );
}
