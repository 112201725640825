import { AxiosResponse } from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import axios from 'libs/Axios';

import { AxiosDefaultErrorEntity } from 'types/api/Common/ErrorTypes';
import {
  MyViewDashboardDefinitionItem,
  MyViewItemConfig,
  PersonalizationConfig,
  QuickAccessItem,
  RequestMyViewDashboardItemOrdering,
  RequestSaveMyViewDashboard,
  TenantConfiguration,
  TenantUser,
} from 'types/api/Tenant/ConfigurationTypes';
import { KTreeNode } from 'types/api/Tenant/AROKMS/KTreeDataTypes';
import { createTreeData, sortTreeData } from 'utils/Object';
import { ENV_CONFIG } from 'config/env';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { usePrefetchQueryWithParams } from 'hooks/usePrefetchQuery';

export const TENANT_CONFIGURATION_DATA_QUERY = {
  CONFIGURATION_LIST: 'CONFIGURATION_LIST',
  PEOPLE_CONFIG_LIST: 'PEOPLE_CONFIG_LIST',
  TENANT_USER_LIST: 'TENANT_USER_LIST',
  ALL_MY_VIEW_ITEMS: 'ALL_MY_VIEW_ITEMS',
  PEOPLE_MY_VIEW_ITEMS: 'PEOPLE_MY_VIEW_ITEMS',
  MY_PERSONALZIATION_CONFIG: 'MY_PERSONALZIATION_CONFIG',
  MY_VIEW_DETAILS: 'MY_VIEW_DETAILS',
  MY_VIEW_MEMBER: 'MY_VIEW_MEMBER',
  ADDITIONAL_WORKFLOW_DROPDOWN: 'ADDITIONAL_WORKFLOW_DROPDOWN',
  MY_VIEW_DASHBOARD_DEFINITION_LIST: 'MY_VIEW_DASHBOARD_DEFINITION_LIST',
};

export function useGetTenantConfigData({
  refetchInterval = false,
  enabled = true,
}: {
  refetchInterval: number | false;
  enabled?: boolean;
}) {
  return useQuery<AxiosResponse<TenantConfiguration[]>, AxiosDefaultErrorEntity>(
    [TENANT_CONFIGURATION_DATA_QUERY.CONFIGURATION_LIST],
    () => axios.get(`/api/v1/tenant/config/list`).then((res) => res),
    {
      refetchInterval,
      enabled: enabled,
    }
  );
}

export function useGetTenantUsers() {
  return useQuery<AxiosResponse<TenantUser[]>, AxiosDefaultErrorEntity>(
    [TENANT_CONFIGURATION_DATA_QUERY.TENANT_USER_LIST],
    () => axios.get(`/api/v1/tenant/config/users`).then((res) => res)
  );
}

export function useGetAllMyViewItemList() {
  return useQuery<AxiosResponse<MyViewItemConfig[]>, AxiosDefaultErrorEntity>(
    [TENANT_CONFIGURATION_DATA_QUERY.ALL_MY_VIEW_ITEMS],
    () => axios.get(`/api/v1/tenant/config/my-view-definition/list`).then((res) => res)
  );
}

export function useGetMyViewDashboardList() {
  return useQuery<AxiosResponse<MyViewDashboardDefinitionItem[]>, AxiosDefaultErrorEntity>(
    [TENANT_CONFIGURATION_DATA_QUERY.MY_VIEW_DASHBOARD_DEFINITION_LIST],
    () => axios.get(`/api/v1/tenant/config/my-view/dashboard/list`).then((res) => res)
  );
}

export function useUpdateMyViewDashboardListOrdering() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, RequestMyViewDashboardItemOrdering[]>(
    (bodyRequest) =>
      axios.post('/api/v1/tenant/config/my-view-definition/dashboard/ordering', bodyRequest).then((res) => res),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(TENANT_CONFIGURATION_DATA_QUERY.MY_VIEW_DASHBOARD_DEFINITION_LIST);
      },
    }
  );
}

export function useGetMyPersonalization({ enabled = true }: { enabled?: boolean }) {
  return useQuery<AxiosResponse<PersonalizationConfig>, AxiosDefaultErrorEntity>(
    [TENANT_CONFIGURATION_DATA_QUERY.MY_PERSONALZIATION_CONFIG],
    () => axios.get(`/api/v1/tenant/config/my-view/personalization`).then((res) => res),
    {
      cacheTime: ENV_CONFIG.endpoint.MAXIMUM_CACHE_TIME,
      staleTime: ENV_CONFIG.endpoint.MAXIMUM_CACHE_TIME,
    }
  );
}

export function useGetMyViewDetails(myViewId: string | null) {
  return useQuery<AxiosResponse<QuickAccessItem>, AxiosDefaultErrorEntity>(
    [TENANT_CONFIGURATION_DATA_QUERY.MY_VIEW_DETAILS, myViewId],
    () => axios.get(`/api/v1/tenant/config/my-view/${myViewId}`).then((res) => res),
    {
      enabled: !!myViewId,
      cacheTime: ENV_CONFIG.endpoint.MAXIMUM_CACHE_TIME,
      staleTime: ENV_CONFIG.endpoint.MAXIMUM_CACHE_TIME,
    }
  );
}
export function usePrefetchMyViewDetails() {
  return usePrefetchQueryWithParams(
    TENANT_CONFIGURATION_DATA_QUERY.MY_VIEW_DETAILS,
    (myViewId: string) => axios.get(`/api/v1/tenant/config/my-view/${myViewId}`).then((res) => res),
    {
      cacheTime: ENV_CONFIG.endpoint.MAXIMUM_CACHE_TIME,
      staleTime: ENV_CONFIG.endpoint.MAXIMUM_CACHE_TIME,
    }
  );
}
export function useGetMyPersonalizationLogin() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse<PersonalizationConfig>, AxiosDefaultErrorEntity>(
    () => axios.get(`/api/v1/tenant/config/my-view/personalization`).then((res) => res),
    {
      onSuccess: (response) => {
        queryClient.setQueryData([TENANT_CONFIGURATION_DATA_QUERY.MY_PERSONALZIATION_CONFIG], response);
      },
    }
  );
}

export function useSaveMyViewDashboard() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse<TenantConfiguration[]>, AxiosDefaultErrorEntity, RequestSaveMyViewDashboard>(
    (bodyRequest) => axios.post('/api/v1/tenant/config/my-view-definition/dashboard', bodyRequest).then((res) => res),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(TENANT_CONFIGURATION_DATA_QUERY.PEOPLE_MY_VIEW_ITEMS);
        queryClient.invalidateQueries(TENANT_CONFIGURATION_DATA_QUERY.MY_PERSONALZIATION_CONFIG);
      },
    }
  );
}

export function useUpdateUserDefaultLoginView() {
  const queryClient = useQueryClient();
  return useMutation<
    AxiosResponse,
    AxiosDefaultErrorEntity,
    {
      myViewId: string;
      userId: string;
    }
  >(
    (bodyRequest) =>
      axios.post(`/api/v1/tenant/config/my-view-definition/update-default-login`, bodyRequest).then((res) => res),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(TENANT_CONFIGURATION_DATA_QUERY.PEOPLE_MY_VIEW_ITEMS);
      },
    }
  );
}

export function useGetMyViewMember(myViewId?: string | null) {
  return useQuery<KTreeNode[], AxiosDefaultErrorEntity>(
    [TENANT_CONFIGURATION_DATA_QUERY.MY_VIEW_MEMBER, myViewId],
    () =>
      axios.get(`/api/v1/tenant/config/my-view/component-member/${myViewId}`).then((res) => {
        if (res.data.length > 0) {
          const treeData = createTreeData(null, res.data);
          return sortTreeData(treeData);
        }
        return null;
      }),
    {
      enabled: !!myViewId,
    }
  );
}

export function useGetAdditionalWorkFlowsDropdown() {
  return useQuery<AxiosResponse<AutoCompleteItem[]>, AxiosDefaultErrorEntity>(
    [TENANT_CONFIGURATION_DATA_QUERY.ADDITIONAL_WORKFLOW_DROPDOWN],
    () => axios.get(`/api/v1/tenant/config/my-view-definition/additional-workflow`).then((res) => res)
  );
}

export function useGetTenantConfigDataMutation() {
  return useMutation<AxiosResponse<TenantConfiguration[]>, AxiosDefaultErrorEntity>(() =>
    axios.get(`/api/v1/tenant/config/list`).then((res) => res)
  );
}

export function useSaveTenantConfigData() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse<TenantConfiguration[]>, AxiosDefaultErrorEntity, TenantConfiguration[]>(
    (data) => axios.post(`/api/v1/tenant/config/save`, data).then((res) => res),
    {
      onSettled: () => {
        queryClient.invalidateQueries(TENANT_CONFIGURATION_DATA_QUERY.CONFIGURATION_LIST);
      },
    }
  );
}

export function useSaveInitialTenantConfigData() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse<TenantConfiguration[]>, AxiosDefaultErrorEntity, TenantConfiguration[]>(
    (data) => axios.post(`/api/v1/tenant/config/initial-setup`, data).then((res) => res),
    {
      onSettled: () => {
        queryClient.invalidateQueries(TENANT_CONFIGURATION_DATA_QUERY.CONFIGURATION_LIST);
      },
    }
  );
}
