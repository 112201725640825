import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import axios from 'libs/Axios';
import { AxiosDefaultErrorEntity } from 'types/api/Common/ErrorTypes';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import {
  SolutionDefinitionRowItem,
  SolutionDefinitionUpserRequest,
} from 'types/api/SystemTenant/AROKMS/SolutionDefinitionTypes';

export interface SubjectDropdownParameter extends AutoCompleteItem {
  persistentName?: string | null;
}

const SOLUTION_DEFINITION_QUERY = {
  SOLUTION_DEFINITION_LIST: 'SOLUTION_DEFINITION_LIST',
  SOLUTION_DEFINITION_DROPDOOWN: 'SOLUTION_DEFINITION_DROPDOOWN',
};

export function useUpsertSolutionDefinitionData() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, SolutionDefinitionUpserRequest>(
    (bodyRequest) => axios.post('/api/v1/solution-definition/upsert', bodyRequest),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([SOLUTION_DEFINITION_QUERY.SOLUTION_DEFINITION_LIST]);
        queryClient.invalidateQueries([SOLUTION_DEFINITION_QUERY.SOLUTION_DEFINITION_DROPDOOWN]);
      },
    }
  );
}

export function useSolutionDefinitionList() {
  return useQuery<AxiosResponse<SolutionDefinitionRowItem[]>, AxiosDefaultErrorEntity>(
    [SOLUTION_DEFINITION_QUERY.SOLUTION_DEFINITION_LIST],
    () => axios.get(`/api/v1/solution-definition/list`).then((res) => res)
  );
}

export function useGetSolutionDefinitionDropdown() {
  return useQuery<AxiosResponse<AutoCompleteItem[]>, AxiosDefaultErrorEntity>(
    [SOLUTION_DEFINITION_QUERY.SOLUTION_DEFINITION_DROPDOOWN],
    () => axios.get(`/api/v1/solution-definition/list/dropdown`).then((res) => res)
  );
}
