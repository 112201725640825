import * as Yup from 'yup';
import React, { useMemo } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { MainLayoutComponent } from 'components/LayoutComponent/SidebarLayout/MainLayoutComponent';
import { INSIGHT_VIEW_CUBE_ADDITIONAL_DROPDOWN_OPTIONS, InsightViewType } from 'constant/ViewConstant';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import ButtonLoading from '@mui/lab/LoadingButton';
import Checkbox from '@mui/material/Checkbox';
import { Box } from '@mui/material';
import { useFormik } from 'formik';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { useNavigate } from 'react-router-dom';
import { PATH_CONSTANT } from 'constant/PathConstant';
import {
  IInsightViewAttributeDefinitionRef,
  InsightViewAttributeDefinitionTable,
} from './components/InsightViewAttributeDefinition';
import ActivityIndicator from 'components/ActivityIndicatorComponent';
import {
  useGetStandardViewDefinitionCubeDropdown,
  useGetStandardViewDefinitionSubjectDropdown,
} from 'services/v1/SystemTenant/AROView/StandardViewDefinitionService';
import {
  InsightViewAttributeItem,
  RequestCreateInsightViewDefinition,
  RequestInsightViewAttributeItem,
  RequestInsightViewVarianceItem,
} from 'types/api/SystemTenant/AROView/InsightViewDefinitionTypes';
import { useCreateInsightViewDefinition } from 'services/v1/SystemTenant/AROView/InsightViewDefinitionService';
import {
  IInsightViewVarianceDefinitionRef,
  InsightViewVarianceDefinitionTable,
} from './components/InsightViewVarianceDefinition';
import {
  IInsightViewCellColorDefinitionRef,
  InsightViewCellColorDefinitionTable,
} from './components/InsightViewCellColorDefinition';
import { InsightViewTypeComponent } from './components/InsightType';

const autocompleteStyles = {
  width: '55%',
  '& .MuiOutlinedInput-root': {
    padding: '1px 2px',
  },
  '& .MuiAutocomplete-popper': {
    fontSize: '10px',
  },
};
const textInputStyles = {
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

export interface CreateInsightViewDefinitionFormikInitialValues {
  name: string | null;
  subject: AutoCompleteItem | null;
  cube: AutoCompleteItem | null;
  type: string;
}
export const createInsightViewDefinitionFormikInitialValues: CreateInsightViewDefinitionFormikInitialValues = {
  name: null,
  subject: null,
  cube: null,
  type: InsightViewType.REPORT,
};

export const createInsightViewDefinitionValidationSchema = Yup.object().shape({
  name: Yup.string().required('This field is required').nullable(),
  subject: Yup.object().nullable(),
  cube: Yup.object().required('This field is required').nullable(),
});

export default function CreateInsightViewDefinition() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const attributeDefiintionRef = React.useRef<IInsightViewAttributeDefinitionRef>(null);
  const varianceDefinitionRef = React.useRef<IInsightViewVarianceDefinitionRef>(null);
  const cellColorDefinitionRef = React.useRef<IInsightViewCellColorDefinitionRef>(null);
  const [insightAttributeDefinition, setInsightAttributeDefinition] = React.useState<InsightViewAttributeItem[]>([]);
  const [showVarianceDefinition, setShowVarianceDefinition] = React.useState<boolean>(false);
  const [showCellColorDefinition, setShowColorDefinition] = React.useState<boolean>(false);

  const formik = useFormik({
    initialValues: createInsightViewDefinitionFormikInitialValues,
    validationSchema: createInsightViewDefinitionValidationSchema,
    onSubmit: () => {},
  });

  const { data: subjectDropdownOptions, isLoading: isLoadingSubjectOptions } =
    useGetStandardViewDefinitionSubjectDropdown();
  const { data: cubeDropdownOptions, isLoading: isLoadingCubeOptions } = useGetStandardViewDefinitionCubeDropdown(
    formik.values.subject?.value?.toString() ?? ''
  );

  const { mutate: createInsightView, isLoading: isSubmitting } = useCreateInsightViewDefinition();

  const cubeDropdownOptionsCustom = useMemo(() => {
    if (cubeDropdownOptions?.data && cubeDropdownOptions.data.length > 0) {
      return [...cubeDropdownOptions.data, ...INSIGHT_VIEW_CUBE_ADDITIONAL_DROPDOWN_OPTIONS];
    }
    return INSIGHT_VIEW_CUBE_ADDITIONAL_DROPDOWN_OPTIONS;
  }, [cubeDropdownOptions?.data]);

  const handleOnSubmit = () => {
    if (formik.values.cube && formik.values.name) {
      const attributeDefinitionData = attributeDefiintionRef.current?.getRowData();
      const varianceDefinitionData = varianceDefinitionRef?.current?.getRowData();
      const cellColorDefinitionData = cellColorDefinitionRef?.current?.getRowData();
      const attributeDefinitions: RequestInsightViewAttributeItem[] = (attributeDefinitionData ?? []).map((item) => {
        return {
          id: item.id,
          isGroup: item.isGroup,
          isNew: item.isNew,
          relativePeriodType: item.relativePeriodType,
          relativePeriod: item.relativePeriod,
          relativeYear: item.relativeYear,
          relativeYearType: item.relativeYearType,
          totalRule: item.totalRule?.value.toString() ?? null,
          accumulationRule: item.accumulationRule?.value.toString() ?? null,
          name: item.name,
          groupId: item.group?.value.toString() ?? null,
          cubeLayerId: item.cubeLayer?.value.toString() ?? null,
          groupTopHeaderType: item.groupTopHeaderType,
        };
      });

      const varianceDefinitions: RequestInsightViewVarianceItem[] =
        varianceDefinitionData?.map((item) => {
          return {
            groupId: item.group?.value.toString() ?? null,
            resultColumnId: item.resultColumn?.value.toString() ?? null,
            targetColumnId: item.targetColumn?.value.toString() ?? null,
            id: item.id,
          };
        }) ?? [];

      // @ts-ignore
      const cellColorDefinitions: RequestInsightViewCellColorItem[] =
        cellColorDefinitionData
          ?.map((item) => {
            if (item.cubeAttribute?.value) {
              return {
                id: item.id,
                cubeAttributeId: item.cubeAttribute?.value.toString(),
                lessThanColor: item.lessThanColor?.value ?? null,
                lessThanValue: item.lessThanValue,
                greaterThanColor: item.greaterThanColor?.value ?? null,
                greaterThanValue: item.greaterThanValue,
              };
            }
            return null;
          })
          .filter((item) => item != null) ?? [];

      const payload: RequestCreateInsightViewDefinition = {
        cubeId: formik.values.cube?.value?.toString(),
        name: formik.values.name,
        insightViewType: formik.values.type,
        attributeDefinitions,
        varianceDefinitions,
        cellColorDefinitions,
      };
      createInsightView(payload, {
        onSuccess: () => {
          toast.success('Create Insight View Definition successfully!');
          navigate(PATH_CONSTANT.SYSTEM.VIEW.INSIGHT_VIEW_DEFINITION);
        },
      });
    }
  };

  const handleOnCancel = () => {
    navigate(PATH_CONSTANT.SYSTEM.VIEW.INSIGHT_VIEW_DEFINITION);
  };

  const handleOnCubeChange = (e: React.SyntheticEvent, value: AutoCompleteItem | null) => {
    formik.setFieldValue('cube', value);
  };

  const handleOnSubjectChange = (e: React.SyntheticEvent, value: AutoCompleteItem | null) => {
    formik.setFieldValue('subject', value);
  };

  const handleOnChangeShowVarianceDefinition = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowVarianceDefinition(e.target.checked);
  };

  const handleOnChangeShowCellColorDefinition = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowColorDefinition(e.target.checked);
  };

  const isAllFormValid = createInsightViewDefinitionValidationSchema.isValidSync(formik.values);
  const isLoadingOptionsData = isLoadingSubjectOptions;

  return (
    <MainLayoutComponent
      pageTitle='Add New Insight View'
      breadcrumbs={[t('SIDEBAR_MENU.DASHBOARD'), 'Insight View Definition']}
    >
      <Box p={2} bgcolor='#fff'>
        {isLoadingOptionsData ? (
          <Stack height={450}>
            <ActivityIndicator />
          </Stack>
        ) : (
          <>
            <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
              Insight View Definition
            </Typography>
            <Divider sx={{ my: 1 }} />
            <Stack width='100%' direction='row' gap={3} mb={2} flexWrap='wrap' alignItems='center'>
              <Stack width='40%'>
                <Typography variant='input-label' fontWeight={800}>
                  Insight Name
                </Typography>
                <Typography variant='input-label-gray' fontSize={13}></Typography>
                <TextField
                  sx={textInputStyles}
                  name='name'
                  hiddenLabel
                  value={formik.values.name}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  variant='outlined'
                  size='small'
                  placeholder='e.g. Interest Function for Loan'
                />
              </Stack>
              <Stack width='40%'>
                <Typography variant='input-label' fontWeight={800}>
                  Select Subject
                </Typography>
                <Typography variant='input-label-gray' fontSize={13}>
                  Choose the subject (table) from which you want to pull the values.
                </Typography>
                <Autocomplete
                  options={subjectDropdownOptions?.data ?? []}
                  getOptionLabel={(option) => option.label}
                  sx={{ ...autocompleteStyles, width: '100%' }}
                  clearIcon={null}
                  value={formik.values.subject}
                  onBlur={formik.handleBlur}
                  onChange={handleOnSubjectChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name='subject'
                      placeholder='Select Subject...'
                      error={formik.touched.subject && Boolean(formik.errors.subject)}
                      helperText={formik.touched.subject && formik.errors.subject}
                    />
                  )}
                />
              </Stack>
              <Stack width='40%'>
                <Typography variant='input-label' fontWeight={800}>
                  Select Cube
                </Typography>
                <Typography variant='input-label-gray' fontSize={13}>
                  Choose the cube from which you want to calculate the values for.
                </Typography>
                <Autocomplete
                  options={cubeDropdownOptionsCustom}
                  disabled={isLoadingCubeOptions}
                  getOptionLabel={(option) => option.label}
                  sx={{ ...autocompleteStyles, width: '100%' }}
                  clearIcon={null}
                  value={formik.values.cube}
                  onBlur={formik.handleBlur}
                  onChange={handleOnCubeChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name='cube'
                      placeholder='Select Cube...'
                      error={formik.touched.cube && Boolean(formik.errors.cube)}
                      helperText={formik.touched.cube && formik.errors.cube}
                    />
                  )}
                />
              </Stack>
              <Stack width='40%'>
                <Typography variant='input-label' fontWeight={800}>
                  Select Insight Type
                </Typography>
                <Typography variant='input-label-gray' fontSize={13}>
                  Choose the cube from which you want to calculate the values for.
                </Typography>
                <InsightViewTypeComponent
                  value={formik.values.type}
                  onChange={(selectedValue) => {
                    formik.setFieldValue('type', selectedValue);
                  }}
                />
              </Stack>
            </Stack>
            <Stack direction='row' alignItems='center' mb={2}>
              <Checkbox
                checked={showVarianceDefinition}
                onChange={handleOnChangeShowVarianceDefinition}
                name='active'
                sx={{
                  color: '#828C99',
                  px: 0,
                  paddingRight: 0.5,
                }}
              />
              <Typography variant='input-label-gray' fontWeight={600}>
                Add Variance Definition to this Insight View
              </Typography>
            </Stack>
            <Stack direction='row' alignItems='center' mb={2}>
              <Checkbox
                checked={showCellColorDefinition}
                onChange={handleOnChangeShowCellColorDefinition}
                name='active'
                sx={{
                  color: '#828C99',
                  px: 0,
                  paddingRight: 0.5,
                }}
              />
              <Typography variant='input-label-gray' fontWeight={600}>
                Add Cell Colour Definition
              </Typography>
            </Stack>
            <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
              Column Definition
            </Typography>
            <Divider sx={{ my: 1 }} />

            <InsightViewAttributeDefinitionTable
              disabled={!formik.values.cube || !formik.values.subject || !formik.values.name}
              cubeId={formik.values.cube?.value?.toString()}
              insightDefinitionType={formik.values.type}
              onTableChange={(data) => {
                setInsightAttributeDefinition(data);
              }}
              ref={attributeDefiintionRef}
            />

            {showVarianceDefinition && (
              <>
                <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                  Variance Definition
                </Typography>
                <Divider sx={{ my: 1 }} />
                <InsightViewVarianceDefinitionTable
                  attributeDefinitionData={insightAttributeDefinition}
                  ref={varianceDefinitionRef}
                />
              </>
            )}

            {showCellColorDefinition && (
              <>
                <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                  Cell Colour Definition
                </Typography>
                <Divider sx={{ my: 1 }} />
                <InsightViewCellColorDefinitionTable
                  attributeDefinitionData={insightAttributeDefinition}
                  initialData={[]}
                  ref={cellColorDefinitionRef}
                  cubeId={formik.values.cube?.value?.toString()}
                />
              </>
            )}
            <Stack direction='row' px={2} justifyContent='space-between' alignItems='center'>
              <Stack />
              <Stack direction='row' gap={2}>
                <ButtonLoading disabled={isSubmitting} variant='main-table-panel-border' onClick={handleOnCancel}>
                  Cancel
                </ButtonLoading>
                <ButtonLoading
                  variant='main-table-panel'
                  loading={isSubmitting}
                  onClick={handleOnSubmit}
                  disabled={!isAllFormValid}
                >
                  Save
                </ButtonLoading>
              </Stack>
            </Stack>
          </>
        )}
      </Box>
    </MainLayoutComponent>
  );
}
