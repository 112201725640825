import * as Yup from 'yup';
import React, { HTMLAttributes, useEffect, useMemo, useState } from 'react';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ButtonLoading from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AutoComplete from '@mui/material/Autocomplete';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { RelativeTimeType } from './RelativeTimeType';
import { InsightViewAttributeItem } from 'types/api/SystemTenant/AROView/InsightViewDefinitionTypes';
import { useGetStandardViewDefinitionCubeLayerDropdown } from 'services/v1/SystemTenant/AROView/StandardViewDefinitionService';
import { getInsightViewRelativeTimeDescription } from 'utils/DateUtils';
import {
  ACCUMULATION_TYPE_DROPDOWN_OPTIONS,
  InsightViewRelativeTimeCategory,
  InsightViewRelativeTimeType,
  TOTAL_RULE_DROPDOWN_OPTIONS,
} from 'constant/ViewConstant';
import { useFormik } from 'formik';
import { INSIGHT_COLUM_GROUP_TOP_HEADER_TYPE } from './SelectGroupType';

const autoCompleteStyle = {
  '& .MuiAutocomplete-popper': {
    backgroundColor: 'red !imoprtant',
    fontSize: '10px',
  },
};
const ModalContentStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 650,
  bgcolor: 'background.paper',
  maxHeight: 580,
  overflow: 'auto',
  borderRadius: 1,
  py: 2,
};

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

const nonNegativeIntegerRegex = /^\d*$/;

export interface ModalAddInsightViewAttributeDefinitionProps {
  visible?: boolean;
  onClose?: () => void;
  onSave?: (attributeItem: InsightViewAttributeItem) => void;
  onDelete?: (attributeItem: InsightViewAttributeItem) => void;
  cubeId?: string;
  selectedData?: InsightViewAttributeItem | null;
  groupData: InsightViewAttributeItem[];
  mapGroupIdWithGroupData: Map<string, InsightViewAttributeItem>;
}

const formikValidationSchema = Yup.object().shape({
  name: Yup.string().required('This field is required').nullable(),
  cubeLayer: Yup.object().required('This field is required').nullable(),
  relativePeriod: Yup.number().required('This field is required'),
  relativePeriodType: Yup.string().required('This field is required'),
  relativeYear: Yup.number().required('This field is required'),
  relativeYearType: Yup.string().required('This field is required'),
  group: Yup.object().required('This field is required').nullable(),
});

export type FormikInsightAttibuteValues = Omit<InsightViewAttributeItem, 'id' | 'isNew' | 'groupTopHeaderType'>;

export const formikInsightAttributeInitialValues: FormikInsightAttibuteValues = {
  cubeLayer: null,
  isGroup: false,
  name: '',
  relativePeriod: 0,
  relativePeriodType: InsightViewRelativeTimeType.CURRENT_TIME,
  relativeYear: 0,
  relativeYearType: InsightViewRelativeTimeType.CURRENT_TIME,
  totalRule: null,
  accumulationRule: null,
  group: null,
};

export function ModalAddColumnAttribute(props: Readonly<ModalAddInsightViewAttributeDefinitionProps>) {
  const { onClose, visible = false, onSave, cubeId, onDelete, selectedData, mapGroupIdWithGroupData } = props;

  const { data: cubeLayerOptions, isLoading: isLoadingDropdownCubeLayer } =
    useGetStandardViewDefinitionCubeLayerDropdown(cubeId);

  const [selecteGroupType, setSelectedGroupType] = useState<string | null>(null);

  const formik = useFormik<FormikInsightAttibuteValues>({
    initialValues: formikInsightAttributeInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: () => {},
  });

  const handleOnCloseModal = () => {
    onClose?.();
    formik.resetForm();
    setSelectedGroupType(null);
  };

  const handleOnCancel = () => {
    handleOnCloseModal();
  };
  const handleOnDelete = () => {
    onDelete?.(selectedData as InsightViewAttributeItem);
    handleOnCloseModal();
  };

  const handleOnRelativeYearTypeChange = (selectedVal: string) => {
    formik.setFieldValue('relativeYearType', selectedVal);

    const isCurrentTime = selectedVal === InsightViewRelativeTimeType.CURRENT_TIME;
    const shouldResetRelativeYear = isCurrentTime || formik.values.relativeYear === 0;

    if (shouldResetRelativeYear) {
      formik.setFieldValue('relativeYear', isCurrentTime ? 0 : 1);
    }
  };

  const handleOnRelativePeriodTypeChange = (selectedVal: string) => {
    formik.setFieldValue('relativePeriodType', selectedVal);

    const isCurrentTime = selectedVal === InsightViewRelativeTimeType.CURRENT_TIME;
    const shouldResetRelativePeriod = isCurrentTime || formik.values.relativePeriod === 0;

    if (shouldResetRelativePeriod) {
      formik.setFieldValue('relativePeriod', isCurrentTime ? 0 : 1);
    }
  };

  const handleRelativeYearChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (nonNegativeIntegerRegex.test(value)) {
      formik.setFieldValue('relativeYear', value);
    }
  };

  const handleRelativePeriodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (nonNegativeIntegerRegex.test(value)) {
      formik.setFieldValue('relativePeriod', value);
    }
  };

  const handleOnChangeGroup = (event: React.ChangeEvent<{}>, newValue: AutoCompleteItem | null) => {
    formik.setFieldValue('group', newValue);
    if (newValue) {
      // Inherit relative period and relative year from group
      const selectedGroupItem = mapGroupIdWithGroupData.get(newValue?.value?.toString());
      if (selectedGroupItem) {
        setSelectedGroupType(selectedGroupItem.groupTopHeaderType);
        if (selectedGroupItem.groupTopHeaderType === INSIGHT_COLUM_GROUP_TOP_HEADER_TYPE.TIME_PERIOD) {
          formik.setFieldValue('relativePeriodType', selectedGroupItem.relativePeriodType);
          formik.setFieldValue('relativePeriod', selectedGroupItem.relativePeriod);
        }
        if (selectedGroupItem.groupTopHeaderType === INSIGHT_COLUM_GROUP_TOP_HEADER_TYPE.RELATIVE_YEAR_AND_LAYER) {
          formik.setFieldValue('relativeYearType', selectedGroupItem.relativeYearType);
          formik.setFieldValue('relativeYear', selectedGroupItem.relativeYear);
          formik.setFieldValue('cubeLayer', selectedGroupItem.cubeLayer);
        }
        if (selectedGroupItem.groupTopHeaderType === INSIGHT_COLUM_GROUP_TOP_HEADER_TYPE.TOTAL_RULE) {
          formik.setFieldValue('totalRule', selectedGroupItem.totalRule);
        }
        if (selectedGroupItem.groupTopHeaderType === INSIGHT_COLUM_GROUP_TOP_HEADER_TYPE.STATIC_PERIOD) {
          formik.setFieldValue('accumulationRule', selectedGroupItem.accumulationRule);
        }
      }
    }
  };

  const handleOnSave = () => {
    if (!selectedData) {
      const payload: InsightViewAttributeItem = {
        id: `NEWCOLUMN-${Date.now()}`,
        isNew: true,
        ...formik.values,
        isGroup: false,
        groupTopHeaderType: null,
      };
      onSave?.(payload);
    } else {
      const payload: InsightViewAttributeItem = {
        ...selectedData,
        ...formik.values,
      };
      onSave?.(payload);
    }
    handleOnCloseModal();
  };

  const groupDropdownOptions = useMemo(() => {
    return props.groupData
      .filter((item) => item.id !== selectedData?.id)
      .map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
  }, [props.groupData, selectedData]);

  const renderOption = (props: HTMLAttributes<HTMLElement>, option: AutoCompleteItem) => {
    return (
      <Box component='li' sx={{ '& > span': { fontSize: '14px', mr: 1, flexShrink: 0 } }} {...props}>
        <span>{option.label}</span>
      </Box>
    );
  };

  const isValidForm = formikValidationSchema.isValidSync(formik.values);

  useEffect(() => {
    if (selectedData) {
      formik.setValues({
        ...selectedData,
        relativePeriodType: selectedData.relativePeriodType ?? InsightViewRelativeTimeType.CURRENT_TIME,
        relativeYearType: selectedData.relativeYearType ?? InsightViewRelativeTimeType.CURRENT_TIME,
      });
      if (selectedData.group) {
        const groupItem = mapGroupIdWithGroupData.get(selectedData.group?.value?.toString());
        if (groupItem) {
          setSelectedGroupType(groupItem.groupTopHeaderType);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedData]);

  return (
    <div>
      <Modal open={visible} onClose={handleOnCloseModal}>
        <Stack direction='column' sx={ModalContentStyle} px={2}>
          <Stack>
            <Stack justifyContent='space-between' direction='row' alignItems='center'>
              <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                Column Definition
              </Typography>
              <IconButton onClick={handleOnCloseModal} component='label'>
                <HighlightOffRoundedIcon sx={closeIconStyle} />
              </IconButton>
            </Stack>
          </Stack>
          <Divider sx={{ mb: 1 }} />
          <Stack mb={1.6} width='100%' direction='row' spacing={2.5} alignItems='center'>
            <Stack mb={1.6} width='50%'>
              <Typography variant='body2' fontWeight='bold' color='black'>
                Column Name
              </Typography>
              <Typography variant='input-label-gray' mb={1}>
                Input the name of the column, the name will be not be displayed in the view.
              </Typography>
              <TextField
                size='small'
                placeholder='e.g. Last Year Actual'
                value={formik.values.name}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                name='name'
              />
            </Stack>
            <Stack mb={1.6} width='50%'>
              <Typography variant='body2' fontWeight='bold' color='black'>
                Column Group
              </Typography>
              <Typography variant='input-label-gray' mb={1}>
                Choose the group for this column to be displayed in.
              </Typography>
              <AutoComplete
                clearIcon={null}
                size='small'
                value={formik.values.group}
                onChange={handleOnChangeGroup}
                onBlur={formik.handleBlur}
                getOptionLabel={(option: AutoCompleteItem) => option.label}
                options={groupDropdownOptions}
                renderOption={renderOption}
                sx={autoCompleteStyle}
                renderInput={(params) => (
                  <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                    <TextField {...params} placeholder='Select Group' name='group' />
                  </Stack>
                )}
              />
            </Stack>
          </Stack>
          {formik.values.group && formik.values.name && (
            <>
              <Stack mb={1.6} width='100%' direction='row' spacing={2.5} alignItems='center'>
                {selecteGroupType !== INSIGHT_COLUM_GROUP_TOP_HEADER_TYPE.STATIC_PERIOD && (
                  <Stack mb={1.6} width='50%' spacing={1}>
                    <Stack>
                      <Typography variant='body2' fontWeight='bold' color='black'>
                        Relative Period
                      </Typography>
                      {selecteGroupType === INSIGHT_COLUM_GROUP_TOP_HEADER_TYPE.TIME_PERIOD && (
                        <Typography variant='input-label-gray' fontSize={12}>
                          (Inherited from group <b>{formik.values.group?.label}</b>)
                        </Typography>
                      )}
                    </Stack>
                    <RelativeTimeType
                      value={formik.values.relativePeriodType}
                      onChange={handleOnRelativePeriodTypeChange}
                      disabled={selecteGroupType === INSIGHT_COLUM_GROUP_TOP_HEADER_TYPE.TIME_PERIOD}
                    />
                    <TextField
                      size='small'
                      placeholder='e.g. 1'
                      value={formik.values.relativePeriod}
                      onBlur={formik.handleBlur}
                      onChange={handleRelativePeriodChange}
                      disabled={
                        formik.values.relativePeriodType === InsightViewRelativeTimeType.CURRENT_TIME ||
                        selecteGroupType === INSIGHT_COLUM_GROUP_TOP_HEADER_TYPE.TIME_PERIOD
                      }
                      type='number'
                      name='relativePeriod'
                    />
                    {formik.values.relativePeriodType && formik.values.relativePeriod != null && (
                      <Typography variant='input-label-gray' mb={1}>
                        {getInsightViewRelativeTimeDescription(
                          formik.values.relativePeriod,
                          formik.values.relativePeriodType,
                          InsightViewRelativeTimeCategory.PERIOD_TIME
                        )}
                      </Typography>
                    )}
                  </Stack>
                )}

                <Stack mb={1.6} width='50%' spacing={1}>
                  <Typography variant='body2' fontWeight='bold' color='black'>
                    Relative Year
                  </Typography>
                  {selecteGroupType === INSIGHT_COLUM_GROUP_TOP_HEADER_TYPE.RELATIVE_YEAR_AND_LAYER && (
                    <Typography variant='input-label-gray' fontSize={12}>
                      (Inherited from group <b>{formik.values.group?.label}</b>)
                    </Typography>
                  )}
                  <RelativeTimeType
                    value={formik.values.relativeYearType}
                    onChange={handleOnRelativeYearTypeChange}
                    disabled={selecteGroupType === INSIGHT_COLUM_GROUP_TOP_HEADER_TYPE.RELATIVE_YEAR_AND_LAYER}
                  />
                  <TextField
                    size='small'
                    placeholder='e.g. 1'
                    value={formik.values.relativeYear}
                    disabled={
                      formik.values.relativeYearType === InsightViewRelativeTimeType.CURRENT_TIME ||
                      selecteGroupType === INSIGHT_COLUM_GROUP_TOP_HEADER_TYPE.RELATIVE_YEAR_AND_LAYER
                    }
                    onBlur={formik.handleBlur}
                    onChange={handleRelativeYearChange}
                    name='relativeYear'
                  />
                  {formik.values.relativeYearType && formik.values.relativeYear != null && (
                    <Typography variant='input-label-gray' mb={1}>
                      {getInsightViewRelativeTimeDescription(
                        formik.values.relativeYear,
                        formik.values.relativeYearType,
                        InsightViewRelativeTimeCategory.YEAR_TIME
                      )}
                    </Typography>
                  )}
                </Stack>
              </Stack>

              <Stack mb={1.6} width='100%' direction='row' spacing={2.5} alignItems='center'>
                <Stack mb={1.6} width='50%'>
                  <Typography variant='body2' fontWeight='bold' color='black'>
                    Cube Layer
                  </Typography>
                  {selecteGroupType === INSIGHT_COLUM_GROUP_TOP_HEADER_TYPE.RELATIVE_YEAR_AND_LAYER && (
                    <Typography variant='input-label-gray' fontSize={12} mb={1}>
                      (Inherited from group <b>{formik.values.group?.label}</b>)
                    </Typography>
                  )}
                  {selecteGroupType !== INSIGHT_COLUM_GROUP_TOP_HEADER_TYPE.RELATIVE_YEAR_AND_LAYER && (
                    <Typography variant='input-label-gray' mb={1}>
                      Choose the cube layer from which you want to display the values for.
                    </Typography>
                  )}
                  <AutoComplete
                    clearIcon={null}
                    size='small'
                    disabled={selecteGroupType === INSIGHT_COLUM_GROUP_TOP_HEADER_TYPE.RELATIVE_YEAR_AND_LAYER}
                    value={formik.values.cubeLayer}
                    onChange={(event, newValue) => {
                      formik.setFieldValue('cubeLayer', newValue);
                    }}
                    onBlur={formik.handleBlur}
                    getOptionLabel={(option: AutoCompleteItem) => option.label}
                    options={cubeLayerOptions?.data ?? []}
                    renderOption={renderOption}
                    sx={autoCompleteStyle}
                    renderInput={(params) => (
                      <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                        <TextField
                          {...params}
                          name='cubeLayer'
                          placeholder={isLoadingDropdownCubeLayer ? 'Loading...' : 'Select Layer'}
                        />
                      </Stack>
                    )}
                  />
                </Stack>
                {selecteGroupType !== INSIGHT_COLUM_GROUP_TOP_HEADER_TYPE.STATIC_PERIOD ? (
                  <Stack mb={1.6} width='50%'>
                    <Typography variant='body2' fontWeight='bold' color='black'>
                      Total Rule
                    </Typography>
                    {selecteGroupType === INSIGHT_COLUM_GROUP_TOP_HEADER_TYPE.TOTAL_RULE && (
                      <Typography variant='input-label-gray' fontSize={12} mb={1}>
                        (Inherited from group <b>{formik.values.group?.label}</b>)
                      </Typography>
                    )}
                    {selecteGroupType !== INSIGHT_COLUM_GROUP_TOP_HEADER_TYPE.TOTAL_RULE && (
                      <Typography variant='input-label-gray' mb={1}>
                        Choose the total rule for this column to be displayed in.
                      </Typography>
                    )}
                    <AutoComplete
                      clearIcon={null}
                      size='small'
                      value={formik.values.totalRule}
                      disabled={selecteGroupType === INSIGHT_COLUM_GROUP_TOP_HEADER_TYPE.TOTAL_RULE}
                      onChange={(event, newValue) => {
                        formik.setFieldValue('totalRule', newValue);
                      }}
                      onBlur={formik.handleBlur}
                      getOptionLabel={(option: AutoCompleteItem) => option.label}
                      options={TOTAL_RULE_DROPDOWN_OPTIONS}
                      renderOption={renderOption}
                      sx={autoCompleteStyle}
                      renderInput={(params) => (
                        <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                          <TextField {...params} placeholder='Select Rule' name='totalRule' />
                        </Stack>
                      )}
                    />
                  </Stack>
                ) : (
                  <Stack mb={1.6} width='50%'>
                    <Typography variant='body2' fontWeight='bold' color='black'>
                      Accumulation Rule
                    </Typography>
                    <Typography variant='input-label-gray' fontSize={12} mb={1}>
                      (Inherited from group <b>{formik.values.group?.label}</b>)
                    </Typography>
                    <Typography variant='input-label-gray' mb={1}>
                      Choose the accumulation rule for this column to be displayed in.
                    </Typography>
                    <AutoComplete
                      clearIcon={null}
                      size='small'
                      disabled
                      value={formik.values.accumulationRule}
                      onChange={(event, newValue) => {
                        formik.setFieldValue('accumulationRule', newValue);
                      }}
                      onBlur={formik.handleBlur}
                      getOptionLabel={(option: AutoCompleteItem) => option.label}
                      options={ACCUMULATION_TYPE_DROPDOWN_OPTIONS}
                      renderOption={renderOption}
                      sx={autoCompleteStyle}
                      renderInput={(params) => (
                        <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                          <TextField {...params} placeholder='Select Rule' name='accumulationRule' />
                        </Stack>
                      )}
                    />
                  </Stack>
                )}
              </Stack>
            </>
          )}
          <Stack height={300} px={2}></Stack>
          <Stack px={2}>
            <Divider sx={{ mb: 1, mt: 2 }} />
            <Stack direction='row' justifyContent='flex-end' spacing={2} alignItems='center' sx={{ py: 1 }}>
              {selectedData && (
                <Button
                  onClick={handleOnDelete}
                  variant='outlined'
                  sx={{
                    textTransform: 'none',
                  }}
                  color='error'
                >
                  Delete
                </Button>
              )}
              <Button variant='main-table-panel-border' onClick={handleOnCancel}>
                Cancel
              </Button>
              <ButtonLoading variant='main-table-panel' disabled={!isValidForm} onClick={() => handleOnSave()}>
                Save Column
              </ButtonLoading>
            </Stack>
          </Stack>
        </Stack>
      </Modal>
    </div>
  );
}
