import qs from 'query-string';
import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import axios from 'libs/Axios';

import { AxiosDefaultErrorEntity } from 'types/api/Common/ErrorTypes';

import { createTreeData, sortTreeData } from 'utils/Object';
import { KTreeNode } from 'types/api/Tenant/AROKMS/KTreeDataTypes';
import {
  DIMENSION_CATEGORY,
  RequestCubeDimensionalView,
  ResponseCubeDimensionalView,
} from 'types/api/Tenant/AROCube/CubeDataDimensionalViewTypes';
import { useSearchParams } from 'react-router-dom';

export const CUBE_DATA_DIMENSIONAL_VIEW_KEY = {
  CUBE_DIMENSIONAL_VIEW: 'CUBE_DIMENSIONAL_VIEW',
  CUBE_DIMENSIONAL_VIEW_FILTER_MEMBER: 'CUBE_DIMENSIONAL_VIEW_FILTER_MEMBER',
};

export function useGetCubeDimensionalView(bodyRequest: RequestCubeDimensionalView) {
  const [searchParams] = useSearchParams();
  const myViewId = searchParams.get('myViewId') || undefined;
  return useQuery<AxiosResponse<ResponseCubeDimensionalView>, AxiosDefaultErrorEntity>(
    [CUBE_DATA_DIMENSIONAL_VIEW_KEY.CUBE_DIMENSIONAL_VIEW, { bodyRequest }],
    () =>
      axios
        .post(
          `/api/v1/tenant/cube-view/dimension-view?${qs.stringify({
            myViewId,
          })}`,
          bodyRequest
        )
        .then((res) => res),
    {
      enabled: !!bodyRequest.filterValue && !!bodyRequest.layerId && !!bodyRequest.cubeId,
    }
  );
}

export function useGetCubeDimensionalViewFilterMember(
  filterType: string,
  cubeId?: string,
  xDimensionType?: string,
  yDimensionType?: string,
  dimensionCategory?: DIMENSION_CATEGORY,
  insightViewId?: string,
  proxyMemberAccessId?: string
) {
  const [searchParams] = useSearchParams();
  const myViewId = searchParams.get('myViewId') || undefined;
  return useQuery<KTreeNode[], AxiosDefaultErrorEntity>(
    [
      CUBE_DATA_DIMENSIONAL_VIEW_KEY.CUBE_DIMENSIONAL_VIEW_FILTER_MEMBER,
      cubeId,
      xDimensionType,
      yDimensionType,
      filterType,
      dimensionCategory,
      insightViewId,
      proxyMemberAccessId,
    ],
    () =>
      axios
        .get(
          `/api/v1/tenant/cube-view/dropdown/member/${cubeId}?${qs.stringify({
            xDimensionType,
            yDimensionType,
            filterType,
            dimensionCategory,
            insightViewId,
            proxyMemberAccessId,
            myViewId,
          })}`
        )
        .then((res) => {
          if (res.data.length > 0) {
            const treeData = createTreeData(null, res.data);
            return sortTreeData(treeData);
          }
          return null;
        }),
    {
      enabled: !!cubeId,
    }
  );
}
