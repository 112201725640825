//@ts-nocheck
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import RectangleIcon from '@mui/icons-material/Rectangle';
import {
  IconButton,
  Grid,
  Stack,
  Tooltip,
  Typography,
  Divider,
  Popper,
  Checkbox,
  Box,
  checkboxClasses,
  Backdrop,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { useStylesPopper } from '../AROKMS/KTree/ViewKtreeData';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGetMyPersonalization, useGetMyViewDetails } from 'services/v1/Tenant/ConfigurationService';
import { MyViewDashboardItem, QuickAccessItem } from 'types/api/Tenant/ConfigurationTypes';
import { getQuickAccessNavigateURL } from 'components/LayoutComponent/VerticalLayout/QuickAccess';
import { PAGE_REF_NAVIGATION } from 'utils/Animation';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useDashboardNaviagationPrefetchHandler } from 'hooks/useDashboardNavigationPrefetchHandler';

const iconButtonStyle = {
  padding: '0px',
};

export enum ControllerNavigationDirection {
  UP = 'up',
  DOWN = 'down',
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
}

interface ControllerNavigationProps {
  onNavigate: (direction: ControllerNavigationDirection) => void;
  onPrefetch?: (direction: ControllerNavigationDirection) => void;
  enableNavigations?: ControllerNavigationDirection[];
  tooltips?: {
    [key in ControllerNavigationDirection]?: string;
  };
}

interface RectangleCheckBoxComponentProps {
  color?: string;
  text: string;
  secondLabel?: string;
  checked: boolean;
  onClick: () => void;
  onMouseEnter?: () => void;
}

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

function ButtonMyViewItem(props: Readonly<RectangleCheckBoxComponentProps>) {
  const { color = '#42BB93', text, secondLabel, checked, onClick, onMouseEnter } = props;

  return (
    <Box
      onMouseEnter={onMouseEnter}
      sx={{
        width: 'auto',
        px: 1,
        height: 40,
        border: `1px solid ${checked ? color : '#bfbfbf'}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 2,
        cursor: 'pointer',
        '&:hover': {
          border: `1px solid ${color}`,
          '& .hover-text': {
            color,
          },
        },
      }}
      onClick={onClick}
    >
      {checked && (
        <Checkbox
          sx={{
            [`&, &.${checkboxClasses.root}`]: {
              color: '#bfbfbf',
              px: 0.5,
            },
            [`&, &.${checkboxClasses.checked}`]: {
              color,
            },
          }}
          checked={checked}
        />
      )}
      <Stack>
        <Typography
          variant='input-label'
          letterSpacing={0.3}
          className='hover-text'
          sx={{
            color: checked ? color : '#737373',
          }}
          textAlign='left'
        >
          {text}
        </Typography>
        {secondLabel && (
          <Typography
            variant='input-label'
            letterSpacing={0.3}
            sx={{
              fontSize: 11,
              color: checked ? color : '#737373',
            }}
            textAlign='left'
          >
            {secondLabel}
          </Typography>
        )}
      </Stack>
    </Box>
  );
}

export function ControllerNavigation(props: ControllerNavigationProps) {
  const {
    onNavigate,
    onPrefetch,
    tooltips = {},
    enableNavigations = [
      ControllerNavigationDirection.UP,
      ControllerNavigationDirection.DOWN,
      ControllerNavigationDirection.LEFT,
      ControllerNavigationDirection.RIGHT,
    ],
  } = props;
  const { role } = useSelector((state: RootState) => state.auth);

  const [anchorEl, setAnchorEl] = useState<null | React.MouseEvent<HTMLElement>>(null);
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);
  const [searchParams] = useSearchParams();
  const prefetchHandler = useDashboardNaviagationPrefetchHandler();
  const navigate = useNavigate();
  const myViewId = searchParams.get('myViewId');

  const classes = useStylesPopper();

  const { data: myViewDetails } = useGetMyViewDetails(myViewId);
  const { data: allMyViewsData } = useGetMyPersonalization({ enabled: true });

  const currentDashboard: MyViewDashboardItem | null = useMemo(() => {
    if (allMyViewsData?.data) {
      const selectedDashboardId = myViewDetails?.data.dashboardId;
      const selectedDashboard =
        allMyViewsData.data.dashboards.find((dashboardItem) => dashboardItem.id === selectedDashboardId) || null;
      if (selectedDashboard) {
        return selectedDashboard;
      }
    }
    return null;
  }, [allMyViewsData?.data, myViewDetails?.data]);

  const insightViewList: QuickAccessItem[] = useMemo(() => {
    if (currentDashboard) {
      return currentDashboard.myViews;
    }

    return [];
  }, [currentDashboard]);

  const handleOnClickButtonMyView = (myViewItem: QuickAccessItem) => {
    const componentURL = getQuickAccessNavigateURL(myViewItem, undefined, PAGE_REF_NAVIGATION.LEFT, role);
    componentURL && navigate(componentURL);
  };

  const handleOnPrefetchMyView = (myViewItem: QuickAccessItem) => {
    prefetchHandler(myViewItem);
  };

  const handleArrowClick = (direction: ControllerNavigationDirection) => {
    onNavigate(direction);
  };

  const handleOnPrefetchData = (direction: ControllerNavigationDirection) => {
    onPrefetch?.(direction);
  };

  const handleOnBoxIconClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.target);
  };

  const handleOnCloseModal = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Backdrop
        open={Boolean(anchorEl)}
        sx={{
          zIndex: 1100,
        }}
      />
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        style={{ width: 'fit-content', position: 'relative' }}
      >
        <Grid item xs={12} container justifyContent='center'>
          {enableNavigations.includes(ControllerNavigationDirection.UP) ? (
            <Tooltip title={tooltips[ControllerNavigationDirection.UP] ?? ''}>
              <IconButton
                style={iconButtonStyle}
                onClick={() => handleArrowClick(ControllerNavigationDirection.UP)}
                onMouseEnter={() => handleOnPrefetchData(ControllerNavigationDirection.UP)}
              >
                <KeyboardArrowUpOutlinedIcon fontSize='medium' />
              </IconButton>
            </Tooltip>
          ) : (
            <Stack height={20} />
          )}
        </Grid>
        <Grid item xs={12} container justifyContent='space-between' alignItems='center' width={70}>
          {enableNavigations.includes(ControllerNavigationDirection.LEFT) ? (
            <Tooltip title={tooltips[ControllerNavigationDirection.LEFT] ?? ''}>
              <IconButton
                style={iconButtonStyle}
                onClick={() => handleArrowClick(ControllerNavigationDirection.LEFT)}
                onMouseEnter={() => handleOnPrefetchData(ControllerNavigationDirection.LEFT)}
              >
                <KeyboardArrowLeftOutlinedIcon fontSize='medium' />
              </IconButton>
            </Tooltip>
          ) : (
            <Stack height={20} />
          )}
          <Tooltip title='Show Navigation Index'>
            <IconButton
              onClick={handleOnBoxIconClick}
              style={{
                ...iconButtonStyle,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              <RectangleIcon fontSize='small' />
            </IconButton>
          </Tooltip>
          {enableNavigations.includes(ControllerNavigationDirection.RIGHT) ? (
            <Tooltip title={tooltips[ControllerNavigationDirection.RIGHT] ?? ''}>
              <IconButton
                style={iconButtonStyle}
                onClick={() => handleArrowClick(ControllerNavigationDirection.RIGHT)}
                onMouseEnter={() => handleOnPrefetchData(ControllerNavigationDirection.RIGHT)}
              >
                <KeyboardArrowRightOutlinedIcon fontSize='medium' />
              </IconButton>
            </Tooltip>
          ) : (
            <Stack height={20} />
          )}
        </Grid>
        <Grid item xs={12} container justifyContent='center'>
          {enableNavigations.includes(ControllerNavigationDirection.DOWN) ? (
            <Stack width={25}>
              <Tooltip title={tooltips[ControllerNavigationDirection.DOWN] ?? ''}>
                <IconButton
                  style={iconButtonStyle}
                  onClick={() => handleArrowClick(ControllerNavigationDirection.DOWN)}
                  onMouseEnter={() => handleOnPrefetchData(ControllerNavigationDirection.DOWN)}
                >
                  <KeyboardArrowDownOutlinedIcon fontSize='medium' />
                </IconButton>
              </Tooltip>
            </Stack>
          ) : (
            <Stack height={20} />
          )}
        </Grid>
      </Grid>
      <Popper
        id='asx'
        sx={{ zIndex: 1200 }}
        open={Boolean(anchorEl)}
        className={classes.popper}
        anchorEl={anchorEl}
        placement='right'
        disablePortal={false}
        modifiers={[
          {
            name: 'flip',
            enabled: true,
            options: {
              altBoundary: true,
              rootBoundary: 'document',
              padding: 8,
            },
          },
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              altAxis: true,
              altBoundary: true,
              tether: true,
              rootBoundary: 'document',
              padding: 8,
            },
          },
          {
            name: 'arrow',
            enabled: true,
            options: {
              element: arrowRef,
            },
          },
        ]}
      >
        <span className={classes.arrow} ref={setArrowRef} />
        <Stack sx={{ bgcolor: '#fff', borderRadius: 1, px: 2, py: 1 }} minWidth={250}>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Stack>
              <Typography variant='body2' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                {currentDashboard?.dashboardName}
              </Typography>
              <Typography fontSize={12} variant='input-label-gray'>
                Index Navigation
              </Typography>
            </Stack>
            <IconButton onClick={handleOnCloseModal} component='label'>
              <HighlightOffRoundedIcon sx={closeIconStyle} />
            </IconButton>
          </Stack>
          <Divider sx={{ mb: 2 }} />
          <Stack>
            <Stack alignItems='center' py={1}>
              <Stack gap={1} maxHeight={400} overflow='auto' pb={2} pt={1}>
                {insightViewList.map((myViewItem) => (
                  <ButtonMyViewItem
                    onMouseEnter={() => handleOnPrefetchMyView(myViewItem)}
                    onClick={() => handleOnClickButtonMyView(myViewItem)}
                    key={myViewItem.value}
                    text={myViewItem.title}
                    checked={myViewId === myViewItem.viewId}
                  />
                ))}
              </Stack>
            </Stack>
            <Stack></Stack>
          </Stack>
        </Stack>
      </Popper>
    </>
  );
}
