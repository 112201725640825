// @ts-nocheck
import _ from 'lodash';
import { useState, useMemo, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router';
import Alert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import qs from 'query-string';
import {
  GridColDef,
  GridRowModesModel,
  GridRowEditStopParams,
  GridRowModes,
  GridRowId,
  GridRowParams,
  GridFilterModel,
  GridSelectionModel,
} from '@mui/x-data-grid-pro';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { CustomDataTable } from 'components/DatatableComponent';

import {
  useDisplayTable,
  DisplayTableParams,
  useUpsertDisplayTable,
  useUpdateDisplayData,
  useDeleteDisplayData,
  useDataInputDownloadImportTemplate,
  useGetCurrentImportActivity,
  useGetDataInputImportHistory,
} from 'services/v1/Tenant/AROKMS/DisplayTableService';
import { DataInputImportActivityStatus, DynamicDataItem } from 'types/api/Tenant/AROKMS/DisplayTableTypes';
import { AxiosDefaultErrorEntity } from 'types';
import { RulesImplementation, getFilterOperatorByRuleTypeCode } from 'config/RulesImplementation/rulesMapping';
import { convertRuleTypeParamsToSnakeCase } from 'utils/String';
import { renderTextInputEditCell } from 'components/DatatableComponent/CustomTextInput';
import { getColumnDataType } from 'config/RulesImplementation/dataTypeMapping';
import { convertObjectToValue } from 'utils/Object';
import { enUS } from '@mui/x-data-grid';

import eventEmitter, { EVENT_EMITTER_KEYS } from 'utils/EventEmitter';
import ModalDeleteComponent, { ModalDeleteState } from 'components/ModalComponent/ModalDeleteComponent';
import { getErrorMessage, getErrorTitle } from 'utils/Error';
import { ButtonRecordEdit } from './components/ButtonRecordEdit';
import { LAYOUT_TABLE_VIEW_TYPE, SUBJECT_TYPE } from 'constant/DataInputConstant';
import { serializeDataInputPayload } from './utils/serialiazePayload';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { checkAccess } from 'routes/PrivateRoute';
import { ROLES } from 'constant/PermissonConstant';
import { DASHBOARD_VIEW_MODE } from 'constant/DashboardTypeConstant';
import {
  generateBreadCumbItemsV2,
  TenantHomeDashboardNavigationBar,
} from 'components/LayoutComponent/VerticalLayout/HeaderBar';
import { PATH_CONSTANT } from 'constant/PathConstant';
import { useSearchParams } from 'react-router-dom';
import { useGetSubjectDetails } from 'services/v1/SystemTenant/AROKMS/SubjectService';
import { useSubjectProcedureDetails } from 'services/v1/SystemTenant/AROKMS/SubjectProcedureService';
import { setHomeNavigationState } from 'store/reducer/homeReducer';
import { ModalDataInputCustomRule } from './components/ModalCustomRule';
import { DataInputSettingPanel } from './components/DataInputSettingPanel';
import { DataInputTableOptions } from 'components/ButtonComponent/ButtonTableOptions';
import { SystemStatusBasedAccessProvider } from 'components/RBAC/SystemStatusBasedAccessProvider';
import { downloadFileFromURL, getFileName } from 'utils/DownloadFIle';
import { ModalImportDataInput } from './components/ModalImportDataInput';
import { ModalDataImportNotification } from './components/ModalDataImportNotification';
import { ModalImportDataHistory } from './components/ModalImportDataHistory';
import { isImportingData } from './utils/fileImport';
import { RULE_DATA_TYPE_DATE_TIME } from 'constant/RuleConstant';
import { ModalAssociateFunction } from './components/ModalAssociateFunction';
import { SUBJECT_PROCEDURE_ALLOW_ACTIONS } from 'constant/SubjectConstant';
import { ModalTableAnalytic } from 'pages/SystemTenant/AROKMS/TableAnalytics/ModalTableAnalytic';
import { FormTableAnalyticParameter } from 'components/ButtonComponent/ButtonTableAnalytic';
import { ModalPreviewAttachment } from './components/ModalAttachmentContent/ModalPreviewAttachment';
import { Box } from '@mui/material';
import { AnimatedPage } from 'components/AnimatedPage';
import { getPageAnimationByRefNavigation } from 'utils/Animation';
import { NAVIGATION_COMPONENT_TYPE } from 'constant/NavigationConstant';
import { useGetBreadcumbItems } from 'services/v1/Common/NavBarMenuService';

const myCustomLocale = {
  ...enUS,
  dataGrid: {
    ...enUS.dataGrid,
    filterOperatorDropdownFilter: 'contains',
  },
};

interface OptionItem {
  value: string | number;
  label: string;
}

const panelStyle = {
  transform: 'translate3d(704px, 140.667px, 0px) !important',

  '& .MuiDataGrid-panelFooter': {
    display: 'none',
  },
};

const modalDeleteStateInitial: ModalDeleteState = {
  message: '',
  isError: false,
  errorMessage: null,
  open: false,
};

const initialModalAnalyticState = {
  open: false,
  rowTableDefinitionId: null,
  columnTableDefinitiId: null,
  dataTableDefinitionId: null,
};

export default function DataInputPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const authReducer = useSelector((state: RootState) => state.auth);
  const { subjectId } = useParams<{ subjectId: string }>();
  const { data: subjectDetails } = useGetSubjectDetails(subjectId);
  const existingSearchParams = qs.parse(location.search);

  const navigationRef = searchParams.get('ref');
  const procedureId = searchParams.get('procedureId') || undefined;
  const { data: breadCrumbItems } = useGetBreadcumbItems(
    procedureId ? procedureId : subjectId,
    procedureId ? NAVIGATION_COMPONENT_TYPE.SUBJECT_PROCEDURE : NAVIGATION_COMPONENT_TYPE.SUBJECT
  );
  const { data: procedureDetails } = useSubjectProcedureDetails(procedureId);

  const [showModalImportNotification, setShowModalImportNotification] = useState<boolean>(false);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [showCustomRuleModal, setShowCustomRuleModal] = useState<boolean>(false);
  const [showModalImportHistory, setShowModalImportHistory] = useState<boolean>(false);
  const [showModalAssociateFunction, setShowModalAssociateFunction] = useState<boolean>(false);
  const [importActivityStatus, setImportActivityStatus] = useState<DataInputImportActivityStatus | null>(null);
  const [showModalTableAnalytic, setShowModalTableAnalytic] = useState<{
    open: boolean;
    parameterValue?: FormTableAnalyticParameter;
  }>(initialModalAnalyticState);
  const [modalImportData, setModalImportData] = useState<ModalDataInputState | null>(null);
  const [modalAttachment, setModalAttachment] = useState<{
    visible: boolean;
    objectIds: string[];
    fieldLabel: string;
  }>({
    visible: false,
    objectIds: [],
    fieldLabel: '',
  });
  const [rowData, setRowData] = useState<DynamicDataItem[]>([]);
  const [selectedSubjectOption, setSelectedSubjectOption] = useState<AutoCompleteItem>({ label: '', value: '' });
  const [initialOption] = useState<OptionItem | null>(null);
  const [modalDeleteState, setModalDeleteState] = useState<ModalDeleteState>(modalDeleteStateInitial);
  const [filter, setFilter] = useState<DisplayTableParams>({
    page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
    size: searchParams.get('size') ? Number(searchParams.get('size')) : 20,
    filterValue: '',
    sortBy: undefined,
    sortType: 'asc',
    filterOperator: 'contains',
    filterColumn: undefined,
    subjectId: undefined,
  });
  const [snackbar, setSnackbar] = useState<Pick<AlertProps, 'children' | 'severity'> | null>(null);
  const [muiDataTableKey, setMuiDataTableKey] = useState<number>(0);
  const [selectedDataID, setSelectedDataID] = useState<GridRowId[]>([]);
  const {
    data: dataRecord,
    isLoading: isLoadingTable,
    refetch: refetchDataRecord,
    isError,
    error,
  } = useDisplayTable({ subjectId, procedureId });
  const { data: importActivity } = useGetCurrentImportActivity(subjectId, {
    refetchInterval: isImportingData(importActivityStatus) ? 5000 : false,
  });

  const { refetch: refetchImportHistory } = useGetDataInputImportHistory(subjectId);

  const { mutate: downloadImportTemplate } = useDataInputDownloadImportTemplate();

  const { mutate: upsertData } = useUpsertDisplayTable({ subjectId });
  const { mutate: deleteData } = useDeleteDisplayData({ subjectId });
  const { mutate: editData } = useUpdateDisplayData({ subjectId });

  const isDataEditable = useMemo(() => {
    if (subjectDetails?.data?.type === SUBJECT_TYPE.VIEW) return false;
    return checkAccess(
      [ROLES.BUILDER, ROLES.ADMIN, ROLES.TESTER, ROLES.SUPPORT, ROLES.PACKAGER],
      authReducer.threadUserAsRole
    );
  }, [authReducer.threadUserAsRole, subjectDetails]);

  const isViewOnly =
    authReducer.dashboardViewMode === DASHBOARD_VIEW_MODE.VIEW_ONLY ||
    selectedSubjectOption?.type === SUBJECT_TYPE.VIEW;

  const isEditOnly = useMemo(() => {
    return procedureDetails?.data?.allowAction === SUBJECT_PROCEDURE_ALLOW_ACTIONS.UPDATE_ONLY.toString();
  }, [procedureDetails?.data]);

  const columns = useMemo<GridColDef[]>(() => {
    if (dataRecord?.data?.columns) {
      let dynamicColumns = dataRecord?.data?.columns.map((column) => {
        const dataTypeCode = RULE_DATA_TYPE_DATE_TIME.has(column.ruleTypeCode) ? 'TYPE_DATE' : column.dataTypeCode;
        const columnCellColourData = dataRecord?.data?.cellColor?.[column.field];
        const ruleImplementation = RulesImplementation(column, convertRuleTypeParamsToSnakeCase(column.ruleTypeParams));
        return {
          editable: true,
          field: column.field,
          type: getColumnDataType(dataTypeCode),
          headerName: column.headerName,
          width: 150,
          align: 'left',
          headerAlign: 'left',

          renderEditCell: (params) =>
            renderTextInputEditCell({
              ...params,
              type: getColumnDataType(column.dataTypeCode),
              isRequired: !column.allowEmpty,
            }),

          ...ruleImplementation,

          renderCell: (params: GridRenderCellParams) => {
            if (!ruleImplementation?.renderCell) return undefined;
            if (columnCellColourData) {
              const cellValue = typeof params?.value === 'object' ? params?.value?.label : params.value;
              const cellColor = columnCellColourData?.[cellValue] || null;
              if (cellColor) {
                return (
                  <Box
                    sx={{
                      backgroundColor: cellColor,
                      width: '100%',
                      height: '100%',
                      alignItems: 'center',
                      display: 'flex',
                    }}
                  >
                    {ruleImplementation?.renderCell(params)}
                  </Box>
                );
              }
            }
            return ruleImplementation?.renderCell(params);
          },

          filterOperators: getFilterOperatorByRuleTypeCode(column.ruleTypeCode),
        };
      });

      if (!isDataEditable) {
        dynamicColumns = dynamicColumns.map((column) => {
          return {
            ...column,
            editable: false,
          };
        });
      }

      if (dataRecord.data.layoutView === LAYOUT_TABLE_VIEW_TYPE.DEDICATED_PAGE_VIEW && isDataEditable) {
        return [
          {
            field: 'mui_action',
            headerName: 'Edit',
            editable: false,
            width: 30,
            renderCell: (params: GridRowParams) => (
              <SystemStatusBasedAccessProvider>
                <ButtonRecordEdit
                  {...params}
                  subjectId={subjectId}
                  onClick={() => {
                    navigate(
                      `${PATH_CONSTANT.TENANT.KMS.DATA_INPUT_DEDICATED_PAGE_EDIT.replace(
                        ':subjectId',
                        subjectId
                      ).replace(':recordId', params.id as string)}?refBackParam=${encodeURIComponent(
                        window.location.pathname + window.location.search
                      )}&${qs.stringify({
                        procedureId,
                      })}`
                    );
                  }}
                />
              </SystemStatusBasedAccessProvider>
            ),
            filterable: false,
          },
          ...dynamicColumns,
        ];
      }

      return dynamicColumns;
    }

    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataRecord, selectedSubjectOption, isDataEditable, importActivityStatus, procedureId]);

  const isDedicatedPageLayout = useMemo(() => {
    if (dataRecord?.data?.layoutView === LAYOUT_TABLE_VIEW_TYPE.DEDICATED_PAGE_VIEW) {
      return true;
    }
    return false;
  }, [dataRecord]);

  const breadCrumbs = useMemo(() => {
    return generateBreadCumbItemsV2(breadCrumbItems?.data);
  }, [breadCrumbItems?.data]);

  const isAnyRowEdit = useMemo(() => {
    return Object.values(rowModesModel).some((item) => item.mode === GridRowModes.Edit);
  }, [rowModesModel]);

  const DataMutationSuccessHandlerOption = {
    onSuccess: () => {
      refetchDataRecord();
      setRowModesModel({});
    },
    onError: (error: AxiosDefaultErrorEntity) => {
      setSnackbar({ children: getErrorMessage(error), severity: 'error' });
      setRowModesModel({});
      refetchDataRecord();
    },
  };

  const handleOnFilter = (params: GridFilterModel) => {
    let filterObj = {};
    if (params.items.length) {
      filterObj = {
        filterValue: params.items[0].value,
        filterOperator: params.items[0].operatorValue,
        filterColumn: params.items[0].columnField,
      };
    } else {
      filterObj = {
        filterValue: '',
        filterOperator: 'contains',
      };
    }
    setFilter({
      ...filter,
      ...filterObj,
    });
  };

  const handleCloseSnackbar = () => setSnackbar(null);

  const handleRowEditStop = (params: GridRowEditStopParams) => {
    setRowModesModel({ ...rowModesModel, [params.id]: { mode: GridRowModes.View } });
  };

  const handleEditRowModes = (params: GridRowParams) => {
    return;

    // eslint-disable-next-line no-unreachable
    if (isViewOnly || isAnyRowEdit) return;

    setRowModesModel({ ...rowModesModel, [params.id]: { mode: GridRowModes.Edit } });
  };

  const handleOnClickDeleteButton = () => {
    setModalDeleteState({
      ...modalDeleteState,
      open: true,
      message: `Are you sure you want to delete ${selectedDataID.length} data?`,
    });
  };

  const handleOnSelectionModelChange = (selectedId: GridSelectionModel) => {
    setSelectedDataID(selectedId);
  };

  const handleOnDeleteData = () => {
    if (selectedDataID.length === 0) return;
    deleteData(
      { subjectId, ids: selectedDataID },
      {
        onSuccess: () => {
          setMuiDataTableKey(muiDataTableKey + 1);
          setSelectedDataID([]);
          setModalDeleteState({ open: false, message: '', isError: false, errorMessage: null });
        },

        onError: (error: AxiosDefaultErrorEntity) => {
          setModalDeleteState({
            open: true,
            message: '',
            isError: true,
            errorMessage: getErrorMessage(error),
          });
        },
      }
    );
  };

  const handleCancelInsert = (id: number) => {
    const newRows = rowData.filter((item) => item.id !== id);
    setRowData(newRows);
    setRowModesModel((prev) => {
      const newModel = _.cloneDeep(prev);
      delete newModel[id];
      return newModel;
    });
  };

  const handleProcessRowUpdate = (data: DynamicDataItem, oldData: DynamicDataItem) => {
    // If old row data is equal to new row data, then do nothing
    // (no need to update just return the data, because nothing changed)
    const { isNew, ...payloadData } = data;

    if (_.isEqual(convertObjectToValue(payloadData, 'label'), oldData)) return oldData;
    // otherwise, update the row data
    else {
      // check is empty from data and check to column whether is nullable
      const isDataEmpty = Object.keys(_.omit(data, ['id', 'isNew'])).every((key) => {
        return !data[key];
      });

      const omitData = _.omit(data, ['id', 'isNew']);
      const omitDataKeys = Object.keys(omitData);

      let emptyNotAllowedKeys = [];

      omitDataKeys.forEach((key) => {
        const column = dataRecord?.data.columns.find((item) => item.field === key);

        if (column) {
          const dataEmpty = data[key] === undefined || data[key] === null || data[key] === '';

          if ((column.allowEmpty === true || column.readOnlyColumn === true) && dataEmpty) {
            // Not a problem, continue to next key.
          } else if (column.nullable === false && dataEmpty) {
            emptyNotAllowedKeys.push(key); // This is a problem.
          }
        }
      });

      const isAnyDataEmpty = emptyNotAllowedKeys.length > 0;

      const dataWithRuleTypeCode = _.mapValues(payloadData, (value, key) => {
        const column = dataRecord?.data.columns.find((item) => item.field === key);
        if (column) {
          return {
            value,
            ruleTypeCode: column.ruleTypeCode,
            dropdownOptions: column.dropdownOptions,
            readOnlyColumn: column.readOnlyColumn,
          };
        }
        return { value };
      });

      const tableDefinitionIdFromColumn = _.mapValues(_.omit(payloadData, ['id', 'isNew']), (value, key) => {
        const column = dataRecord?.data.columns.find((item) => item.field === key);
        if (column) {
          return column.tableDefinitionId;
        }
      });

      if (isDataEmpty) return handleCancelInsert(data.id);

      if (!isDataEmpty && isAnyDataEmpty) return;

      if (isNew) {
        // if the row is new and not empy, then insert the row to database
        if (!isDataEmpty) {
          const insertPayload = _.omit(serializeDataInputPayload(dataWithRuleTypeCode, authReducer), ['id']);

          upsertData(
            { data: insertPayload, tableDefinitionId: tableDefinitionIdFromColumn },
            DataMutationSuccessHandlerOption
          );

          return { ...data, isNew: false };
        }
      } else {
        const updatePayload = serializeDataInputPayload(dataWithRuleTypeCode, authReducer);
        editData(
          { data: updatePayload, tableDefinitionId: tableDefinitionIdFromColumn },
          DataMutationSuccessHandlerOption
        );
        return data;
      }
    }
  };

  const handleChangePage = (newPage: number) => {
    setFilter({ ...filter, page: newPage + 1 });
    navigate({
      search: qs.stringify({
        ...existingSearchParams,
        ...filter,
        procedureId,
        page: newPage + 1,
      }),
    });
  };

  const handleChangePageSize = (newPageSize: number) => {
    setFilter({ ...filter, size: newPageSize });
    navigate({
      search: qs.stringify({
        ...filter,
        size: newPageSize,
        page: 1,
      }),
    });
  };
  const handleOnOptionClick = (type: DataInputTableOptions) => {
    if (type === DataInputTableOptions.CUSTOM_RULES) {
      setShowCustomRuleModal(true);
    }
    if (type === DataInputTableOptions.IMPORT_DATA_HISTORY) {
      setShowModalImportHistory(true);
    }

    if (type === DataInputTableOptions.FUNCTION_TRIGGER) {
      setShowModalAssociateFunction(true);
    }
  };

  useEffect(() => {
    if (dataRecord?.data?.data) {
      setRowData(dataRecord?.data?.data);
    }
  }, [dataRecord?.data]);
  useEffect(() => {
    if (isError) setRowData([]);
  }, [isError]);

  useEffect(() => {
    if (subjectId) {
      setMuiDataTableKey(muiDataTableKey + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subjectId]);

  useEffect(() => {
    if (subjectDetails?.data) {
      const option = {
        label: subjectDetails?.data?.displayName,
        value: subjectDetails?.data?.id,
      };
      setSelectedSubjectOption(option);
      if (!procedureId) {
        dispatch(
          setHomeNavigationState({
            subject: option,
            event: null,
            cube: null,
            ktree: null,
            view: null,
            'subject-procedure': null,
          })
        );
      } else {
        dispatch(
          setHomeNavigationState({
            subject: option,
            event: null,
            cube: null,
            ktree: null,
            view: null,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subjectDetails?.data, procedureId]);

  useEffect(() => {
    if (isImportingData(importActivity?.data?.status)) {
      setImportActivityStatus(DataInputImportActivityStatus.ON_PROGRESS);
      refetchDataRecord();
    } else {
      setImportActivityStatus(null);
      refetchImportHistory();
      refetchDataRecord();
    }
    refetchDataRecord();
  }, [importActivity?.data?.status, refetchDataRecord, refetchImportHistory]);

  const insertButtonText = useMemo(() => {
    if (procedureDetails?.data?.procedureName) return `Add ${procedureDetails?.data?.procedureName}`;
    if (!subjectDetails?.data?.subject) return 'Add New';
    return `Add New ${subjectDetails?.data?.displayName}`;
  }, [subjectDetails?.data, procedureDetails?.data]);

  const handleOnAddDataClick = () => {
    navigate(
      `${PATH_CONSTANT.TENANT.KMS.DATA_INPUT_DEDICATED_PAGE_INSERT.replace(':subjectId', subjectId)}?${qs.stringify({
        subjectId,
        procedureId: procedureDetails?.data?.id || undefined,
      })}`
    );
  };

  const handleOnTemplateClick = (selectedFileType: string) => {
    downloadImportTemplate(
      { subjectId, fileType: selectedFileType },
      {
        onSuccess: (response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));

          downloadFileFromURL(
            url,
            `ImportTemplate_${getFileName(subjectDetails?.data?.displayName, selectedFileType)}`
          );
        },
      }
    );
  };

  const handleOnDisplayTableAnalytic = (data: FormTableAnalyticParameter) => {
    setShowModalTableAnalytic({
      open: true,
      parameterValue: data,
    });
  };

  const handleOnImportOptionClick = (selectedFileType: string) => {
    setModalImportData({ fileType: selectedFileType, open: true });
  };
  const handleOnImportModalClose = () => {
    setModalImportData(null);
  };

  useEffect(() => {
    if (
      isDataEditable &&
      (importActivity?.data?.status === DataInputImportActivityStatus.FAILED ||
        importActivity?.data?.status === DataInputImportActivityStatus.COMPLETED)
    ) {
      setShowModalImportNotification(true);
    }
  }, [importActivity?.data, isDataEditable]);

  useEffect(() => {
    const handleOnShowAttachmentClick = (data: { visible: boolean; url: string; fieldLabel: string }) => {
      setModalAttachment(data);
    };
    eventEmitter.on(EVENT_EMITTER_KEYS.TRIGGER_SHOW_MODAL_ATTACHMENT, handleOnShowAttachmentClick);

    return () => {
      eventEmitter.off(EVENT_EMITTER_KEYS.TRIGGER_SHOW_MODAL_ATTACHMENT, handleOnShowAttachmentClick);
    };
  }, []);

  return (
    <TenantHomeDashboardNavigationBar error={error} breadCrumbs={breadCrumbs}>
      <ModalDataImportNotification
        importActivity={importActivity?.data}
        onClose={() => setShowModalImportNotification(false)}
        visible={showModalImportNotification}
        subjectDisplayName={subjectDetails?.data?.displayName}
        subjectId={subjectId}
      />
      <AnimatedPage key={subjectId || 'default-subject'} variants={getPageAnimationByRefNavigation(navigationRef)}>
        <CustomDataTable
          localeText={myCustomLocale.dataGrid}
          key={`custom-data-table-${muiDataTableKey}`}
          initialState={{
            pinnedColumns: {
              left: ['mui_action'],
            },
            pagination: {
              page: 0,
            },
          }}
          isError={isError}
          errorMessage={error && getErrorMessage(error)}
          errorTitle={getErrorTitle(error)}
          loading={isLoadingTable || importActivityStatus === DataInputImportActivityStatus.ON_PROGRESS}
          rows={rowData}
          columns={columns}
          rowCount={dataRecord?.data.rowCount ?? 0}
          page={filter.page - 1}
          pageSize={filter.size}
          getRowId={(row) => row?.id || 0}
          onPageChange={handleChangePage}
          checkboxSelection={false}
          onPageSizeChange={handleChangePageSize}
          rowModesModel={rowModesModel}
          disableSelectionOnClick
          rowsPerPageOptions={[5, 10, 20]}
          onSelectionModelChange={handleOnSelectionModelChange}
          processRowUpdate={handleProcessRowUpdate}
          onProcessRowUpdateError={(error) => {
            console.log(error, rowData, rowModesModel);
          }}
          onRowEditStop={handleRowEditStop}
          onRowDoubleClick={handleEditRowModes}
          getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
          experimentalFeatures={{ newEditingApi: true }}
          onFilterModelChange={handleOnFilter}
          paginationMode='client'
          editMode='row'
          sortingMode='client'
          filterMode='client'
          autoHeight={rowData.length !== 0 && rowData.length > 5}
          columnBuffer={2}
          columnThreshold={2}
          components={{
            Toolbar: DataInputSettingPanel,
          }}
          componentsProps={{
            panel: {
              sx: panelStyle,
            },
            toolbar: {
              // @ts-ignore
              viewOnly: isViewOnly || isEditOnly,
              withChipLabel: true,
              onOptionClick: handleOnOptionClick,
              disabled: isLoadingTable || rowData.some((item) => item.isNew) || isAnyRowEdit,
              isInserting: rowData.some((item) => item.isNew),
              setRows: setRowData,
              setRowModesModel,
              onDeleteData: handleOnClickDeleteButton,
              options: [],
              initialOption,
              optionValue: selectedSubjectOption,
              onAddDataClick: isDedicatedPageLayout ? () => handleOnAddDataClick() : undefined,
              onButtonTemplateClick: handleOnTemplateClick,
              onImport: handleOnImportOptionClick,
              onButtonTableAnalyticClick: handleOnDisplayTableAnalytic,
              config: {
                deleteButtonText: 'Delete',
                fieldFocusOnInsert: '',
                insertButtonText,
                insertInitialObject: {
                  ...dataRecord?.data.columnInitialValues,
                  isNew: true,
                },
              },
            },
          }}
        />
      </AnimatedPage>
      {!!snackbar && (
        <Snackbar
          open
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={3000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}

      <ModalTableAnalytic
        open={showModalTableAnalytic.open}
        onClose={() => setShowModalTableAnalytic(initialModalAnalyticState)}
        subjectId={subjectId}
        parameterValue={showModalTableAnalytic.parameterValue}
      />

      <ModalImportDataInput
        visible={modalImportData?.open}
        subject={subjectDetails?.data}
        onClose={handleOnImportModalClose}
        onCancel={handleOnImportModalClose}
        subjectType='SUBJECT'
        onImportSuccess={handleOnImportModalClose}
      />
      <ModalImportDataHistory
        visible={showModalImportHistory}
        onClose={() => setShowModalImportHistory(false)}
        subjectId={subjectId}
      />

      <ModalAssociateFunction
        visible={showModalAssociateFunction}
        onClose={() => setShowModalAssociateFunction(false)}
        subjectId={subjectId}
      />
      <ModalDeleteComponent
        visible={modalDeleteState.open}
        message={modalDeleteState.message}
        isError={modalDeleteState.isError}
        errorMessage={modalDeleteState.errorMessage}
        onApprove={handleOnDeleteData}
        onClose={() => setModalDeleteState(modalDeleteStateInitial)}
        onCancel={() => setModalDeleteState(modalDeleteStateInitial)}
      />
      <ModalDataInputCustomRule
        visible={showCustomRuleModal}
        subjectId={subjectId}
        onClose={() => setShowCustomRuleModal(false)}
      />
      <ModalPreviewAttachment
        fieldLabel={modalAttachment.fieldLabel}
        visible={modalAttachment.visible}
        subjectId={subjectId}
        initialObjectIds={modalAttachment.objectIds}
        onClose={() => {
          setModalAttachment({ visible: false, url: '' });
        }}
      />
    </TenantHomeDashboardNavigationBar>
  );
}
