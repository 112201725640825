export const PATH_CONSTANT = {
  AUTH: {
    LOGIN: '/auth/login',
    SELECT_ROLE: '/auth/select-role',
    INITIAL_SETUP: '/auth/initial-setup',
  },
  SYSTEM: {
    KMS: {
      DATA_BUILDER: {
        SUBJECT: '/kms/data-builder/subject',
        ATTRIBUTE: '/kms/data-builder/attribute',
        TABLE_DEFINITION: '/kms/data-builder/table-definition',
      },
      DATA_EVENT: {
        EVENT_DEFINTION: '/aro-event/event-definition',
      },
      KTREE_DEFINITION: '/kms/k-tree/definition',
      SUBJECT_PROCESS: '/kms/subject-process',
      SOLUTION_DEFINITION: '/kms/solution-definition',
      SUBJECT_PROCEDURE: '/kms/subject-procedure',
    },
    CUBE: {
      CUBE_DEFINITION: '/cube/definition',
      CUBE_DEFINITION_CREATE: '/cube/definition/:subjectId/create',
      CUBE_DEFINITION_EDIT: '/cube/definition/:subjectId/:cubeId/edit',
      CUBE_LAYER: '/cube/layer/list',
      CUBE_LAYER_SET: '/cube/layer-set/list',
      TIME_PERIOD_SET: '/cube/time-period-set/list',
    },
    FUNCTION: {
      FUNCTION_DEFINITION: '/aro-function/definition',
      CREATE_FUNCTION_DEFINITION: '/aro-function/definition/create',
      EDIT_FUNCTION_DEFINITION: '/aro-function/definition/:functionId/edit',
    },
    VIEW: {
      STANDARD_VIEW_DEFINITION: '/aro-view/standard-view-definition',
      CREATE_STANDARD_VIEW_DEFINITION: '/aro-view/standard-view-definition/create',
      EDIT_STANDARD_VIEW_DEFINITION: '/aro-view/standard-view-definition/:viewId/edit',
      INSIGHT_VIEW_DEFINITION: '/aro-view/insight-view-definition',
      CREATE_INSIGHT_VIEW_DEFINITION: '/aro-view/insight-view-definition/create',
      EDIT_INSIGHT_VIEW_DEFINITION: '/aro-view/insight-view-definition/:insightViewId/edit',
    },
  },
  TENANT: {
    HOME: '/home',
    KMS: {
      DATA_INPUT: '/data-model/data-input/:subjectId',
      DATA_INPUT_DEDICATED_PAGE_INSERT: '/data-model/data-input/insert/:subjectId',
      DATA_INPUT_DEDICATED_PAGE_EDIT: '/data-model/data-input/edit/:subjectId/:recordId',
      KTREE_DATA: '/ktree/view/:kTreeId',
      MEMBER_LIST: '/data-builder/member-list',
    },
    CUBE: {
      CUBE: '/data-model/cube/:cubeId',
      CUBE_DIMENSIONAL_VIEW: '/cdv/:cubeId',
      INSIGHT: '/data-model/cube/:cubeId/insight/:insightId',
    },
    CONFIGURATION: {
      DATA_MIGRATION: '/configuration/data-migration',
      CONFIGURATION: '/configuration',
    },
    EVENT: {
      EVENT: '/event/:subjectId',
      ADD_EVENT: '/event/insert/:subjectId',
      EDIT_EVENT: '/event/edit/:subjectId/:recordId',
    },
    VIEW: {
      STANDARD_VIEW: '/standard-view/:viewId',
    },
    DASHBOARD: {
      DASHBOARD: '/dashboard/:dashboardId',
    },
  },
  TENANT_MASTER: {
    HOME: '/admin/home',
    MASTER_TABLE: {
      SYSTEM: '/master-table/system',
      ADD_SYSTEM: '/master-table/system/insert',
      EDIT_SYSTEM: '/master-table/system/edit/:id',
      SERVER: '/master-table/server',
      ADD_SERVER: '/master-table/server/insert',
      EDIT_SERVER: '/master-table/server/edit/:id',
      TENANT: '/master-table/tenant',
      ADD_TENANT: '/master-table/tenant/insert',
      EDIT_TENANT: '/master-table/tenant/edit/:id',
      PERSON: '/master-table/person',
      ADD_PERSON: '/master-table/person/insert',
      EDIT_PERSON: '/master-table/edit/:id',
    },
    DISPLAY_TABLE: '/display-table',
    EDIT_DISPLAY_TABLE: '/display-table/edit/:id',
    DATAWAREHOUSE: {
      LIST: '/datawarehouse',
      ADD: '/datawarehouse/insert',
      EDIT: '/datawarehouse/edit/:id',
      CHANNEL: '/datawarehouse/:dataSourceId/channel',
    },
  },
};
