// @ts-nocheck
import _ from 'lodash';
import qs from 'query-string';
import { toast } from 'react-toastify';
import { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import { produce } from 'immer';
import { DataGridProProps, GRID_TREE_DATA_GROUPING_FIELD } from '@mui/x-data-grid-pro';
import { CustomDataTable } from 'components/DatatableComponent';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import LinearProgress from '@mui/material/LinearProgress';
import CubeDimensionalViewModal, { CubeDimensionalViewPageProps } from '../CubeDimensionalViewPage';
import { Alert, Box, Stack, Typography } from '@mui/material';
import { RootState } from 'store';
import {
  CubeDataEntryPanelOptionValues,
  CubeDataEntrySettingPanel,
  CubeDataEntryShowDataDetails,
} from './components/CubeDataEntryPanel';
import {
  useGetCubeDataEntry,
  useGetCubeDataEntryBaseValue,
  useGetCubeDataEntryInitialValue,
  useSyncCubeData,
  useUpsertCubeDataEntryBase,
  useUpsertCubeDataEntryInitial,
  useUpsertCubeDataentry,
} from 'services/v1/Tenant/AROCube/CubeDataEntryService';
import { CubeDataEntryAttribute, RequestUpsertCubeDataEntry } from 'types/api/Tenant/AROCube/CubeDataEntryTypes';
import ActivityIndicator from 'components/ActivityIndicatorComponent';
import { getErrorMessage, getErrorTitle } from 'utils/Error';
import { CUBE_ACTIVITY_STATUS, CUBE_LAYER_TYPE, CUBE_PERCENTAGE_TYPE, CUBE_SYNC_STATUS } from 'constant/CubeConstant';
import { RULES_CONSTANT } from 'constant/RuleConstant';
import { ModalLeavePageConfirmationComponent } from 'components/ModalComponent/ModalLeavePageConfirmationComponent';
import { formatCubeNumber } from 'utils/NumberUtils';
import {
  getCellClassName,
  jsonToGridData,
  jsonToGridDataSpecialEntry,
  transformData,
  transformDataSpecialEntry,
} from './utils/CubeDataEntryUtils';
import { CustomGridTreeDataGroupingCell } from './components/CustomGridTreeDataGroupingCell';
import {
  generateBreadCumbItemsV2,
  TenantHomeDashboardNavigationBar,
} from 'components/LayoutComponent/VerticalLayout/HeaderBar';
import { useGetCubeDefinitionDetails } from 'services/v1/SystemTenant/AROCube/CubeBuilderService';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ModalInfoComponent, { ModalInfoProps } from 'components/ModalComponent/ModalInfoComponent';
import { dayjs } from 'utils/DateUtils';
import { ENV_CONFIG } from 'config/env';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { ROLES } from 'constant/PermissonConstant';
import { checkAccess } from 'routes/PrivateRoute';
import { useGetBreadcumbItems } from 'services/v1/Common/NavBarMenuService';
import { NAVIGATION_COMPONENT_TYPE } from 'constant/NavigationConstant';
import { PATH_CONSTANT } from 'constant/PathConstant';
import { AnimatedPage } from 'components/AnimatedPage';
import { ModalCubeEventDataDetails } from './components/ModalEventData';
import { getPageAnimationByRefNavigation } from 'utils/Animation';

const TabStyle = {
  textTransform: 'none',
  '&.Mui-selected': {
    color: '#42BB93',
  },
};
const TabIndicatoryStyle = {
  style: {
    backgroundColor: '#42BB93',
  },
};

const moreDetailsTextActionStyle = {
  color: '#42BB93',
  cursor: 'pointer',
  textDecoration: 'underline',
};

const CUBE_DATA_TABS = {
  CUBE_ENTRY: 'CUBE_ENTRY',
  INITIAL_VALUE: 'INITIAL_VALUE',
  BASE_VALUE: 'BASE_VALUE',
};

const initialStateModalDetails = {
  timePeriod: null,
  cubeMember: null,
  eventSubjectId: null,
  eventSubjectName: null,
  open: false,
};

export default function CubePage() {
  const cubeSettingPanelRef = useRef<{
    isLoadingOptionsData: boolean;
  }>(null);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const navigationRef = searchParams.get('ref');
  const locations = useLocation();
  const existingSearchParams = qs.parse(locations.search);
  const { cubeId } = useParams<{ cubeId: string }>();
  const { auth: authReducer } = useSelector((state: RootState) => state);
  const [cubeActivityStatus, setCubeActivityStatus] = useState<string | null>(null);
  const { data: cubeDefinitionDetails, error: errorCubeDefinitionDetails } = useGetCubeDefinitionDetails(cubeId, {
    refetchInterval:
      cubeActivityStatus === CUBE_ACTIVITY_STATUS.CALCULATING ||
      cubeActivityStatus === CUBE_ACTIVITY_STATUS.RECALCULATING
        ? 3000
        : false,
  });

  const [modalInfo, setModalInfo] = useState<ModalInfoProps>({
    title: '',
    descriptionMessage: '',
    visible: false,
  });
  const [rowData, setRowData] = useState<any>([]);
  const [initialData, setInitialData] = useState<any>([]);
  const [optionValue, setOptionValue] = useState<{
    subjectId: string | undefined;
    cubeId: string | undefined;
    layerId: string | undefined;
    memberId: string | undefined;
    xMemberId: string | undefined;
  }>({
    subjectId: undefined,
    cubeId: cubeId,
    layerId: undefined,
    memberId: undefined,
    xMemberId: undefined,
  });

  const [modalDataDetails, setModalDataDetails] = useState<{
    open: boolean;
    eventSubjectId: string | null;
    eventSubjectName: string | null;
    cubeMember: string | null;
    timePeriod: string | null;
  }>(initialStateModalDetails);
  const [cubeDimensionalViewModalState, setCubeDimensionalViewModalState] = useState<CubeDimensionalViewPageProps>({
    cubeId: undefined,
    open: false,
  });

  const [selectedTab, setSelectedTab] = useState(CUBE_DATA_TABS.CUBE_ENTRY);

  const [isLoadingOptionsData, setIsLoadingOptionsData] = useState(false);

  const isAnyUnsaved = useMemo(() => {
    return !_.isEqual(rowData, initialData);
  }, [rowData, initialData]);

  const isDataEditable = useMemo(() => {
    return checkAccess(
      [ROLES.BUILDER, ROLES.ADMIN, ROLES.TESTER, ROLES.SUPPORT, ROLES.PACKAGER],
      authReducer.threadUserAsRole
    );
  }, [authReducer.threadUserAsRole]);

  const {
    data: cubeEntryData,
    isLoading: isLoadingDataEntry,
    isError: isErrorCubeData,
    error: errorCubeData,
    refetch: refetchCubeData,
  } = useGetCubeDataEntry(optionValue.cubeId, optionValue.layerId, optionValue.memberId, optionValue.xMemberId);

  const {
    data: cubeEntryDataBaseValue,
    isLoading: isLoadingDataBaseValue,
    error: errorCubeDataBaseValue,
  } = useGetCubeDataEntryBaseValue(cubeId, optionValue.memberId, optionValue.xMemberId);

  const {
    data: cubeEntryDataInitialValue,
    isLoading: isLoadingDataInitialValue,
    error: errorCubeDataInitialValue,
  } = useGetCubeDataEntryInitialValue(cubeId, optionValue.memberId, optionValue.xMemberId);

  const { mutate: saveCubeDataEntry, isLoading: isSubmitting } = useUpsertCubeDataentry();
  const { mutate: saveCubeDataEntryBaseValue, isLoading: isSubmittingBaseValue } = useUpsertCubeDataEntryBase();
  const { mutate: saveCubeDataEntryInitialValue, isLoading: isSubmittingInitialValue } =
    useUpsertCubeDataEntryInitial();

  const { data: breadCrumbItems } = useGetBreadcumbItems(cubeId, NAVIGATION_COMPONENT_TYPE.CUBE);

  const { mutate: syncCubeData } = useSyncCubeData();

  const error = errorCubeData || errorCubeDataBaseValue || errorCubeDataInitialValue || errorCubeDefinitionDetails;

  const percentageRenderAttributes = useMemo(() => {
    if (cubeEntryData?.data) {
      return cubeEntryData?.data.attributes
        .filter((attr) => attr.percentage === CUBE_PERCENTAGE_TYPE.PERCENTAGE)
        .map((attr) => attr.name);
    }
    return [];
  }, [cubeEntryData?.data]);

  const decipalPlacesRenderAttributes: { [key: string]: number } = useMemo(() => {
    if (cubeEntryData?.data) {
      return cubeEntryData?.data.attributes.reduce((acc, attr) => {
        acc[attr.name] = attr.decimal;
        return acc;
      }, {} as { [key: string]: number });
    }
    return {};
  }, [cubeEntryData?.data]);

  const inputInitialAttributes: string[] = useMemo(() => {
    if (cubeEntryData?.data) {
      return cubeEntryData.data.attributes
        .filter((attrItem) => attrItem.ruleTypeCode === RULES_CONSTANT.CUBE.RULE_CUBE_INPUT_INITIAL)
        .map((attribute) => attribute.name);
    }
    return [];
  }, [cubeEntryData?.data]);

  const readOnlyAttributes: string[] = useMemo(() => {
    switch (selectedTab) {
      case CUBE_DATA_TABS.CUBE_ENTRY:
        return cubeEntryData?.data.attributes.filter((attribute) => !attribute.isEditable).map((attr) => attr.name);
      case CUBE_DATA_TABS.BASE_VALUE:
        return cubeEntryDataBaseValue?.data.attributes
          .filter((attribute) => !attribute.isEditable)
          .map((attr) => attr.name);
      case CUBE_DATA_TABS.INITIAL_VALUE:
        return cubeEntryDataInitialValue?.data.attributes
          .filter((attribute) => !attribute.isEditable)
          .map((attr) => attr.name);

      default:
        return [];
    }
  }, [cubeEntryData?.data, cubeEntryDataBaseValue?.data, cubeEntryDataInitialValue?.data, selectedTab]);

  const calculatedAttributes = useMemo(() => {
    switch (selectedTab) {
      case CUBE_DATA_TABS.CUBE_ENTRY:
        return cubeEntryData?.data.attributes.filter((attribute) => attribute.isCalculated).map((attr) => attr.name);
      case CUBE_DATA_TABS.BASE_VALUE:
        return cubeEntryDataBaseValue?.data.attributes
          .filter((attribute) => attribute.isCalculated)
          .map((attr) => attr.name);
      case CUBE_DATA_TABS.INITIAL_VALUE:
        return cubeEntryDataInitialValue?.data.attributes
          .filter((attribute) => attribute.isCalculated)
          .map((attr) => attr.name);
      default:
        return [];
    }
  }, [cubeEntryData?.data, cubeEntryDataBaseValue?.data, cubeEntryDataInitialValue?.data, selectedTab]);

  const pullFromEventAttributesMap = useMemo(() => {
    return new Map(
      cubeEntryData?.data.attributes
        .filter((attribute) => attribute.ruleTypeCode === RULES_CONSTANT.CUBE.RULE_CUBE_PULL_FROM_EVENT)
        .map((attr) => [attr.name, attr])
    );
  }, [cubeEntryData?.data.attributes]);

  const tabsMenu = useMemo(() => {
    if (cubeEntryData?.data) {
      if (cubeEntryData?.data.layer.layerType !== CUBE_LAYER_TYPE.BASELINE || cubeEntryData.data.consolidateView) {
        return [
          {
            value: CUBE_DATA_TABS.CUBE_ENTRY,
            label: 'Cube Entry',
          },
        ];
      }
      return [
        {
          value: CUBE_DATA_TABS.CUBE_ENTRY,
          label: 'Cube Entry',
        },
        {
          value: CUBE_DATA_TABS.BASE_VALUE,
          label: `Base Value (${cubeEntryData?.data?.baseAttributes.length})`,
        },
        {
          value: CUBE_DATA_TABS.INITIAL_VALUE,
          label: `Initial Value (${cubeEntryData?.data?.initialAttributes.length})`,
        },
      ];
    }
    return [];
  }, [cubeEntryData?.data]);

  const columns = useMemo(() => {
    if (cubeEntryData?.data && selectedTab === CUBE_DATA_TABS.CUBE_ENTRY) {
      return [
        ...Object.keys(cubeEntryData?.data.data).map((timePeriod) => ({
          field: timePeriod,
          headerName: timePeriod,
          type: 'number',
          editable: true,
          minWidth: 120,
          headerClassName: 'custom-cube-tree-data-header',
          cellClassName: (params) => getCellClassName(params, initialData),
          renderCell: (params) => {
            const attributeName = params.id.split('#')[0];
            const isValueClickAble = pullFromEventAttributesMap.has(attributeName);
            const decimalPlaces = decipalPlacesRenderAttributes?.[attributeName] || 0;

            let value;
            if (percentageRenderAttributes?.includes(attributeName)) {
              value = params?.value && formatCubeNumber(params?.value, decimalPlaces, true);
            } else {
              value = params?.value && formatCubeNumber(params?.value, decimalPlaces);
            }

            if (isValueClickAble) {
              return (
                <span
                  style={{ color: 'blue', textDecoration: 'none', cursor: 'pointer' }}
                  onClick={(e) => {
                    e.preventDefault();
                    handleOnCellValueClick({
                      attributeName,
                      timePeriod,
                    });
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.textDecoration = 'underline';
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.textDecoration = 'none';
                  }}
                >
                  {value}
                </span>
              );
            }

            return value;
          },
        })),
      ];
    }
    if (!cubeEntryData?.data && CUBE_DATA_TABS.CUBE_ENTRY === selectedTab) return [];

    return [
      {
        field: 'Value',
        headerName: 'Value',
        type: 'number',
        editable: true,
        minWidth: 120,
        headerClassName: 'custom-cube-tree-data-header',
        cellClassName: (params) => getCellClassName(params, initialData),
        renderCell: (params) => {
          const attributeName = params.id.split('#')[0];
          const decimalPlaces = decipalPlacesRenderAttributes?.[attributeName] || 0;
          if (percentageRenderAttributes?.includes(attributeName)) {
            return params?.value && formatCubeNumber(params?.value, decimalPlaces, true);
          }
          return params?.value && formatCubeNumber(params?.value, decimalPlaces);
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cubeEntryData?.data,
    selectedTab,
    initialData,
    percentageRenderAttributes,
    decipalPlacesRenderAttributes,
    pullFromEventAttributesMap,
  ]);

  const firsTimePeriod = useMemo(() => {
    if (cubeEntryData?.data) {
      const timePeriod = cubeEntryData?.data?.data;
      return Object.keys(timePeriod)?.[0] || null;
    }
    return null;
  }, [cubeEntryData?.data]);
  useEffect(() => {
    if (selectedTab === CUBE_DATA_TABS.CUBE_ENTRY && cubeEntryData?.data) {
      setRowData(jsonToGridData(cubeEntryData?.data, false));
      setInitialData(jsonToGridData(cubeEntryData?.data, false));
    }
    if (selectedTab === CUBE_DATA_TABS.BASE_VALUE && cubeEntryDataBaseValue?.data) {
      setRowData(jsonToGridDataSpecialEntry(cubeEntryDataBaseValue?.data));
      setInitialData(jsonToGridDataSpecialEntry(cubeEntryDataBaseValue?.data));
    }
    if (selectedTab === CUBE_DATA_TABS.INITIAL_VALUE && cubeEntryDataInitialValue?.data) {
      setRowData(jsonToGridDataSpecialEntry(cubeEntryDataInitialValue?.data));
      setInitialData(jsonToGridDataSpecialEntry(cubeEntryDataInitialValue?.data));
    }
  }, [cubeEntryData?.data, selectedTab, cubeEntryDataBaseValue?.data, cubeEntryDataInitialValue?.data]);

  useEffect(() => {
    const handleUnload = (e) => {
      if (isAnyUnsaved) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, [isAnyUnsaved]);

  useEffect(() => {
    if (cubeDefinitionDetails?.data) {
      setCubeActivityStatus(cubeDefinitionDetails?.data?.cubeActivityStatus?.activityStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cubeDefinitionDetails?.data]);

  const breadCrumbs = useMemo(() => {
    return generateBreadCumbItemsV2(breadCrumbItems?.data);
  }, [breadCrumbItems?.data]);

  const groupingColDef: DataGridProProps['groupingColDef'] = {
    headerName: '',
    pinnable: true,
    filterable: true,
    leafField: 'name',
    minWidth: 200,
    maxWidth: 400,
    cellClassName: 'custom-cube-tree-data-grouping-cell',
    headerClassName: 'custom-cube-tree-data-header',
    renderCell: CustomGridTreeDataGroupingCell,
  };

  const handleOnOptionChange = (data: CubeDataEntryPanelOptionValues) => {
    setOptionValue({
      subjectId: data.subject?.value,
      cubeId: data.cube?.value,
      layerId: data.layer?.value,
      memberId: data.member?.value,
      xMemberId: data.xMember?.value,
    });
  };

  const handleOnTabChange = (event, newValue) => {
    if (!isAnyUnsaved || window.confirm('You have unsaved data. Are you sure you want to change tab?')) {
      setSelectedTab(newValue);
    }
  };

  const handleOnShowDataDetails = useCallback(
    (data: CubeDataEntryShowDataDetails) => {
      setModalDataDetails({
        eventSubjectName: data.eventSubjectName,
        eventSubjectId: data.eventSubjectId,
        cubeMember: data.cubeMember || optionValue.memberId,
        timePeriod: data.timePeriod,
        open: true,
      });
    },
    [optionValue.memberId]
  );

  const handleOnCellValueClick = useCallback(
    ({ attributeName, timePeriod }: { attributeName: string; timePeriod: string }) => {
      const cubeAttribute: CubeDataEntryAttribute | null = pullFromEventAttributesMap.get(attributeName);
      const attributeSubject = cubeDefinitionDetails?.data.attributes.find((attr) => attr.id === cubeAttribute?.id);
      if (attributeSubject && timePeriod) {
        handleOnShowDataDetails({
          timePeriod,
          cubeMember: cubeEntryData?.data.member?.value,
          eventSubjectId: attributeSubject.subjectEvent.value,
          eventSubjectName: attributeSubject.subjectEvent.label,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [optionValue.memberId, cubeDefinitionDetails?.data.attributes, pullFromEventAttributesMap]
  );

  const handleOnSaveData = () => {
    const payload: RequestUpsertCubeDataEntry = {
      cubeId: cubeId ?? '',
      layerId: optionValue.layerId ?? '',
      memberId: optionValue.memberId ?? '',
      xmemberId: optionValue.xMemberId ?? null,
      data:
        selectedTab === CUBE_DATA_TABS.CUBE_ENTRY
          ? transformData(rowData, cubeEntryData?.data?.data)
          : transformDataSpecialEntry(rowData),
    };

    const toastId = toast.loading('Saving data...');

    const onSuccess = () => {
      toast.update(toastId, { render: 'Saving data success!', type: 'success', isLoading: false, autoClose: 3000 });
    };

    const onError = (error) => {
      toast.update(toastId, { render: getErrorMessage(error), type: 'error', isLoading: false, autoClose: 3000 });
    };

    if (selectedTab === CUBE_DATA_TABS.CUBE_ENTRY) {
      saveCubeDataEntry(payload, { onSuccess, onError });
    } else if (selectedTab === CUBE_DATA_TABS.BASE_VALUE) {
      saveCubeDataEntryBaseValue(payload, { onSuccess, onError });
    } else if (selectedTab === CUBE_DATA_TABS.INITIAL_VALUE) {
      saveCubeDataEntryInitialValue(payload, { onSuccess, onError });
    }
  };

  const handleTriggerSyncCubeData = () => {
    syncCubeData({
      cubeDefinitionId: cubeId,
      layerId: optionValue.layerId,
      memberId: optionValue.memberId,
      xMemberId: optionValue.xMemberId,
    });
  };

  const handleCloseModalView = () => {
    setCubeDimensionalViewModalState({
      open: false,
      cubeId: undefined,
    });
  };

  const handleShowCubeView = () => {
    navigate(
      {
        search: qs.stringify({
          layerId: optionValue.layerId,
          memberId: optionValue.memberId,
          xMemberId: optionValue.xMemberId,
          ...existingSearchParams,
        }),
      },
      {
        replace: true,
      }
    );

    cubeId &&
      setCubeDimensionalViewModalState({
        open: true,
        cubeId: cubeId,
      });
  };

  const isLoadingData = useMemo(() => {
    return isLoadingDataEntry || isLoadingDataInitialValue || isLoadingDataBaseValue;
  }, [isLoadingDataEntry, isLoadingDataInitialValue, isLoadingDataBaseValue]);

  const isSubmittingData = useMemo(() => {
    return isSubmitting || isSubmittingBaseValue || isSubmittingInitialValue;
  }, [isSubmitting, isSubmittingBaseValue, isSubmittingInitialValue]);

  const isCubeInCalculating =
    cubeActivityStatus === CUBE_ACTIVITY_STATUS.CALCULATING ||
    cubeActivityStatus === CUBE_ACTIVITY_STATUS.RECALCULATING;

  const handleEditCellChangeCommitted = useCallback(
    (params) => {
      const { id, field, value } = params;

      setRowData(
        produce(rowData, (draft) => {
          const row = draft.find((row) => row.rowid === id);
          if (row) {
            row[field] = value;
          }
        })
      );
    },
    [rowData]
  );

  const checkIsCellEditable = useCallback(
    (params) => {
      // the rowId is combination of attributename_datetimeperiod_uuid
      // ex: "tonnes_or_unit#19-02-23#6ecbc515-6773-454a-a40c-08f2a3166dfd"
      // return true;
      if (!isDataEditable) return false;

      const cellAttribute = params?.id?.split('#')?.[0] || '';
      const isInitialInputValue = inputInitialAttributes?.includes(cellAttribute) && firsTimePeriod === params.field;
      return !readOnlyAttributes?.includes(cellAttribute) && !isInitialInputValue && params.field !== 'Total';
    },
    [inputInitialAttributes, readOnlyAttributes, firsTimePeriod, isDataEditable]
  );

  const checkGetCellClassName = useCallback(
    (params) => {
      const { field } = params;
      const cellAttribute = params?.id?.split('#')?.[0] || '';
      const isCellAttributeInReadOnly = readOnlyAttributes?.includes(cellAttribute);
      const isCellAttributeInCalculated = calculatedAttributes?.includes(cellAttribute);
      const isCellAttributeInInputInitial = inputInitialAttributes?.includes(cellAttribute);
      const isInitialInputValue = isCellAttributeInInputInitial && firsTimePeriod === field;

      if (field === '__tree_data_group__') {
        return isCellAttributeInCalculated ? 'font-bold' : '';
      }

      let className = params.indexRelativeToCurrentPage % 2 === 0 ? '' : 'cube-odd';

      if (isCellAttributeInReadOnly || isInitialInputValue || field === 'Total' || !isDataEditable) {
        className += ' non-editable';
      } else {
        className += ' editable';
      }

      if (isCellAttributeInCalculated) className += ' calculated';
      return className;
    },
    [readOnlyAttributes, calculatedAttributes, inputInitialAttributes, firsTimePeriod, isDataEditable]
  );

  const handleOnRefresh = () => {
    refetchCubeData();
  };

  const handleOnSeeFailureDetails = () => {
    const errorMessage = `Cube calculation failed at ${dayjs(
      cubeDefinitionDetails?.data?.cubeActivityStatus?.lastModifiedDate
    ).format(ENV_CONFIG.config.DATE_TIME_FORMAT)}. 
    With error message: ${cubeDefinitionDetails?.data?.cubeActivityStatus?.activityStatusDescription}`;

    setModalInfo({
      visible: true,
      title: 'Cube Calculation Failed',
      descriptionMessage: errorMessage,
    });
  };

  const handleOnSelectedInsightView = (option: AutoCompleteItem) => {
    const url = `${PATH_CONSTANT.TENANT.CUBE.INSIGHT.replace(':cubeId', cubeId).replace(':insightId', option.value)}?${
      qs.stringify({
        memberId: optionValue.memberId,
        xMemberId: optionValue.xMemberId,
      }) || ''
    }`;
    navigate(url);
  };

  const initialScrollColumnIndex = useMemo(() => {
    if (cubeEntryData?.data) {
      return cubeEntryData?.data.todayTimePeriodIndex + 2;
    }
    return 0;
  }, [cubeEntryData?.data]);

  useEffect(() => {
    if (selectedTab !== CUBE_DATA_TABS.CUBE_ENTRY) setSelectedTab(CUBE_DATA_TABS.CUBE_ENTRY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cubeId]);

  return (
    <>
      <TenantHomeDashboardNavigationBar
        error={error}
        breadCrumbs={breadCrumbs}
        containerSx={{ height: '100%', flex: 1, flexDirection: 'column', overflowY: 'auto', display: 'flex' }}
        rootContainerSx={{ height: '95vh' }}
      >
        {isDataEditable && cubeActivityStatus === CUBE_ACTIVITY_STATUS.CALCULATION_FAILED && (
          <Alert severity='warning' sx={{ mb: 2 }}>
            This cube is currently experiencing a calculation error. The data displayed below represents the last
            successful update and may not reflect the most recent information. To ensure data accuracy, please contact
            your system administrator for an immediate investigation into the issue.{' '}
            <span style={moreDetailsTextActionStyle} onClick={handleOnSeeFailureDetails}>
              <u>
                <b>See more details</b>
              </u>
            </span>
          </Alert>
        )}
        <AnimatedPage
          key={cubeId || 'cube-id'}
          variants={getPageAnimationByRefNavigation(navigationRef)}
          style={{
            height: '100%',
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
          }}
        >
          <CubeDataEntrySettingPanel
            ref={cubeSettingPanelRef}
            cubeId={cubeId}
            disabled={isLoadingData || isSubmittingData || isCubeInCalculating}
            loading={isLoadingData || isSubmittingData}
            setIsLoadingOptionsData={setIsLoadingOptionsData}
            onOptionChange={handleOnOptionChange}
            showSaveButton={isAnyUnsaved}
            onButtonSaveClick={handleOnSaveData}
            onButtonSycnClick={handleTriggerSyncCubeData}
            onRefresh={handleOnRefresh}
            onShowDataDetails={handleOnShowDataDetails}
            onShowCubeView={handleShowCubeView}
            onInsightMenuOptionClick={handleOnSelectedInsightView}
            showSyncButton={cubeEntryData?.data.cubeSync?.status === CUBE_SYNC_STATUS.REQUIRED}
          />

          {tabsMenu.length > 1 && (
            <Stack direction='row' flex spacing={5} alignItems='center' justifyContent='space-between' width='100%'>
              <Box px={3.5} sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <Tabs
                  sx={{
                    width: '100%',
                  }}
                  TabIndicatorProps={TabIndicatoryStyle}
                  value={selectedTab}
                  onChange={handleOnTabChange}
                >
                  {tabsMenu.map((tab) => (
                    <Tab sx={TabStyle} value={tab.value} label={tab.label} key={tab.value} />
                  ))}
                </Tabs>
              </Box>
            </Stack>
          )}

          {isCubeInCalculating && (
            <Stack sx={{ height: 400, justifyContent: 'center', alignItems: 'center' }}>
              <Stack width='20%'>
                <Typography
                  variant='input-label-gray'
                  mb={2}
                  textAlign='center'
                  className='blinking-element dot-loading'
                >
                  {cubeDefinitionDetails?.data?.cubeActivityStatus?.activityStatusDescription}
                </Typography>
                <LinearProgress />
              </Stack>
            </Stack>
          )}

          {isLoadingData || isLoadingOptionsData ? (
            <Stack direction='column' sx={{ bgcolor: '#fff', py: 3, height: '300px', px: 2 }} justifyContent='center'>
              <ActivityIndicator />
            </Stack>
          ) : (
            (cubeActivityStatus === CUBE_ACTIVITY_STATUS.READY ||
              cubeActivityStatus === CUBE_ACTIVITY_STATUS.CALCULATION_FAILED) && (
              <CustomDataTable
                loading={isSubmittingData}
                isError={isErrorCubeData}
                errorTitle={getErrorTitle(errorCubeData)}
                errorMessage={getErrorMessage(errorCubeData)}
                initialState={{
                  pinnedColumns: { left: [GRID_TREE_DATA_GROUPING_FIELD] },
                  pagination: {
                    page: 0,
                  },
                  detailPanel: {
                    expandedRowIds: [1],
                  },
                }}
                columns={columns}
                rows={isErrorCubeData ? [] : initialData}
                treeData={!isErrorCubeData}
                getRowId={(row) => row?.rowid || 0}
                getTreeDataPath={(row) => row.path}
                groupingColDef={!isErrorCubeData && groupingColDef}
                disableSelectionOnClick
                onCellEditCommit={handleEditCellChangeCommitted}
                checkboxSelection={false}
                rowsPerPageOptions={[5, 10, 20]}
                isCellEditable={checkIsCellEditable}
                isGroupExpandedByDefault={(params) => {
                  return true;
                }}
                getCellClassName={checkGetCellClassName}
                paginationMode='client'
                editMode='cell'
                sortingMode='client'
                columnBuffer={2}
                columnThreshold={2}
                sx={{ height: 500, mt: 1 }}
                filterMode='client'
                autoHeight={false}
                hideFooter
                initialScrollColumnIndex={initialScrollColumnIndex}
                components={{
                  Toolbar: null,
                  Pagination: null,
                }}
              />
            )
          )}
        </AnimatedPage>
        <ModalLeavePageConfirmationComponent when={isAnyUnsaved} />
      </TenantHomeDashboardNavigationBar>
      <ModalInfoComponent
        visible={modalInfo.visible}
        title={modalInfo.title}
        descriptionMessage={modalInfo.descriptionMessage}
        onClose={() => setModalInfo({ ...modalInfo, visible: false })}
      />
      <CubeDimensionalViewModal
        open={cubeDimensionalViewModalState.open}
        onClose={handleCloseModalView}
        cubeId={cubeId}
      />
      <ModalCubeEventDataDetails
        open={modalDataDetails.open}
        onClose={() => {
          setModalDataDetails(initialStateModalDetails);
        }}
        cubeId={cubeId}
        cubeMemberName={cubeEntryData?.data.member?.label}
        title={`${cubeEntryData?.data.member?.label} - Data Details`}
        subtitle={`${modalDataDetails.eventSubjectName} • ${modalDataDetails.timePeriod}`}
        timePeriod={modalDataDetails.timePeriod || undefined}
        cubeMember={modalDataDetails.cubeMember || undefined}
        subjectId={modalDataDetails.eventSubjectId || undefined}
      />
    </>
  );
}
