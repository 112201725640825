import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DASHBOARD_VIEW_MODE } from 'constant/DashboardTypeConstant';

export type DashboardType = 'DASHBOARD_MASTER' | 'DASHBOARD_TENANT' | 'DASHBOARD_SYSTEM';
export type RoleType = 'Admin' | 'Builder' | 'Manager' | 'Packager' | 'Tester' | 'Support';
export type BrowserLoginHistory = {
  userFullName: string;
  timestamp: string;
};

export interface TenantInterface {
  roleType: RoleType | '';
  roleTypeId: string | null;

  tenant: {
    id: string;
    dashboardType: DashboardType;
    tenantName?: string;
    tenantDesc: string;
    tenantCode?: string;
    systemName?: string;
    organizationName?: string;
    organizationCode?: string;
    systemCode?: string;
    version: string;
    tenantStatus: string;
    industryName: string;
    industryCode: string;
    systemCodeStatus: string;
    systemDemoStatus: string;
    systemFieldStatus: string;
    roleName: string;
    roleId: string;
  };
  setupStatus?: string;
}

export interface RoleInterface {
  roleType: string;
  roleName: string;
  adminRight: number;
  licenseName: string;
  agreementType: string;
  industrySector: string;
  countryRegion: string;
}
export interface AuthState {
  accessToken: string;
  rememberMe: boolean;
  expiredDate: string;
  role: RoleType | '';
  dashboardViewMode: string;
  threadUserAsRole: RoleType | '';
  profile: {
    id: string;
    name: string;
  };
  tenants: TenantInterface[];
  roles: RoleInterface[];
  selectedTenant: TenantInterface;
  sessionStatus?: string | null;
  browserLoginHistory: BrowserLoginHistory[];
}

const initialState: AuthState = {
  accessToken: '',
  rememberMe: false,
  expiredDate: '',
  role: '',
  threadUserAsRole: '',
  dashboardViewMode: DASHBOARD_VIEW_MODE.VIEW_ONLY,
  profile: {
    id: '',
    name: '',
  },
  tenants: [],
  roles: [],
  selectedTenant: {
    roleType: '',
    roleTypeId: null,
    tenant: {},
  } as TenantInterface,
  sessionStatus: null,
  browserLoginHistory: [],
};

export const authSlice = createSlice({
  name: '@AUTHENTICATION',
  initialState,
  reducers: {
    setProfile: (state, action: PayloadAction<any>) => {
      // @ts-ignore
      state.profile = action.payload;
    },

    setAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
    },
    setRememberMe: (state, action: PayloadAction<{ rememberMe: boolean; expiredDate: string }>) => {
      state.rememberMe = action.payload.rememberMe;
      state.expiredDate = action.payload.expiredDate;
    },
    setRole: (state, action: PayloadAction<RoleType | ''>) => {
      state.role = action.payload;
    },
    setUserBehaviourRole(state, action: PayloadAction<RoleType | ''>) {
      state.threadUserAsRole = action.payload;
    },

    logout: (state) => {
      return {
        ...initialState,
        browserLoginHistory: state.browserLoginHistory,
      };
    },
    setTenants: (state, action: PayloadAction<TenantInterface[]>) => {
      state.tenants = action.payload;
    },
    setRoles(state, action: PayloadAction<RoleInterface[]>) {
      state.roles = action.payload;
    },
    setSelectedTenant: (state, action: PayloadAction<TenantInterface>) => {
      state.selectedTenant = action.payload;
    },
    setDashboardViewMode: (state, action: PayloadAction<string>) => {
      state.dashboardViewMode = action.payload;
    },
    setSessionStatus: (state, action: PayloadAction<string | null>) => {
      state.sessionStatus = action.payload;
    },

    addBrowserLoginHistory: (state, action: PayloadAction<BrowserLoginHistory>) => {
      if (!state.browserLoginHistory) {
        state.browserLoginHistory = [];
      }

      // we only store the last 5 login history
      if (state.browserLoginHistory.length >= 5) {
        state.browserLoginHistory.shift();
      }
      state.browserLoginHistory.push(action.payload);
    },
  },
});

export const {
  setProfile,
  setAccessToken,
  setRememberMe,
  logout,
  setRole,
  setTenants,
  setSelectedTenant,
  setRoles,
  setUserBehaviourRole,
  setDashboardViewMode,
  setSessionStatus,
  addBrowserLoginHistory,
} = authSlice.actions;

export default authSlice.reducer;
