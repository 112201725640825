import { AnimatePresence, Variants, motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';

const defaultAnimations = {
  initial: { opacity: 0, y: 100 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -100 },
};

interface AnimatedPageProps {
  children: React.ReactNode;
  variants?: Variants;
  style?: React.CSSProperties;
}

export const AnimatedPage = ({ children, ...props }: AnimatedPageProps) => {
  const { variants = defaultAnimations } = props;
  const location = useLocation();
  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        initial='initial'
        animate='animate'
        exit='exit'
        key={location.pathname}
        variants={variants}
        transition={{ duration: 0.3 }}
        {...props}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
};
