import { useEffect, useImperativeHandle, useMemo, useRef, useState, forwardRef, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Formik, FormikProps } from 'formik';
import { DropdownItem } from 'types/api/SystemTenant/AROKMS/TableDefinitionTypes';
import { ReactComponent as CubeAccrossIcon } from 'assets/icons/icon-cube-across.svg';
import { ReactComponent as CubeDown } from 'assets/icons/icon-cube-down.svg';
import { useGetLayerListDropdownByCube } from 'services/v1/Tenant/AROCube/CubeDataEntryService';
import { flattenTreeData, renderOptionsKtree, getListBoxPropsAutoScrolItem } from 'utils/Object';
import { useGetCubeDefinitionDetails } from 'services/v1/SystemTenant/AROCube/CubeBuilderService';
import { DIMENSION_CATEGORY, DropdownItemWithType } from 'types/api/Tenant/AROCube/CubeDataDimensionalViewTypes';
import { useGetCubeDimensionalViewFilterMember } from 'services/v1/Tenant/AROCube/CubeDimensionalViewService';
import { ControllerNavigation, ControllerNavigationDirection } from 'pages/Tenant/AroDashboard/ControllerNavigation';
import { useGetMyPersonalization, useGetMyViewDetails } from 'services/v1/Tenant/ConfigurationService';
import {
  WorkFlowDrillNavigationDirection,
  getCurrentMyViewIndex,
  getNextDrillDownURL,
  getNextMyViewItem,
  getPreviousMyViewItem,
  getWorkFlowDrillView,
} from 'utils/MyViewUtils';
import { PAGE_REF_NAVIGATION } from 'utils/Animation';
import { DASHBOARD_DISPLAY_TYPE } from 'constant/NavigationConstant';
import { getQuickAccessNavigateURL } from 'components/LayoutComponent/VerticalLayout/QuickAccess';

export interface OptionItem {
  value: string | number;
  label: string;
}

const autoCompleteStyle = {
  width: 230,
  '& .MuiAutocomplete-popper': {
    backgroundColor: 'red !imoprtant',
    fontSize: '10px',
  },
};

const textInputStyle = {};

export type DimensionalViewOptionValues = {
  xDimensionType: DropdownItemWithType | null;
  yDimensionType: DropdownItemWithType | null;
  layer: DropdownItem | null;
  member: DropdownItem | null;
  xMember: DropdownItem | null;
};

interface SettingPanelProps {
  disabled?: boolean;
  subjectId?: string | number;
  cubeDefinitionId: string;
  xDimensionOptions: DropdownItemWithType[];
  yDimensionOptions: DropdownItemWithType[];
  ref?: any;
  onOptionChange?: (option: DimensionalViewOptionValues) => void;
  loading?: boolean;
  initialValues: DimensionalViewOptionValues;

  setIsLoadingOptionsData?: (isLoading: boolean) => void;
}

const formikInitialValues: DimensionalViewOptionValues = {
  layer: null,
  member: null,
  xDimensionType: null,
  xMember: null,
  yDimensionType: null,
};

export const CubeDimensionalViewPanel = forwardRef((props: SettingPanelProps, ref) => {
  const {
    disabled: disableProps,
    initialValues,
    xDimensionOptions,
    onOptionChange,
    yDimensionOptions,
    cubeDefinitionId,
    setIsLoadingOptionsData,
  } = props;

  const [searchParams] = useSearchParams();
  const layerId = searchParams.get('layerId');
  const memberId = searchParams.get('memberId');
  const xMemberId = searchParams.get('xMemberId');
  const myViewId = searchParams.get('myViewId');
  const workflowId = searchParams.get('workflowId');

  const navigate = useNavigate();

  const [optionValue, setOptionValue] = useState<DimensionalViewOptionValues>({
    xDimensionType: null,
    yDimensionType: null,
    layer: null,
    member: null,
    xMember: null,
  });
  const formikRef = useRef<FormikProps<DimensionalViewOptionValues>>(null);
  const { data: allMyViewsData } = useGetMyPersonalization({ enabled: true });
  const { data: myViewDetails } = useGetMyViewDetails(myViewId);
  const { data: layerDropdown, isLoading: isFetchingLayer } = useGetLayerListDropdownByCube(cubeDefinitionId);
  const { data: memberDropdown, isLoading: isFetchingMember } = useGetCubeDimensionalViewFilterMember(
    'MAIN_MEMBER',
    cubeDefinitionId,
    optionValue.xDimensionType?.type,
    optionValue.yDimensionType?.type,
    DIMENSION_CATEGORY.CUBE_DIMENSIONAL_VIEW
  );
  const { data: memberXDropdown } = useGetCubeDimensionalViewFilterMember(
    'X_MEMBER',
    cubeDefinitionId,
    optionValue.xDimensionType?.type,
    optionValue.yDimensionType?.type,
    DIMENSION_CATEGORY.CUBE_DIMENSIONAL_VIEW
  );

  const { data: cubeDefinitionDetails } = useGetCubeDefinitionDetails(cubeDefinitionId);

  const disabled = disableProps;

  const memberOptions = useMemo(() => {
    if (memberDropdown) {
      return flattenTreeData(memberDropdown);
    }
    return [];
  }, [memberDropdown]);

  const memberXOptions = useMemo(() => {
    if (memberXDropdown) {
      return flattenTreeData(memberXDropdown);
    }
    return [];
  }, [memberXDropdown]);

  const lowestMemberLevel = useMemo(() => {
    // @ts-ignore
    return memberOptions.reduce((acc, item) => {
      if (item.level > acc) {
        return item.level;
      } else {
        return acc;
      }
    }, 0);
  }, [memberOptions]);

  const lowestMemberXLevel = useMemo(() => {
    // @ts-ignore
    return memberXOptions.reduce((acc, item) => {
      if (item.level > acc) {
        return item.level;
      } else {
        return acc;
      }
    }, 0);
  }, [memberXOptions]);

  const mainMemberFilterLabel = useCallback(() => {
    if (cubeDefinitionDetails?.data) {
      if (
        (optionValue.xDimensionType?.type === 'MEASUREMENT_MEMBER' &&
          optionValue.yDimensionType?.type === 'MAIN_MEMBER') ||
        (optionValue.xDimensionType?.type === 'MAIN_MEMBER' &&
          optionValue.yDimensionType?.type === 'MEASUREMENT_MEMBER')
      ) {
        return 'Period';
      }
      if (
        (optionValue.xDimensionType?.type === 'TIME_PERIOD_MEMBER' &&
          optionValue.yDimensionType?.type === 'MEASUREMENT_MEMBER') ||
        (optionValue.xDimensionType?.type === 'MEASUREMENT_MEMBER' &&
          optionValue.yDimensionType?.type === 'TIME_PERIOD_MEMBER')
      ) {
        return cubeDefinitionDetails?.data.member.label;
      }

      if (
        (optionValue.xDimensionType?.type === 'TIME_PERIOD_MEMBER' &&
          optionValue.yDimensionType?.type === 'MAIN_MEMBER') ||
        (optionValue.xDimensionType?.type === 'MAIN_MEMBER' &&
          optionValue.yDimensionType?.type === 'TIME_PERIOD_MEMBER')
      ) {
        return 'Measure';
      }
      if (
        (optionValue.xDimensionType?.type === 'TIME_PERIOD_MEMBER' &&
          optionValue.yDimensionType?.type === 'SECOND_MEMBER') ||
        (optionValue.xDimensionType?.type === 'SECOND_MEMBER' &&
          optionValue.yDimensionType?.type === 'TIME_PERIOD_MEMBER')
      ) {
        return cubeDefinitionDetails?.data.member.label;
      }

      if (
        (optionValue?.xDimensionType?.type === 'MEASUREMENT_MEMBER' &&
          optionValue.yDimensionType?.type === 'SECOND_MEMBER') ||
        (optionValue.xDimensionType?.type === 'SECOND_MEMBER' &&
          optionValue.yDimensionType?.type === 'MEASUREMENT_MEMBER')
      ) {
        return cubeDefinitionDetails?.data.member.label;
      }

      if (
        (optionValue.xDimensionType?.type === 'MAIN_MEMBER' && optionValue.yDimensionType?.type === 'SECOND_MEMBER') ||
        (optionValue.xDimensionType?.type === 'TIME_PERIOD_MEMBER' &&
          optionValue.yDimensionType?.type === 'TIME_PERIOD_MEMBER')
      ) {
        return 'Measure';
      }
    }
    return 'Member';
  }, [optionValue.xDimensionType?.type, optionValue.yDimensionType?.type, cubeDefinitionDetails?.data]);

  const secondMemberFilterLabel = useCallback(() => {
    if (cubeDefinitionDetails?.data) {
      if (
        (optionValue.xDimensionType?.type === 'MEASUREMENT_MEMBER' &&
          optionValue.yDimensionType?.type === 'MAIN_MEMBER') ||
        (optionValue.xDimensionType?.type === 'MAIN_MEMBER' &&
          optionValue.yDimensionType?.type === 'MEASUREMENT_MEMBER')
      ) {
        return cubeDefinitionDetails?.data.subjectX.label;
      }
      if (
        (optionValue.xDimensionType?.type === 'TIME_PERIOD_MEMBER' &&
          optionValue.yDimensionType?.type === 'MEASUREMENT_MEMBER') ||
        (optionValue.xDimensionType?.type === 'MEASUREMENT_MEMBER' &&
          optionValue.yDimensionType?.type === 'TIME_PERIOD_MEMBER')
      ) {
        return cubeDefinitionDetails?.data.subjectX.label;
      }

      if (
        (optionValue.xDimensionType?.type === 'TIME_PERIOD_MEMBER' &&
          optionValue.yDimensionType?.type === 'MAIN_MEMBER') ||
        (optionValue.xDimensionType?.type === 'MAIN_MEMBER' &&
          optionValue.yDimensionType?.type === 'TIME_PERIOD_MEMBER')
      ) {
        return cubeDefinitionDetails?.data.subjectX.label;
      }
      if (
        (optionValue?.xDimensionType?.type === 'TIME_PERIOD_MEMBER' &&
          optionValue.yDimensionType?.type === 'SECOND_MEMBER') ||
        (optionValue.xDimensionType?.type === 'SECOND_MEMBER' &&
          optionValue.yDimensionType?.type === 'TIME_PERIOD_MEMBER')
      ) {
        return 'Measure';
      }

      if (
        (optionValue?.xDimensionType?.type === 'MEASUREMENT_MEMBER' &&
          optionValue.yDimensionType?.type === 'SECOND_MEMBER') ||
        (optionValue.xDimensionType?.type === 'SECOND_MEMBER' &&
          optionValue.yDimensionType?.type === 'MEASUREMENT_MEMBER')
      ) {
        return 'Period';
      }

      if (
        (optionValue.xDimensionType?.type === 'MAIN_MEMBER' && optionValue.yDimensionType?.type === 'SECOND_MEMBER') ||
        (optionValue.xDimensionType?.type === 'TIME_PERIOD_MEMBER' &&
          optionValue.yDimensionType?.type === 'TIME_PERIOD_MEMBER')
      ) {
        return 'Period';
      }
    }
    return 'Member';
  }, [optionValue.xDimensionType?.type, optionValue.yDimensionType?.type, cubeDefinitionDetails?.data]);

  const insightViewList = useMemo(() => {
    if (allMyViewsData?.data.displayType === DASHBOARD_DISPLAY_TYPE.DASHBOARD_LIST) {
      const selectedDashboardId = myViewDetails?.data.dashboardId;
      const selectedDashboard =
        allMyViewsData.data.dashboards.find((dashboardItem) => dashboardItem.id === selectedDashboardId) || null;

      if (selectedDashboard) {
        return selectedDashboard.myViews;
      }
    }

    return allMyViewsData?.data.myViewItems || [];
  }, [allMyViewsData?.data, myViewDetails?.data]);

  const currentMyViewItem = useMemo(() => {
    if (myViewDetails?.data) {
      return myViewDetails?.data;
    }
    return null;
  }, [myViewDetails?.data]);

  const currentMyViewIndex = useMemo(() => {
    if (myViewId == null) return 0;
    return getCurrentMyViewIndex(insightViewList, myViewId);
  }, [myViewId, insightViewList]);

  const nextViewItem = useMemo(() => {
    return getNextMyViewItem(insightViewList, currentMyViewIndex);
  }, [currentMyViewIndex, insightViewList]);

  const previousViewItem = useMemo(() => {
    return getPreviousMyViewItem(insightViewList, currentMyViewIndex);
  }, [currentMyViewIndex, insightViewList]);

  const nextDrilDownWorkFlow = useMemo(() => {
    return getWorkFlowDrillView(currentMyViewItem, workflowId, WorkFlowDrillNavigationDirection.DOWN);
  }, [currentMyViewItem, workflowId]);
  const previousDrilDownWorkFlow = useMemo(() => {
    return getWorkFlowDrillView(currentMyViewItem, workflowId, WorkFlowDrillNavigationDirection.UP);
  }, [currentMyViewItem, workflowId]);

  const navigationControllerTooltips = useMemo(() => {
    const defaultToolTips: {
      [key: string]: string;
    } = {
      [ControllerNavigationDirection.UP]: previousDrilDownWorkFlow?.componentName
        ? `Show ${previousDrilDownWorkFlow.componentName}`
        : 'Show Insight',
      [ControllerNavigationDirection.DOWN]: nextDrilDownWorkFlow?.componentName
        ? `Show ${nextDrilDownWorkFlow?.componentName}`
        : 'Show Data',
    };
    if (insightViewList.length > 1) {
      if (currentMyViewIndex > -1) {
        if (currentMyViewIndex > 0) {
          defaultToolTips[ControllerNavigationDirection.LEFT] = `Show ${insightViewList[currentMyViewIndex - 1].title}`;
        }
        if (currentMyViewIndex < insightViewList.length - 1) {
          defaultToolTips[ControllerNavigationDirection.RIGHT] = `Show ${
            insightViewList[currentMyViewIndex + 1].title
          }`;
        }
      }
    }
    return defaultToolTips;
  }, [previousDrilDownWorkFlow, nextDrilDownWorkFlow, currentMyViewIndex, insightViewList]);

  const enableControllerNavigations = useMemo(() => {
    const controller = [];
    if (nextDrilDownWorkFlow && previousDrilDownWorkFlow) {
      controller.push(ControllerNavigationDirection.UP);
      controller.push(ControllerNavigationDirection.DOWN);
    }
    if (previousDrilDownWorkFlow && !nextDrilDownWorkFlow) {
      controller.push(ControllerNavigationDirection.UP);
    }
    if (!previousDrilDownWorkFlow && nextDrilDownWorkFlow) {
      controller.push(ControllerNavigationDirection.DOWN);
    }
    if (insightViewList?.length > 1) {
      if (currentMyViewIndex > 0) controller.push(ControllerNavigationDirection.LEFT);
      if (currentMyViewIndex < insightViewList.length - 1) controller.push(ControllerNavigationDirection.RIGHT);
    }
    return controller;
  }, [nextDrilDownWorkFlow, previousDrilDownWorkFlow, currentMyViewIndex, insightViewList]);

  const handleOnDashboardNavigate = (direction: ControllerNavigationDirection) => {
    if (direction === ControllerNavigationDirection.UP) {
      const previousDrillDownURL = getNextDrillDownURL(currentMyViewItem, previousDrilDownWorkFlow, {
        memberId: optionValue.member?.value,
        ref: PAGE_REF_NAVIGATION.TOP,
      });
      previousDrillDownURL && navigate(previousDrillDownURL);
    }
    if (direction === ControllerNavigationDirection.DOWN && nextDrilDownWorkFlow) {
      const nextDrillDownURL = getNextDrillDownURL(currentMyViewItem, nextDrilDownWorkFlow, {
        memberId: optionValue.member?.value,
        ref: PAGE_REF_NAVIGATION.BOTTOM,
      });
      nextDrillDownURL && navigate(nextDrillDownURL);
    }

    if (direction === ControllerNavigationDirection.RIGHT && nextViewItem) {
      const nextMyViewItemURL = getQuickAccessNavigateURL(nextViewItem, undefined, PAGE_REF_NAVIGATION.RIGHT);
      nextMyViewItemURL && navigate(nextMyViewItemURL);
    }
    if (direction === ControllerNavigationDirection.LEFT && previousViewItem) {
      const previousMyViewItemURL = getQuickAccessNavigateURL(previousViewItem, undefined, PAGE_REF_NAVIGATION.LEFT);
      previousMyViewItemURL && navigate(previousMyViewItemURL);
    }
  };

  useEffect(() => {
    onOptionChange && onOptionChange(optionValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionValue]);

  useEffect(() => {
    if (initialValues) {
      setOptionValue((prevState) => ({
        ...prevState,
        xDimensionType: initialValues.xDimensionType,
        yDimensionType: initialValues.yDimensionType,
      }));
    }
  }, [initialValues]);

  // auto select layer
  useEffect(() => {
    if (layerDropdown?.data && layerDropdown?.data.length > 0) {
      const layer = layerDropdown?.data?.find((item) => item.value === layerId) ?? layerDropdown?.data[0];

      const selectedLayer: DropdownItem = {
        id: layer.value,
        value: layer?.value?.toString() || '',
        label: layer.label,
      };
      setOptionValue((prev) => ({ ...prev, layer: selectedLayer }));
    }
  }, [layerDropdown?.data, layerId]);

  // auto select member
  useEffect(() => {
    if (memberOptions && memberOptions?.length > 0) {
      const member = memberOptions?.find((item: DropdownItem) => item.value === memberId) ?? memberOptions?.[0];
      const selectedMember: DropdownItem = {
        id: member.value,
        value: member?.value?.toString() || '',
        label: member.label,
      };
      setOptionValue((prev) => ({ ...prev, member: selectedMember }));
    }
  }, [memberOptions, memberId]);

  useEffect(() => {
    if (memberXOptions && memberXOptions?.length > 0) {
      const memberX = memberXOptions?.find((item: DropdownItem) => item.value === xMemberId) ?? memberXOptions?.[0];
      const selectedMemberX: DropdownItem = {
        id: memberX.value,
        value: memberX?.value?.toString() || '',
        label: memberX.label,
      };
      setOptionValue((prev) => ({ ...prev, xMember: selectedMemberX }));
    }
  }, [memberXOptions, xMemberId]);

  useImperativeHandle(ref, () => ({
    isLoadingOptionsData: isFetchingLayer || isFetchingMember,
  }));

  useEffect(() => {
    setIsLoadingOptionsData && setIsLoadingOptionsData(isFetchingLayer || isFetchingMember);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingLayer, isFetchingMember]);

  return (
    <Stack direction='column' sx={{ backgroundColor: '#fff', py: 1.8 }}>
      <Formik onSubmit={() => {}} innerRef={formikRef} initialValues={formikInitialValues}>
        {({ errors, touched, handleBlur, handleChange, values, setFieldValue, handleSubmit }) => {
          return (
            <>
              <Stack
                direction='row'
                alignItems='center'
                sx={{
                  bgcolor: '#FBFBFB',
                  px: 2,
                  border: '1px solid #E3EBF6',
                  py: enableControllerNavigations.length > 0 ? 0 : 2,
                }}
              >
                <Stack direction='row' justifyContent='space-between' width='100%'>
                  <Stack direction='row' spacing={5} alignItems='center'>
                    <Autocomplete
                      // @ts-ignore
                      onChange={(event, value) => {
                        setOptionValue((prev) => ({
                          ...prev,
                          xDimensionType: value,
                        }));
                      }}
                      clearIcon={null}
                      size='small'
                      value={optionValue.xDimensionType}
                      disabled={disabled}
                      getOptionLabel={(option: DropdownItem) => option.label}
                      options={xDimensionOptions || []}
                      renderOption={(props, option) => (
                        <Box component='li' sx={{ '& > span': { fontSize: '14px', mr: 1, flexShrink: 0 } }} {...props}>
                          <span>{option.label}</span>
                        </Box>
                      )}
                      sx={autoCompleteStyle}
                      renderInput={(params) => (
                        <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                          <TextField
                            {...params}
                            sx={textInputStyle}
                            onBlur={handleBlur}
                            label={'Down'}
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position='start'>
                                  <CubeDown />
                                </InputAdornment>
                              ),
                            }}
                            name='xDimensionType'
                          />
                        </Stack>
                      )}
                    />
                    <Autocomplete
                      // @ts-ignore
                      onChange={(event, value) => {
                        setOptionValue((prev) => ({
                          ...prev,
                          yDimensionType: value,
                        }));
                      }}
                      clearIcon={null}
                      size='small'
                      value={optionValue.yDimensionType}
                      disabled={disabled}
                      getOptionLabel={(option: DropdownItem) => option.label}
                      options={yDimensionOptions || []}
                      renderOption={(props, option) => (
                        <Box component='li' sx={{ '& > span': { fontSize: '14px', mr: 1, flexShrink: 0 } }} {...props}>
                          <span>{option.label}</span>
                        </Box>
                      )}
                      sx={autoCompleteStyle}
                      renderInput={(params) => (
                        <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                          <TextField
                            {...params}
                            sx={textInputStyle}
                            label='Across'
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position='start'>
                                  <CubeAccrossIcon />
                                </InputAdornment>
                              ),
                            }}
                            onBlur={handleBlur}
                            name='yDimenstionType'
                          />
                        </Stack>
                      )}
                    />

                    <Autocomplete
                      // @ts-ignore
                      onChange={(event, value) => {
                        setOptionValue((prev) => ({ ...prev, layer: value }));
                      }}
                      clearIcon={null}
                      size='small'
                      value={optionValue.layer}
                      disabled={disabled || isFetchingLayer}
                      getOptionLabel={(option: DropdownItem) => option.label}
                      options={layerDropdown?.data || []}
                      renderOption={(props, option) => (
                        <Box component='li' sx={{ '& > span': { fontSize: '14px', mr: 1, flexShrink: 0 } }} {...props}>
                          <span>{option.label}</span>
                        </Box>
                      )}
                      sx={autoCompleteStyle}
                      renderInput={(params) => (
                        <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                          <TextField
                            {...params}
                            sx={textInputStyle}
                            label={isFetchingLayer ? 'Loading...' : 'Version'}
                            onBlur={handleBlur}
                            name='layer'
                          />
                        </Stack>
                      )}
                    />
                  </Stack>
                </Stack>
                {enableControllerNavigations.length > 0 && (
                  <ControllerNavigation
                    enableNavigations={enableControllerNavigations}
                    tooltips={navigationControllerTooltips}
                    onNavigate={handleOnDashboardNavigate}
                  />
                )}
              </Stack>
              <Stack direction='row' alignItems='center' sx={{ pt: 1, px: 2 }} width='100%'>
                <Stack direction='row' gap={3} width='100%'>
                  <Autocomplete
                    // @ts-ignore
                    onChange={(event, value) => {
                      setOptionValue((prev) => ({ ...prev, member: value }));
                    }}
                    clearIcon={null}
                    sx={{
                      width: 300,
                    }}
                    size='small'
                    value={optionValue.member}
                    disabled={disabled || !optionValue.layer || isFetchingMember}
                    getOptionLabel={(option: DropdownItem) => option.label}
                    options={memberOptions || []}
                    renderOption={(props, option, state) => renderOptionsKtree(props, option, state, lowestMemberLevel)}
                    ListboxProps={{
                      ...getListBoxPropsAutoScrolItem(memberOptions, optionValue.member),
                    }}
                    renderInput={(params) => (
                      <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                        <TextField
                          {...params}
                          sx={textInputStyle}
                          label={isFetchingMember ? 'Loading...' : mainMemberFilterLabel() || 'Member'}
                          onBlur={handleBlur}
                          name='member'
                        />
                      </Stack>
                    )}
                  />

                  {cubeDefinitionDetails?.data.subjectX && (
                    <Autocomplete
                      // @ts-ignore
                      onChange={(event, value) => {
                        setOptionValue((prev) => ({ ...prev, xMember: value }));
                      }}
                      clearIcon={null}
                      size='small'
                      sx={{
                        width: 300,
                      }}
                      value={optionValue.xMember}
                      disabled={disabled || !optionValue.layer || isFetchingMember}
                      getOptionLabel={(option: DropdownItem) => option.label}
                      options={memberXOptions || []}
                      renderOption={(props, option, state) =>
                        renderOptionsKtree(props, option, state, lowestMemberXLevel)
                      }
                      ListboxProps={{
                        ...getListBoxPropsAutoScrolItem(memberXOptions, optionValue.xMember),
                      }}
                      renderInput={(params) => (
                        <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                          <TextField
                            {...params}
                            sx={textInputStyle}
                            label={isFetchingMember ? 'Loading...' : secondMemberFilterLabel() || 'Member'}
                            onBlur={handleBlur}
                            name='xMember'
                          />
                        </Stack>
                      )}
                    />
                  )}
                </Stack>
              </Stack>
            </>
          );
        }}
      </Formik>
    </Stack>
  );
});
